import {
  StyledNotistackComponents,
  StyledSnackbarProvider,
} from './Snackbar.styled';

export const Snackbar = () => {
  return (
    <StyledSnackbarProvider
      autoHideDuration={3000}
      Components={{
        error: StyledNotistackComponents,
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    />
  );
};
