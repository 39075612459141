import { ChangeEvent } from 'react';
import { FieldPath, FieldValues, useController } from 'react-hook-form';

import { StyledInput, StyledInputContainer } from './ControllerTextInput.styed';
import { FieldError } from '../../FieldError/FieldError';
import { FormTextFieldProps } from '../../../data-models';

export function ControllerTextInput<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  placeholder,
  className,
  type = 'text',
  onChangeCustom,
}: FormTextFieldProps<TFieldValues, TFieldName>) {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (onChangeCustom) {
      onChangeCustom(event.target.value);
      return;
    }
    onChange(event.target.value);
  };

  return (
    <StyledInputContainer>
      <StyledInput
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        error={!!error}
        className={className}
        type={type}
      />

      <FieldError message={error?.message} />
    </StyledInputContainer>
  );
}
