import { useTheme } from '@mui/material';

import { IconWrapper } from '../../IconWrapper/IconWrapper';
import { TickIcon } from '../../icons';
import {
  BotName,
  CongratulationsModalBg,
  CongratulationsModalContainer,
  CongratulationsModalContent,
  GreenText,
  IconContainer,
  YouBotText,
  YouBuyBotText,
} from './CongratulationsModal.styled';
import { useModalContext } from '../../../context';
import { CongratulationsType } from '../../../data-models/enums';

type ContentType = {
  botName: string;
};

const SellContent = ({ botName }: ContentType) => {
  return (
    <>
      <YouBotText>Your bot</YouBotText>
      <BotName>{botName}</BotName>
      <GreenText>has been sold</GreenText>
    </>
  );
};

const BuyContent = ({ botName }: ContentType) => {
  return (
    <>
      <GreenText>Congratulations</GreenText>
      <YouBuyBotText>you bought a bot</YouBuyBotText>
      <BotName>{botName}</BotName>
    </>
  );
};

export const CongratulationsModal = () => {
  const theme = useTheme();
  const { handleChangeModalParameters, modalParameters } = useModalContext();

  const botName = modalParameters?.value?.name || '';
  const variant = modalParameters?.value?.variant;

  const handleClose = () => {
    handleChangeModalParameters([
      {
        key: 'isOpen',
        value: false,
      },
    ]);
  };

  return (
    <CongratulationsModalContainer onClick={handleClose}>
      <IconContainer>
        <IconWrapper
          configIcon={{
            $bgColor: theme.palette.green.main,
            $iconSize: 24,
            $size: 52,
            $border: `1px solid ${theme.palette.white.main}`,
          }}
        >
          <TickIcon />
        </IconWrapper>
      </IconContainer>

      <CongratulationsModalBg />

      <CongratulationsModalContent>
        {variant === CongratulationsType.buy && (
          <BuyContent botName={botName} />
        )}

        {variant === CongratulationsType.sell && (
          <SellContent botName={botName} />
        )}
      </CongratulationsModalContent>
    </CongratulationsModalContainer>
  );
};
