/* eslint-disable no-irregular-whitespace */
import {
  EmptyBotList,
  CreateBotText,
  EmptyBotListText,
  InstructionsList,
} from '../../../pages/MyBotsPage/MyBotsPage.styled';
import { CloseIcon, FaqIcon } from '../../icons';
import {
  CenteredModal,
  ModalContent,
  ModalHeaderContainer,
  ModalHeaderText,
  ModalCloseButton,
  ModalContentWrapper,
  ScrollWrapper,
} from './FaqModal.styled';
import { useState } from 'react';

export const FaqModal = () => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <FaqIcon onClick={handleOpen} />
      <CenteredModal
        open={open}
        onClose={handleClose}
        aria-labelledby='centered-modal-title'
        aria-describedby='centered-modal-description'
      >
        <ModalContent>
          <ModalHeaderContainer>
            <ModalHeaderText>HOW TO PLAY</ModalHeaderText>
            <ModalCloseButton onClick={handleClose}>
              <CloseIcon />
            </ModalCloseButton>
          </ModalHeaderContainer>
          <ModalContentWrapper>
            <ScrollWrapper>
              <EmptyBotList>
                <CreateBotText>
                  YOU DON’T HAVE ANY BOTS YET. Create ONE ⬆️
                </CreateBotText>
                <EmptyBotListText>
                  Pump’n’Dump is a multiplayer strategy game for Crypto
                  Hooligans. Every round your crypto bots engage in 1:1 battles,
                  deciding whether to pump or dump crypto. Once the round
                  starts, your bot’s actions are fully automated, based on the
                  strategy you’ve set. Success depends on your strategy as your
                  bot reacts to opponents in real-time.
                </EmptyBotListText>
                <EmptyBotListText>
                  Compete, adapt, and see if your bots can outscore the rest in
                  this fast-paced crypto challenge!
                </EmptyBotListText>
                <p>HOW TO PLAY:</p>
                <InstructionsList>
                  <li>Create a bot and configure its trading strategy. </li>
                  <li>
                    Join your bots into the lobby, where they will automatically
                    added the next round.
                  </li>
                  <li>
                    Ensure you have enough $PUMPs in your balance, as each bot’s
                    participation in a round costs 50 $PUMPs.
                  </li>
                  <li>
                    Once the round starts, watch your bots and others as they
                    progress in the arena.
                  </li>
                  <li>
                    At the end of the round, view the results, including total
                    scores and the amount of $PUMPs won by each bot.
                  </li>
                </InstructionsList>
              </EmptyBotList>
            </ScrollWrapper>
          </ModalContentWrapper>
        </ModalContent>
      </CenteredModal>
    </div>
  );
};
