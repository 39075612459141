import { Box, Typography, styled } from '@mui/material';

export const StyledPriceBlock = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: 0px 8px;
  min-height: 48px;
  height: 48px;
  overflow: hidden;
  align-items: center;
  width: 100%;
  border-radius: 1000px;
  background-color: ${({ theme }) => theme.palette.black.main};
`;

export const PriceWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 2px;
`;

export const StyledPriceText = styled(Typography)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-family: Aldrich;
  line-height: normal;
  color: white;
`;

export const BuyText = styled(Typography)`
  font-size: 16px;
  color: white;
`;
