export const THEME = {
  COLORS: {
    BLACK_MAIN: '#0C090C',
    BLACK_SECONDARY: '#0C090C',
    BLACK_DARK: '#000',
    BLACK_CONTRAST: '#212021',
    WHITE: '#FFF',
    PLATINUM: '#EAEBE5',
    GREY_LIGHT: '#AAAAAA',
    GREY_MAIN: '#676767',
    GREY_DARK: '#464646',
    GREY_CONTRAST: '#BBBBBB',
    DIM_GREY_MAIN: '#696969',
    DARK_GREY_LIGHT: '#292929',
    DARK_GREY_MAIN: '#181618',
    RED_LIGHT: '#F33D3D',
    RED_MAIN: '#F21A43',
    RED_DARK: '#491414',
    RED_SWITCH: '#F33D3D',
    RED_SWITCH_DARK: '#491414',
    YELLOW_MAIN: '#FAFF2C',
    YELLOW_DARK: '#c4c731',
    ORANGE_MAIN: '#EB6B25',
    LIME_LIGHT: '#C1DEBA',
    LIME_MAIN: '#C5E961',
    GREEN_MAIN: '#2CE72B',
    GREEN_DARK: '#236023',
    ROSE_FOG_MAIN: '#E7C6B4',
    TEA_MAIN: '#BAB7AA',
    CARARRA_MAIN: '#EAEBE5',
    HOME_BACKGROUND: '#323119',
    BLUE_PURPLE_MAIN: '#7952FC',
    GREEN_SUCCESS: '#144934',
    GREEN_SUCCESS_ACCENT: '#2CE72B',
  },
  BORDER_RADIUS: {
    PILL: '1000px',
  },
};
