import { Box, Button, styled } from '@mui/material';

import imageURL from '../../../../assets/image/home-side-menu-background.svg';

export const SideMenuContainer = styled(Box)`
  display: flex;
  gap: 12px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  width: 75px;
  height: 163px;
  bottom: 56px;
  padding: 6px;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    content: '';
    display: block;
    width: 75px;
    height: 163px;
    background-image: url(${imageURL});
    background-size: 100%;
    background-repeat: no-repeat;
  }
`;

export const SideMenuButton = styled(Button)`
  position: absolute;
  top: 36px;
  flex-direction: column;
  align-items: center;
  min-width: 60px;
  width: 60px;
  height: 82px;
  font-size: 12px;
  line-height: 14px;
  border-radius: 26px;
  color: ${({ theme }) => theme.palette.lime.main};

  .MuiButton-startIcon {
    margin: 0 0 6px 0;
  }
`;
