import { THEME } from './theme';

export const SMALL_SCREEN = '(max-width:600px)';

export const MEDIUM_SCREEN = '(max-width:1000px)';

export const DEFAULT_SCROLLBAR_STYLE = {
  '&::-webkit-scrollbar': {
    width: '0.25rem',
    height: '0.25rem',
  },

  '&::-webkit-scrollbar-track': {
    borderRadius: '0.125rem',
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: THEME.COLORS.DARK_GREY_MAIN,
    borderRadius: '0.25rem',
  },
};
