import { Box, styled } from '@mui/material';

import { transientOptions } from '../../../../utils';

export const GameValuesContainer = styled(Box, transientOptions)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;

export const InfoWrapper = styled(Box, transientOptions)`
  display: flex;
  justify-content: space-between;
  margin: auto;
  max-width: 375px;
  padding-inline: 16px;
  gap: 24px;
  z-index: 1;
  padding-top: 16px;
`;
