import { generatePath, useNavigate } from 'react-router-dom';

import {
  BotName,
  BotScore,
  ControlWrapper,
  GearButton,
  GearButtonWrapper,
  StyledListItem,
} from './MyBotsListItem.styled';
import { BotDataUI } from '../../../../data-models';
import { GearIcon } from '../../../../components/icons';
import { ROUTES } from '../../../../constants';
import { useEffect, useState } from 'react';
import {
  useJoinToBattleMutation,
  useRemoveFromBattleMutation,
} from '../../../../redux/api';
import { enqueueSnackbar } from 'notistack';
import { PumpSwitch } from '../../../../components/ControllerFields/ControllerSwitch/PumpSwitch';

type MyBotListItemType = {
  botData: BotDataUI;
};

const SWITCH_STATE_LABELS = {
  active: 'joined',
  inactive: '',
};

export const MyBotsListItem = ({ botData }: MyBotListItemType) => {
  const [isBotActive, setIsBotActive] = useState<boolean>(false);
  const navigate = useNavigate();
  const [join] = useJoinToBattleMutation();
  const [remove] = useRemoveFromBattleMutation();

  useEffect(() => {
    setIsBotActive(Boolean(botData?.isActive));
  }, [botData]);

  const handleChangeBotParticipation = () => {
    if (!isBotActive) {
      setIsBotActive(true);
      join(botData?.id)
        .unwrap()
        .catch(() => {
          enqueueSnackbar('Error occured while joining battle.', {
            variant: 'error',
          });
          setIsBotActive(false);
        });
    } else {
      setIsBotActive(false);
      remove(botData?.id)
        .unwrap()
        .catch(() => {
          enqueueSnackbar('Error occured while leaving battle.', {
            variant: 'error',
          });
          setIsBotActive(true);
        });
    }
  };

  const handleSettingClick = () => {
    navigate(generatePath(ROUTES.MY_BOTS_EDIT, { id: botData?.id }));
  };

  return (
    <StyledListItem>
      <BotName noWrap>{botData?.name}</BotName>

      <ControlWrapper>
        <BotScore noWrap>{botData?.totalScore}</BotScore>

        <GearButtonWrapper>
          <GearButton onClick={handleSettingClick}>
            <GearIcon />
          </GearButton>
        </GearButtonWrapper>
        <PumpSwitch
          checked={isBotActive}
          isColored={false}
          onChange={handleChangeBotParticipation}
          switchLabel={SWITCH_STATE_LABELS}
        />
      </ControlWrapper>
    </StyledListItem>
  );
};
