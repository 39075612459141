import { Colors } from '../../data-models';
import { THEME } from '../../constants';

type StarIconType = {
  color?: Colors;
  variant: 'outline' | 'fill';
  width?: number;
  height?: number;
};

export const StarIcon = ({
  color = THEME.COLORS.BLACK_MAIN,
  variant,
  height = 14,
  width = 14,
}: StarIconType) => {
  switch (variant) {
    case 'outline':
      return (
        <svg
          width={width}
          height={height}
          viewBox='0 0 14 14'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g id='Star 2'>
            <path
              id='Vector'
              fillRule='evenodd'
              clipRule='evenodd'
              d='M9.42915 2.49409C8.66453 0.140847 5.33533 0.140847 4.57072 2.49409C4.34591 3.18597 3.70116 3.6544 2.97368 3.6544C0.499335 3.6544 -0.529447 6.82066 1.47234 8.27504C2.06089 8.70265 2.30716 9.4606 2.08235 10.1525C1.31774 12.5057 4.01112 14.4626 6.01291 13.0082C6.60145 12.5806 7.39841 12.5806 7.98696 13.0082C9.98874 14.4626 12.6821 12.5057 11.9175 10.1525C11.6927 9.4606 11.939 8.70265 12.5275 8.27504C14.5293 6.82066 13.5005 3.6544 11.0262 3.6544C10.2987 3.6544 9.65395 3.18597 9.42915 2.49409ZM5.40289 2.76448C5.90557 1.21738 8.09429 1.21738 8.59697 2.76448C8.93892 3.81687 9.91963 4.5294 11.0262 4.5294C12.6529 4.5294 13.3292 6.611 12.0132 7.56715C11.118 8.21757 10.7434 9.37047 11.0853 10.4229C11.588 11.97 9.8173 13.2565 8.50127 12.3003C7.60605 11.6499 6.39382 11.6499 5.49859 12.3003C4.18256 13.2565 2.41185 11.97 2.91453 10.4229C3.25647 9.37047 2.88187 8.21757 1.98665 7.56715C0.670615 6.611 1.34697 4.5294 2.97368 4.5294C4.08023 4.5294 5.06095 3.81687 5.40289 2.76448Z'
              fill={color}
            />
          </g>
        </svg>
      );

    case 'fill':
      return (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width={width}
          height={height}
          viewBox='0 0 16 16'
          fill='none'
        >
          <g clipPath='url(#clip0_1046_687)'>
            <path
              d='M7.16047 0.482612C7.24605 0.335768 7.36863 0.213928 7.51599 0.129246C7.66334 0.0445637 7.83033 0 8.00029 0C8.17025 0 8.33724 0.0445637 8.4846 0.129246C8.63196 0.213928 8.75454 0.335768 8.84011 0.482612L11.0135 4.21358L15.2344 5.12805C15.4004 5.16413 15.5541 5.2431 15.6801 5.35708C15.8061 5.47107 15.9 5.61609 15.9525 5.77768C16.005 5.93928 16.0142 6.11181 15.9792 6.27807C15.9442 6.44433 15.8662 6.59852 15.7531 6.72526L12.8759 9.94534L13.3114 14.2416C13.3285 14.4108 13.301 14.5815 13.2316 14.7367C13.1622 14.892 13.0532 15.0263 12.9157 15.1262C12.7781 15.2262 12.6167 15.2883 12.4476 15.3063C12.2786 15.3244 12.1077 15.2978 11.9521 15.2292L8.00029 13.4874L4.04848 15.2292C3.8929 15.2978 3.72203 15.3244 3.55295 15.3063C3.38387 15.2883 3.22249 15.2262 3.08492 15.1262C2.94735 15.0263 2.83841 14.892 2.76898 14.7367C2.69954 14.5815 2.67204 14.4108 2.68922 14.2416L3.12468 9.94534L0.247525 6.72604C0.134171 6.59931 0.0560428 6.44506 0.0209402 6.27869C-0.0141624 6.11233 -0.00501128 5.93967 0.0474799 5.77794C0.0999711 5.61622 0.193966 5.47109 0.320081 5.35706C0.446195 5.24302 0.600017 5.16405 0.76619 5.12805L4.98705 4.21358L7.16047 0.482612Z'
              fill={color}
            />
          </g>
          <defs>
            <clipPath id='clip0_1046_687'>
              <rect width='16' height='16' fill='white' />
            </clipPath>
          </defs>
        </svg>
      );
    default:
      return null;
  }
};
