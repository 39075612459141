import { useMediaQuery, useTheme } from '@mui/material';

import { IconWrapper, WrappedCircularProgress } from '../../../../components';
import { DiamondOutlineIcon, GearIcon } from '../../../../components/icons';
import { ShopItemData } from '../../../../data-models';
import { useShopListQuery } from '../../../../redux/api';
import {
  SkinsTabCardContainer,
  SkinsTabCardHeader,
  SkinsTabCardTitle,
  SkinsTabContainer,
  StyledBotCardMarker,
} from './SkinsTab.styled';
import { StyledMasonry } from '../GasTab/GasTab.styled';
import { BuyButton } from '../BuyButton/BuyButton';
import { useModalContext } from '../../../../context';
import { TYPE_MODAL } from '../../../../constants';

type BotCardType = {
  data: ShopItemData;
};

const BotCard = ({ data }: BotCardType) => {
  const theme = useTheme();
  const matches = useMediaQuery('(min-width:425px)', { noSsr: true });
  const { handleChangeModalParameters } = useModalContext();

  const isShowButtonIcon = matches || data?.priceTag?.price < 10000;
  const isYourBot = data?.isYourBot;

  const handleClick = () => {
    if (isYourBot) {
      handleChangeModalParameters();
      return;
    }

    handleChangeModalParameters([
      {
        key: 'type',
        value: TYPE_MODAL.BUY_BOT,
      },
      {
        key: 'isOpen',
        value: true,
      },
      {
        key: 'value',
        value: { price: data?.priceTag?.price, name: data.name },
      },
    ]);
  };

  return (
    <SkinsTabCardContainer $isOwner={isYourBot}>
      {isYourBot && <StyledBotCardMarker title='YOUR' />}

      <SkinsTabCardHeader $isOwner={isYourBot}>
        <SkinsTabCardTitle noWrap>{data.name}</SkinsTabCardTitle>
      </SkinsTabCardHeader>

      <BuyButton
        onClick={handleClick}
        price={data?.priceTag?.price}
        icon={
          isShowButtonIcon && (
            <IconWrapper
              configIcon={{
                $bgColor: isYourBot
                  ? theme.palette.lime.main
                  : theme.palette.darkGrey.light,
                $size: 32,
                $iconSize: 16,
              }}
            >
              {isYourBot ? (
                <GearIcon color={theme.palette.black.main} />
              ) : (
                <DiamondOutlineIcon color={theme.palette.white.main} />
              )}
            </IconWrapper>
          )
        }
      />
    </SkinsTabCardContainer>
  );
};

export const SkinsTab = () => {
  const { data, isLoading } = useShopListQuery(undefined, {
    selectFromResult: (result) => ({
      ...result,
      data: result.data?.BOT?.filter((botItem) => botItem.isActive),
    }),
  });

  return (
    <SkinsTabContainer>
      {isLoading && <WrappedCircularProgress />}

      {data && (
        <StyledMasonry columns={2} spacing={1.5}>
          {data.map((shopItem) => (
            <BotCard data={shopItem} key={shopItem.id} />
          ))}
        </StyledMasonry>
      )}
    </SkinsTabContainer>
  );
};
