import { createSlice } from '@reduxjs/toolkit';

export type ServiceSliceState = {
  latestCoinBalance?: number;
  profitCoinDelta?: number;
  latestGasBalance?: number;
  profitGasDelta?: number;
  isFirstBalanceRequest: boolean;
  isFirstLottieRender: boolean;
};

const initialState: ServiceSliceState = {
  latestCoinBalance: 0,
  profitCoinDelta: 0,
  latestGasBalance: 0,
  profitGasDelta: 0,
  isFirstBalanceRequest: true,
  isFirstLottieRender: true,
};

export const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    updateLatestCoinBalanceValue: (state, action) => {
      state.latestCoinBalance = action.payload;
    },
    updateCoinProfitDelta: (state, action) => {
      state.profitCoinDelta = action.payload;
    },
    updateLatestGasBalanceValue: (state, action) => {
      state.latestGasBalance = action.payload;
    },
    updateGasProfitDelta: (state, action) => {
      state.profitGasDelta = action.payload;
    },
    setIsFirstBalanceRequest: (state, action) => {
      state.isFirstBalanceRequest = action.payload;
    },
    setIsFirstLottieRender: (state, action) => {
      state.isFirstLottieRender = action.payload;
    },
  },
});

export const serviceActions = serviceSlice.actions;
export default serviceSlice.reducer;
