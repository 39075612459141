type PumpCurrencyCoinIconType = {
  size?: number | string;
};

export const PumpCurrencyCoinIcon = ({ size }: PumpCurrencyCoinIconType) => {
  return (
    <svg
      width={size || '31'}
      height={size || '30'}
      viewBox='0 0 31 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M28.7896 15C28.7896 22.732 22.5687 29 14.8948 29C7.22092 29 1 22.732 1 15C1 7.26801 7.22092 1 14.8948 1C22.5687 1 28.7896 7.26801 28.7896 15Z'
        fill='#0D283B'
      />
      <path
        d='M1.91115 23.8029L7.61152 9.58713C8.42005 7.57081 10.6978 6.59666 12.6989 7.41131L19.647 10.2397C23.4572 11.7908 24.8562 13.8284 23.8441 16.3525L21.7107 21.6729C20.6919 24.2135 18.2774 24.7082 14.4672 23.1572L7.51917 20.3287L5.53459 25.2779L1.91115 23.8029ZM9.99262 14.1604C9.09239 16.4054 10.1689 18.9606 12.397 19.8676L15.2359 21.0233C16.6803 21.6113 17.5977 21.4186 17.988 20.4453L20.3199 14.63C20.7036 13.6732 20.1732 12.9007 18.7288 12.3127L16.4662 11.3917C13.9198 10.3551 11.0214 11.5946 9.99262 14.1604Z'
        fill='#C61C1C'
      />
      <path
        d='M29 7.52867L23.4323 21.4135C22.6238 23.4299 20.3461 24.404 18.3449 23.5894L11.3969 20.7609C7.58666 19.2098 6.17825 17.1956 7.17164 14.7183L9.26555 9.49642C10.2654 7.00288 12.6705 6.53165 16.4807 8.08273L23.4287 10.9112L25.3766 6.05363L28.1035 4.46961L29 7.52867ZM21.0011 16.9653C21.8846 14.7619 20.8281 12.2539 18.6412 11.3637L15.7276 10.1776C14.2832 9.58961 13.3695 9.77327 12.9864 10.7286L10.6977 16.4362C10.3211 17.3753 10.855 18.1389 12.2994 18.7269L14.6474 19.6827C17.1467 20.7001 19.9913 19.4835 21.0011 16.9653Z'
        fill='#2CB94B'
      />
      <path
        d='M1.44707 23.6168L1.26195 24.0784L1.72263 24.266L5.12861 25.6525C7.69418 28.0405 11.1246 29.5 14.8948 29.5C22.8484 29.5 29.2896 23.0046 29.2896 15C29.2896 13.3107 29.0027 11.6886 28.4753 10.1806L29.4641 7.71477L29.5285 7.55413L29.4798 7.38805L28.5833 4.32898L28.4039 3.71686L27.8523 4.03726L25.6232 5.33215C22.988 2.36681 19.1587 0.5 14.8948 0.5C6.94123 0.5 0.5 6.99543 0.5 15C0.5 17.4559 1.10632 19.7697 2.17661 21.7974L1.44707 23.6168Z'
        stroke='white'
      />
    </svg>
  );
};
