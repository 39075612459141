import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';

import { Container, StyledButton, Value } from './ControllerNumber.styed';
import { MinusIcon, PlusIcon } from '../../icons';

interface FormTextFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TFieldName> {}

export function ControllerNumber<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ control, name }: FormTextFieldProps<TFieldValues, TFieldName>) {
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
  });

  const handleChange = (isMinus?: boolean) => () => {
    if (isMinus) {
      if (value < 1) {
        return;
      } else {
        onChange(value - 1);
      }
    } else {
      if (value === 100) {
        return;
      } else {
        onChange(value + 1);
      }
    }
  };

  return (
    <Container>
      <StyledButton color='secondary' onClick={handleChange(true)}>
        <MinusIcon />
      </StyledButton>

      <Value>{value}</Value>

      <StyledButton color='secondary' onClick={handleChange()}>
        <PlusIcon />
      </StyledButton>
    </Container>
  );
}
