import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  IconWrapper,
  GameInfoWrapper,
  ValueWrapper,
  ProfitValue,
} from './GameDataValue.styled';
import {
  getCoinProfitDelta,
  getGasProfitDelta,
} from '../../redux/selectors/serviceSelector';
import { serviceActions } from '../../redux/reducers/serviceSlice';

interface GameInfoBlockProps {
  icon: any;
  infoValue: string | number;
  isTimer?: boolean;
  isCoinBalance?: boolean;
  isGasBalance?: boolean;
  isTimerCloseToOver?: boolean;
}

export const GameInfoBlock = ({
  icon,
  infoValue,
  isTimer,
  isCoinBalance,
  isGasBalance,
  isTimerCloseToOver,
}: GameInfoBlockProps) => {
  const profitCoinDelta = useSelector(getCoinProfitDelta);
  const profitGasDelta = useSelector(getGasProfitDelta);
  const [isCoinDeltaVisible, setIsCoinDeltaVisible] = useState<boolean>(false);
  const [isGasDeltaVisible, setIsGasDeltaVisible] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (profitCoinDelta) {
      setIsCoinDeltaVisible(true);
    }

    const visibilityTimer = setTimeout(() => {
      setIsCoinDeltaVisible(false);
    }, 3000);

    const dispatchTimer = setTimeout(() => {
      dispatch(serviceActions.updateCoinProfitDelta(0));
    }, 4000);

    return () => {
      clearTimeout(visibilityTimer);
      clearTimeout(dispatchTimer);
    };
  }, [profitCoinDelta]);

  useEffect(() => {
    if (profitGasDelta) {
      setIsGasDeltaVisible(true);
    }

    const visibilityTimer = setTimeout(() => {
      setIsGasDeltaVisible(false);
    }, 3000);

    const dispatchTimer = setTimeout(() => {
      dispatch(serviceActions.updateGasProfitDelta(0));
    }, 4000);

    return () => {
      clearTimeout(visibilityTimer);
      clearTimeout(dispatchTimer);
    };
  }, [profitGasDelta]);

  const formatBalance = (balance: number | string) => {
    return balance?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const renderDeltaValue = () => {
    if (isCoinBalance && isCoinDeltaVisible) {
      return `+${profitCoinDelta}.00`;
    } else if (isGasBalance && isGasDeltaVisible) {
      return `+${profitGasDelta}.00`;
    } else {
      return '';
    }
  };

  return (
    <GameInfoWrapper isTimerOver={Boolean(isTimer && isTimerCloseToOver)}>
      <ProfitValue>{renderDeltaValue()}</ProfitValue>

      <IconWrapper>{icon}</IconWrapper>
      <ValueWrapper>
        <span>
          {isCoinBalance || isGasBalance ? formatBalance(infoValue) : infoValue}
        </span>
      </ValueWrapper>
    </GameInfoWrapper>
  );
};
