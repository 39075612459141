export const MinusIcon = () => {
  return (
    <svg
      width='12'
      height='2'
      viewBox='0 0 12 2'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Minus'>
        <path
          id='Vector'
          d='M0.666626 0.5C0.390484 0.5 0.166626 0.723858 0.166626 1C0.166626 1.27614 0.390484 1.5 0.666626 1.5H11.3333C11.6094 1.5 11.8333 1.27614 11.8333 1C11.8333 0.723858 11.6094 0.5 11.3333 0.5H0.666626Z'
          fill='white'
        />
      </g>
    </svg>
  );
};
