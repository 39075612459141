import { Avatar, Box, styled, Typography } from '@mui/material';

export const ProfileInfoContainer = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const StyledAvatar = styled(Avatar)``;
export const UserFullName = styled(Typography)`
  text-transform: unset;
`;
