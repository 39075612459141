export function ProfileLogoColoredIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='111'
      height='150'
      fill='none'
      viewBox='0 0 111 150'
    >
      <path
        fill='#19473E'
        d='M55.272 5.085c1.42-.143 2.822-.049 4.243-.067 2.482-.012 4.966.018 7.448-.018.517.002 1.063-.012 1.568.1.394.214.298.822.342 1.207-.024 2.553-.006 5.107-.028 7.66-.002.63.052 1.257.11 1.883.58-.344 1.128-.618 1.826-.575 1.737.062 3.484-.081 5.213.092.095 1.347.028 2.688.037 4.036-.007 2.753.003 5.504-.003 8.256.006 1.158-.102 2.278.121 3.426 1.323.272 2.6.703 3.845 1.224.958.421 1.811 1.037 2.781 1.444 1.484.627 2.643 1.597 3.927 2.538.697.511 1.319 1.103 1.907 1.736.819.883 1.74 1.602 2.502 2.539 1.825 2.276 3.24 4.805 4.408 7.471.628 1.666 1.163 3.422 1.338 5.198.256 1.546.6 3.049.568 4.632.012 5.368.014 10.741.008 16.11.004 1.146.137 2.217-.238 3.33l-.388-.078c-1.826-.531-3.71-.76-5.54-1.28.172-1.877.11-3.714.125-5.597-.01-3.02.037-6.043-.024-9.062-.016-1.526-.41-2.934-.716-4.412a21.057 21.057 0 00-.944-3.077c-.564-1.436-1.659-2.568-2.587-3.82-1.66-2.204-3.768-3.88-6.038-5.41-.719-.449-1.57-.755-2.357-1.071a22.074 22.074 0 00-8.746-1.672c-4.495.029-8.991-.004-13.49.029-.383-.045-.994.056-1.202-.345-.177-.898-.076-1.864-.088-2.777.006-9.865 0-19.735.004-29.603.01-1.353-.069-2.696.068-4.047z'
      ></path>
      <path
        fill='#A63325'
        d='M34.764 10.51c1.531-.125 3.085-.026 4.623-.053 1.689.029 3.383-.076 5.066.05.087 1.02.077 2.037.063 3.056.004 12.485-.002 24.971.002 37.457.006 1.298-.047 2.595.034 3.894 1.298.079 2.594.03 3.893.038 5.167 0 10.331.009 15.498-.005 1.711-.047 3.19.25 4.755.934 2.063.818 3.726 2.334 4.945 4.166a10.917 10.917 0 011.294 3.396c.091.393.013.749-.086 1.13-1.423.068-2.842.022-4.265.026-12.204-.025-24.396-.014-36.6.006.22-.35.445-.65.437-1.086-.018-11.16.008-22.238-.012-33.443-.038-.852.137-1.9-.179-2.697-.429-.215-1.014-.155-1.483-.173-5.437.075-10.868-.028-16.302.095-.342.024-.644.03-.733.429-.159.823-.085 1.71-.093 2.547-.05 13.223-.03 26.45-.024 39.672-.042 1.567.123 3.16-.046 4.717l-.312.112c.076-1.542.026-3.077.034-4.627.002-13.962-.012-27.926.012-41.887.069-1.91-.022-3.819.073-5.726a25.07 25.07 0 012.492-.07c5.169.032 10.34-.014 15.506.026.38-.008.747-.022 1.105-.165.202-.538.182-1.15.186-1.718-.036-2.888.016-5.78-.026-8.667-.009-.488.032-.961.142-1.434z'
      ></path>
      <path
        fill='#CB3B24'
        d='M16.447 27.3c5.434-.122 10.865-.02 16.302-.094.469.018 1.054-.042 1.483.174.316.797.141 1.844.18 2.696.02 11.205-.007 22.283.011 33.443.008.435-.217.735-.436 1.086-3.924.024-7.855-.059-11.776.036-.038 2.807-.046 5.635.009 8.44 1.076-.012 2.03-.389 3.075-.584 1.206-.238 2.42-.457 3.641-.592 3.164-.236 6.42.096 9.445 1.07 2.31.954 4.498 1.943 6.137 3.9-.039.515-.05 1.029-.063 1.544-.583.375-1.314 1.003-2.03 1.023-.886-.17-1.718-.91-2.474-1.379-.659-.439-1.387-.705-2.138-.944-1.043-.33-1.96-.742-3.05-.897-1.614-.221-3.202-.424-4.836-.294-3.185.254-6.39 1.132-9.5 1.871-.442.119-.947.206-1.337.45-.171.213-.29.479-.399.726-.074-.167-.15-.332-.225-.5-.457.134-.912.275-1.373.396-1.282-.01-2.436.19-3.67.52-1.795.474-3.554.523-5.277 1.205-1.057.178-2.516.532-3.22-.543-.814-1.041-.411-2.578.784-3.106 1.317-.53 2.814-.662 4.195-.974 1.804-.3 3.585-.663 5.334-1.195l.312-.112c.17-1.557.004-3.15.046-4.717-.006-13.222-.026-26.449.024-39.672.008-.837-.066-1.724.093-2.547.089-.399.39-.405.733-.43zM78.59 73.232c1.007.25 2.028.409 3.048.588 3.754.755 7.355 1.998 11.12 2.735 1.362.23 2.658.636 4.049.674l.388.079c1.075.235 2.142.481 3.239.59.862.105 1.743.143 2.591.324.923.191 1.455 1.106 1.475 2-.032.969-.692 1.816-1.695 1.895-1.831.167-3.732-.163-5.547-.403-.825-.181-1.64-.4-2.478-.52-1.328-.32-2.691-.453-4.011-.803l-.649-.183c-1.924-.566-3.868-1.06-5.795-1.617a37.786 37.786 0 00-5.52-1.261c-1.673-.1-3.469-.115-5.14.018-1.592.284-3.313.63-4.728 1.446-.708.35-1.413.898-2.174 1.097-.555.11-1.197.087-1.71-.175-.359-.211-.586-.676-.797-1.02-.039-.454-.057-.905-.09-1.358.538-.52 1.064-1.027 1.698-1.432 1.012-.664 2.103-1.007 3.212-1.474 2.919-1.293 6.373-1.524 9.515-1.2z'
      ></path>
      <path
        fill='#CB3B24'
        d='M78.59 73.232c1.072.006 2.05.113 3.088.372 2.122.423 4.2 1.003 6.29 1.553 1.102.346 2.16.602 3.3.791 1.83.52 3.713.75 5.539 1.281-1.39-.038-2.687-.445-4.05-.674-3.764-.737-7.365-1.98-11.119-2.735-1.02-.18-2.04-.338-3.047-.588z'
      ></path>
      <path
        fill='#F5F7F5'
        d='M44.518 76.875c2.113.008 4.229.052 6.344-.012.874-.05 1.737-.183 2.613-.208 1.952-.104 3.889.143 5.819.435 1.622.17 3.247.174 4.873.248.032.453.05.904.088 1.357-1.348 1.29-2.248 3.108-2.771 4.88-.453 1.792-.669 3.683-.306 5.512.59 3.67 2.87 7.064 5.992 9.068 1.63 1.136 3.492 1.716 5.429 2.074 1.2.125 2.427.079 3.633.054 3.903-.39 7.542-2.322 10.024-5.366 1.92-2.093 3.15-4.71 3.713-7.484.17-1.315.107-2.672.085-3.997.002-1.194-.435-2.159-.672-3.331.247.03.493.064.738.103l.648.183c.256 1.07.52 2.157.634 3.25.04.937.049 1.891-.004 2.826-.122.966-.4 1.919-.648 2.857-1.095 3.365-3.202 6.49-5.92 8.758-2.407 1.871-5.36 3.142-8.399 3.464-1.751.26-3.448.058-5.175-.258-5.31-1.164-9.905-5.524-11.197-10.832-.314-1.744-.658-3.363-.349-5.147.19-.882.252-1.698.604-2.53.473-1.117 1.115-2.152 1.605-3.264-1.065-.3-2.124-.304-3.211-.435-.861-.149-1.719-.31-2.586-.417a11.806 11.806 0 00-3.642-.012c-1.272.177-2.556.218-3.826.41-.592.067-1.2.07-1.796.083.27.802.619 1.571.896 2.369.604 1.987.98 4.025.624 6.1-.235 1.3-.543 2.68-1.171 3.85-.675 1.313-1.325 2.6-2.36 3.677-1.27 1.583-2.854 2.856-4.62 3.844-2.024.869-4.053 1.547-6.267 1.676-3.319.171-6.817-.638-9.553-2.578-2.558-1.466-4.504-3.72-5.873-6.307-.708-1.34-1.353-2.737-1.624-4.235-.532-2.17-.393-4.507-.262-6.744.187-.635.348-1.269.445-1.925.46-.121.916-.262 1.373-.395.074.167.15.332.225.5-.896 2.265-.82 4.504-.745 6.89.087 1.465.544 2.848 1.125 4.183 1.017 2.485 2.889 4.662 4.96 6.34 1.743 1.266 3.758 2.251 5.871 2.712 4.258.836 8.79-.157 12.19-2.89 1.457-1.27 2.71-2.726 3.603-4.45.562-1.19 1.05-2.392 1.276-3.695.586-2.957-.094-5.887-1.5-8.507-.263-.455-.631-.791-1.016-1.14.012-.515.024-1.029.063-1.544z'
      ></path>
      <path
        fill='#222023'
        d='M73.665 77.348c1.671-.133 3.466-.119 5.14-.018 1.861.286 3.723.699 5.52 1.26 1.927.558 3.871 1.052 5.795 1.618a22.653 22.653 0 00-.738-.103c.237 1.172.674 2.137.672 3.33.022 1.326.084 2.683-.085 3.998-.563 2.773-1.793 5.391-3.713 7.484.573-1.265 1.564-2.23 2.155-3.484.483-1.027.94-2.105 1.18-3.216.26-1.547.392-3.096.22-4.658-.11-.983-.168-2.145-.623-3.037-.195-.463-.833-.572-1.264-.709-3.26-.781-6.27-2.012-9.677-2.257-1.8-.228-3.583-.075-5.369.175-1.334.254-2.626.719-3.94 1.063 1.414-.816 3.135-1.162 4.727-1.446z'
      ></path>
      <path
        fill='#A63325'
        d='M52.48 78.651a11.809 11.809 0 013.642.012c-.31.115-.88.133-.878.568-.135 1.323-.01 2.678-.03 4.01-.022 4.496-.004 8.995-.008 13.492.006 1.376-.05 2.73.104 4.102-1.513.105-2.995.437-4.516.469 1.304-.584 2.65-.61 4.04-.805.068-3.87.017-7.75-.003-11.62-.006-2.187.113-4.386-.024-6.569-1.825-.05-3.726-.058-5.529.242.264 1.373.855 2.638 1.113 4.011.614 2.797.334 5.655-.525 8.368-.377 1.222-1.152 2.302-1.854 3.369-1.137 1.72-2.727 2.956-4.489 3.989-1.292.743-2.673 1.102-4.126 1.363-1.121.194-2.297.498-3.428.373-.865-.095-1.723-.28-2.578-.443-2.216-.437-4.314-1.488-6.208-2.692a6.54 6.54 0 01-1.872-1.79c.932.217 1.788.62 2.687.93 1.194.376 2.478.668 3.734.67 1.01 0 2.025.016 3.035-.014 1.15-.036 2.305-.417 3.404-.755a8.893 8.893 0 002.055-.947c1.768-.988 3.352-2.261 4.622-3.844 1.034-1.077 1.684-2.364 2.359-3.677.628-1.17.936-2.55 1.171-3.85.356-2.075-.02-4.113-.624-6.1-.277-.798-.626-1.567-.895-2.369.595-.014 1.203-.016 1.795-.082 1.27-.194 2.554-.234 3.826-.411zM17.093 78.87a13.97 13.97 0 01-.445 1.926c-.443 1.27-.395 2.54-.435 3.862-.016 1.378-.561 2.634-.541 4.018-.04 4.968-.07 9.936-.046 14.904-.008 1.772.159 3.516-.029 5.286-.179-.749-.312-1.49-.298-2.263-.044-8.057-.01-16.11.019-24.168.018-1.023.032-2.042-.063-3.061-2.375.234-4.726 1.098-7.11 1.222 1.724-.682 3.483-.73 5.279-1.206 1.233-.328 2.387-.53 3.669-.52z'
      ></path>
      <path
        fill='#19473E'
        d='M56.046 78.663c.868.107 1.725.268 2.587.417 1.086.13 2.145.135 3.21.435-.49 1.112-1.131 2.147-1.604 3.264-.352.832-.415 1.648-.604 2.53-.31 1.784.034 3.403.348 5.147 1.292 5.308 5.888 9.668 11.197 10.832 1.727.316 3.424.518 5.175.258 3.04-.322 5.993-1.593 8.4-3.464 2.717-2.267 4.825-5.393 5.92-8.758.867-1.834.803-3.707.734-5.687-.078-1.013-.404-1.98-.45-2.998 1.258.16 2.471.493 3.745.556.838.118 1.653.338 2.478.52.274 1.654.171 3.282.183 4.949.009 4.698-.042 9.398-.024 14.097.018 1.681-.147 3.361-.221 5.042-.218 2.213-.719 4.427-1.085 6.618-.443 2.378-1.411 4.414-2.18 6.677-.507 1.396-1.294 2.683-2.007 4.002a42.098 42.098 0 01-10.658 12.922c-1.008.933-2.244 1.485-3.303 2.343-1.032.833-2.151 1.443-3.277 2.148-1.902 1.138-4.174 1.579-6.195 2.52-1.546.753-3.18 1.063-4.82 1.534-2.758.618-5.545.69-8.415.69.04-2.23-.171-21.786-.151-32.629 1.016.427 2.09 1.259 3.086 1.724 1.4.679 2.883 1.234 4.406 1.563 4.274.797 8.889.572 13.229.473 2.147-.167 4.33-.278 6.44-.721 1.027-.187 2.03-.27 3.028-.614 1.14-.405 2.309-.701 3.434-1.148-2.158-.065-4.291-.244-6.403-.703a38.735 38.735 0 01-7.512-2.751c-.793-.391-1.467-.926-2.188-1.43-.932-.654-1.997-1.051-2.93-1.707-1.772-1.243-3.5-2.586-5.337-3.734-1.8-1.112-3.777-1.849-5.818-2.38-1.063-.198-2.154-.246-3.23-.365-.154-1.371-.098-2.726-.104-4.102.004-4.497-.014-8.996.008-13.493.02-1.33-.104-2.686.03-4.009-.002-.435.568-.453.878-.568z'
      ></path>
      <path
        fill='#222023'
        d='M90.768 80.391c1.32.35 2.683.483 4.012.803-1.274-.062-2.488-.394-3.746-.555.046 1.019.372 1.985.451 2.998.068 1.98.133 3.853-.735 5.687.248-.938.526-1.89.648-2.857.053-.935.045-1.89.004-2.826-.114-1.093-.378-2.18-.634-3.25z'
      ></path>
      <path
        fill='#CB3B24'
        d='M16.648 80.796c-.13 2.237-.27 4.573.262 6.744.271 1.498.916 2.896 1.624 4.235 1.369 2.588 3.315 4.841 5.873 6.307 2.736 1.94 6.234 2.749 9.553 2.578 2.214-.129 4.243-.807 6.266-1.675a8.888 8.888 0 01-2.055.946c-1.099.338-2.254.719-3.404.755-1.01.03-2.024.014-3.035.014-1.256-.002-2.54-.294-3.734-.67-.9-.31-1.755-.713-2.687-.93a6.54 6.54 0 001.872 1.79c1.894 1.204 3.992 2.255 6.208 2.692.855.163 1.713.348 2.578.443 1.131.125 2.307-.179 3.428-.372 1.453-.262 2.834-.621 4.126-1.364 1.761-1.033 3.352-2.269 4.489-3.99.702-1.067 1.477-2.146 1.854-3.368.86-2.713 1.139-5.57.525-8.368-.258-1.373-.85-2.638-1.113-4.011 1.803-.3 3.704-.292 5.53-.242.136 2.183.017 4.382.023 6.57.02 3.87.07 7.748.002 11.619-1.389.195-2.735.221-4.04.805-1.251.393-2.455.665-3.594 1.251-.958.489-1.94.985-2.83 1.589-1.323.89-2.573 1.877-3.91 2.745-1.612 1.19-3.254 2.31-5.096 3.121-3.651 1.923-7.592 2.948-11.668 3.454-1.157.127-2.31.014-3.472.123.783.459 1.612.58 2.472.823 1.95.532 4.001 1.094 6.01 1.348 1.775.167 3.472.416 5.247.41 3.489.02 6.979.033 10.467-.002 1.653-.002 3.217-.37 4.807-.777 1.11-.292 2.192-.86 3.236-1.343.912-.407 1.743-1.073 2.687-1.418 0 10.806.106 21.904.106 32.629-4.162.026-8.551-.859-12.483-2.194-1.78-.691-3.612-1.38-5.263-2.342-2.587-1.307-4.968-3.079-7.282-4.813-1.265-.913-2.18-2.062-3.318-3.13-1.39-1.444-2.699-2.998-3.82-4.662-.815-1.196-1.715-2.293-2.445-3.546-.526-.87-.918-1.794-1.34-2.715-2.016-4.013-3.167-8.544-3.707-12.989.188-1.77.02-3.514.029-5.286-.025-4.968.006-9.936.046-14.904-.02-1.384.525-2.64.541-4.018.04-1.323-.008-2.591.435-3.862z'
      ></path>
    </svg>
  );
}
