import { createApi } from '@reduxjs/toolkit/query/react';

import { client } from './client';
import { CLIENT_ENDPOINTS } from '../../constants';
import { LeaderboardItemData } from '../../data-models/game';

export const leaderBoardAPI = createApi({
  reducerPath: 'leaderBoardApi',
  baseQuery: client,
  keepUnusedDataFor: 5,
  endpoints: (builder) => ({
    leaderBoardList: builder.query<LeaderboardItemData[], void>({
      query: () => ({
        url: CLIENT_ENDPOINTS.LEADER_BOARD,
        method: 'GET',
      }),
    }),
  }),
});

export const { useLeaderBoardListQuery } = leaderBoardAPI;
