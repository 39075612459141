import { Box, styled, Typography } from '@mui/material';

import { THEME } from '../../../../../constants';

export const GameTitle = styled(Typography)`
  text-transform: uppercase;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.darkGrey.main};
`;

export const GameSubtitle = styled(Typography)`
  font-weight: 500;
  color: ${({ theme }) => theme.palette.greyCustom.main};
`;

export const BuySellTitle = styled(Typography)`
  font-size: 36px;
  font-weight: 600;
  max-width: 250px;
  color: ${({ theme }) => theme.palette.black.main};
`;

export const BuySellSubtitle = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.black.main};
`;

export const TestBotContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 8px;
  gap: 16px;
`;

export const TestBotTitle = styled(Typography)`
  font-size: 24px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.black.main};
`;

export const TestBotSpeedMarker = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  padding: 8px;
  border-radius: ${THEME.BORDER_RADIUS.PILL};
  background-color: ${({ theme }) => theme.palette.green.main};
  color: ${({ theme }) => theme.palette.black.main};
`;
