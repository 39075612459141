export const ROUTES = {
  HOME: '/',
  MY_BOTS: '/my-bots',
  MISSIONS: '/missions',
  MY_BOTS_CREATE: '/my-bots/create',
  MY_BOTS_EDIT: '/my-bots/edit/:id',
  MARKETPLACE: '/marketplace',
  MY_NFT: '/my-nft',
  PROFILE: '/profile',
};
