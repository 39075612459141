import React, { useEffect, useState } from 'react';
import { Fade } from '@mui/material';

import { Navigation } from '../../Navigation/Navigation';

import { LayoutContainer, LayoutWrapper } from './BaseLayout.styled';
import { Outlet, useLocation } from 'react-router-dom';

export const BaseLayout = () => {
  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState<string>();

  useEffect(() => {
    if (location.pathname !== displayLocation) {
      setDisplayLocation(location.pathname);
    }
  }, [location.pathname]);

  return (
    <LayoutContainer maxWidth='sm'>
      <Fade
        in={location.pathname === displayLocation}
        timeout={{ exit: 0, enter: 500 }}
      >
        <LayoutWrapper>
          <Outlet />
        </LayoutWrapper>
      </Fade>
      <Navigation />
    </LayoutContainer>
  );
};
