import { Box, Button, ListItem, Typography, styled } from '@mui/material';

export const StyledListItem = styled(ListItem)`
  justify-content: space-between;
  padding: 0;
`;

export const BotName = styled(Typography)`
  color: ${({ theme }) => theme.palette.greyCustom.main};
`;

export const BotScore = styled(Typography)`
  font-weight: bold;
  max-width: 100px;
`;

export const ControlWrapper = styled(Box)`
  display: flex;
  gap: 18px;
`;

export const GearButtonWrapper = styled(Box)`
  display: flex;
  margin-left: 10px;
  min-width: 21px;
`;

export const GearButton = styled(Button)`
  padding: 0;
  background-color: transparent;
  min-width: 21px;
  width: 21px;
  height: 23px;
`;
