import { alpha } from '@mui/material';
import { createTheme } from '@mui/material/styles';

import { THEME } from '../constants';

export const muiDarkTheme = createTheme({
  mode: 'dark',
  palette: {
    text: {
      primary: THEME.COLORS.WHITE,
    },
    background: {
      default: THEME.COLORS.BLACK_MAIN,
    },
    primary: {
      main: THEME.COLORS.YELLOW_MAIN,
      light: THEME.COLORS.YELLOW_MAIN,
      dark: THEME.COLORS.YELLOW_DARK,
      contrastText: THEME.COLORS.BLACK_MAIN,
    },
    error: {
      light: THEME.COLORS.RED_LIGHT,
      main: THEME.COLORS.RED_MAIN,
      dark: THEME.COLORS.RED_DARK,
    },
    secondary: {
      main: THEME.COLORS.GREEN_MAIN,
    },
    greyCustom: {
      main: THEME.COLORS.GREY_MAIN,
      light: THEME.COLORS.GREY_LIGHT,
      dark: THEME.COLORS.GREY_DARK,
      contrastText: THEME.COLORS.GREY_CONTRAST,
    },
    darkGrey: {
      main: THEME.COLORS.DARK_GREY_MAIN,
      light: THEME.COLORS.DARK_GREY_LIGHT,
      contrastText: THEME.COLORS.WHITE,
    },
    white: {
      main: THEME.COLORS.WHITE,
      light: THEME.COLORS.WHITE,
      dark: THEME.COLORS.WHITE,
      contrastText: THEME.COLORS.BLACK_DARK,
    },
    platinum: {
      main: THEME.COLORS.PLATINUM,
    },
    black: {
      main: THEME.COLORS.BLACK_MAIN,
      dark: THEME.COLORS.BLACK_DARK,
      light: THEME.COLORS.BLACK_SECONDARY,
      contrastText: THEME.COLORS.BLACK_CONTRAST,
    },
    dark: {
      main: THEME.COLORS.BLACK_MAIN,
    },
    lime: {
      main: THEME.COLORS.LIME_MAIN,
      light: THEME.COLORS.LIME_LIGHT,
    },
    dimGrey: {
      main: THEME.COLORS.DIM_GREY_MAIN,
      dark: THEME.COLORS.CARARRA_MAIN,
    },
    bluePurple: {
      main: THEME.COLORS.BLUE_PURPLE_MAIN,
    },
    homeBg: {
      main: THEME.COLORS.HOME_BACKGROUND,
    },
    orange: {
      main: THEME.COLORS.ORANGE_MAIN,
    },
    green: {
      main: THEME.COLORS.GREEN_MAIN,
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          paddingInline: '8px',
          width: '100%',
          fontSize: '16px',
          fontWeight: 500,
          borderRadius: THEME.BORDER_RADIUS.PILL,
          textTransform: 'unset',
        },

        contained: {
          position: 'relative',

          '&::before': {
            content: '""',
            top: 0,
            right: 0,
            borderRadius: THEME.BORDER_RADIUS.PILL,
            display: 'block',
            width: '100%',
            height: '100%',
            position: 'absolute',
            border: `1px solid ${alpha(THEME.COLORS.BLACK_MAIN, 0.25)}`,
          },
        },

        containedBluePurple: {
          '&.Mui-disabled': {
            color: THEME.COLORS.DIM_GREY_MAIN,

            '&::before': {
              borderColor: THEME.COLORS.DARK_GREY_LIGHT,
            },
          },
        },

        outlinedPrimary: {
          color: THEME.COLORS.YELLOW_MAIN,
          borderColor: THEME.COLORS.YELLOW_MAIN,
        },

        sizeLarge: {
          height: '62px',
        },

        sizeMedium: {
          height: '52px',
        },

        sizeSmall: {
          height: '42px',
        },

        outlinedInfo: {
          background: 'transparent',
          borderColor: THEME.COLORS.WHITE,
          color: THEME.COLORS.WHITE,
          fontSize: '16px',
          fontFamily: 'Aldrich',
          fontWeight: 400,
          textTransform: 'uppercase',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
        outlinedError: {
          background: THEME.COLORS.RED_DARK,
          borderColor: THEME.COLORS.RED_LIGHT,
          color: THEME.COLORS.RED_LIGHT,
          fontSize: '16px',
          fontFamily: 'Aldrich',
          fontWeight: 400,
          textTransform: 'uppercase',
          '&:hover': {
            backgroundColor: THEME.COLORS.RED_DARK,
          },
        },
        outlinedSuccess: {
          background: THEME.COLORS.GREEN_SUCCESS,
          borderColor: THEME.COLORS.GREEN_SUCCESS_ACCENT,
          color: THEME.COLORS.GREEN_SUCCESS_ACCENT,
          fontSize: '16px',
          fontFamily: 'Aldrich',
          fontWeight: 400,
          textTransform: 'uppercase',
          '&:hover': {
            backgroundColor: THEME.COLORS.GREEN_SUCCESS,
          },
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        outlined: {
          fontWeight: '400',
        },
      },
    },

    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: THEME.BORDER_RADIUS.PILL,
          fontWeight: 500,
        },
        outlined: {
          borderWidth: '1px',
        },
        outlinedError: {
          backgroundColor: THEME.COLORS.DARK_GREY_MAIN,
          borderColor: THEME.COLORS.RED_MAIN,
          color: THEME.COLORS.WHITE,
        },
        outlinedWarning: {
          backgroundColor: THEME.COLORS.DARK_GREY_MAIN,
          borderColor: THEME.COLORS.YELLOW_MAIN,
          color: THEME.COLORS.WHITE,
        },
      },
    },

    MuiCircularProgress: {
      colorDarkGrey: {
        color: THEME.COLORS.BLACK_MAIN,
      },
      styleOverrides: {
        root: {
          color: THEME.COLORS.WHITE,
        },
      },
    },

    MuiBackdrop: {
      styleOverrides: {
        root: {
          background:
            'radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.00) 0%, rgba(157, 152, 134, 0.56) 100%)',
          backdropFilter: 'blur(4px)',
        },
      },
    },

    MuiModal: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },

    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 52,
          height: 28,
          padding: 0,
          margin: 0,
          borderRadius: THEME.BORDER_RADIUS.PILL,
          backgroundColor: THEME.COLORS.DARK_GREY_LIGHT,

          '&::before': {
            content: '""',
            top: 0,
            right: 0,
            borderRadius: THEME.BORDER_RADIUS.PILL,
            display: 'block',
            width: '100%',
            height: '100%',
            position: 'absolute',
            border: `1px solid ${alpha(THEME.COLORS.WHITE, 0.3)}`,
          },
        },

        switchBase: {
          padding: 0,
          '&.Mui-checked': {
            transform: 'translateX(24px)',

            '& .MuiSwitch-thumb ': {
              backgroundColor: THEME.COLORS.GREEN_MAIN,
            },
          },
        },

        thumb: {
          margin: '2px',
          width: '24px',
          height: '24px',
          color: THEME.COLORS.BLACK_MAIN,
          boxShadow: 'none',
        },

        input: {
          with: '100%',
        },

        '&.Mui-checked': {
          transform: 'translateX(24px)',
        },
      },
    },
  },

  typography: {
    fontFamily: ['Aldrich', 'sans-serif'].join(','),
    fontWeightBold: 600,
    fontWeightMedium: 500,
    fontWeightRegular: 400,

    h1: {
      fontSize: '24px',
    },
    h2: {
      fontSize: '20px',
      lineHeight: 'normal',
    },
    body1: {
      fontSize: '20px',
    },
    body2: {
      fontSize: '20px',
    },
    h3: {
      fontSize: '16px',
    },
    subtitle1: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '16px',
    },
    subtitle2: {
      fontSize: '14px',
      fontWeight: 400,
    },
  },
});

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    outlinedError: true;
    outlinedSuccess: true;
    outlinedInfo: true;
  }
}
