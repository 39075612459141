import { CreateStyled } from '@emotion/styled';
import { v4 as uuidv4 } from 'uuid';

import { LOCAL_STORAGE_KEYS, REFRESH_TOKEN_KEY } from '../constants';

export const loadScript = (scriptUrl: string) => {
  const script = document.createElement('script');
  script.src = scriptUrl;
  document.body.appendChild(script);

  return new Promise((res, rej) => {
    script.onload = function (e) {
      res(e);
    };

    script.onerror = function () {
      rej();
    };
  });
};

export const transientOptions: Parameters<CreateStyled>[1] = {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
};

export const numberWithDots = (x: number) => {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const getUserId = () => {
  let userId = localStorage.getItem(LOCAL_STORAGE_KEYS.USER_UUID);

  if (!userId) {
    const uuid = uuidv4();
    userId = uuid;
    localStorage.setItem(LOCAL_STORAGE_KEYS.USER_UUID, uuid);
  }

  return userId;
};

export const generateIdempotencyKey = () => {
  const uniqueId = Math.random()?.toString(36).substring(7);
  const timestamp = Date.now();
  return { idempotencyKey: `${uniqueId}-${timestamp}` };
};

export const roundToTwo = (num: number) => {
  return num.toFixed(2);
};

export const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN_KEY);
};

export const setRefreshToken = (token: string) => {
  localStorage.setItem(REFRESH_TOKEN_KEY, token);
};
