import { Colors } from '../../../data-models';
import { THEME } from '../../../constants';

type DiamondOutlineIconType = {
  color?: Colors;
};

export const DiamondOutlineIcon = ({
  color = THEME.COLORS.WHITE,
}: DiamondOutlineIconType) => {
  return (
    <svg
      width='25'
      height='25'
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.75005 9.1842H22.2501M2.16037 9.75655L11.8986 22.3875C11.9694 22.4797 12.0605 22.5545 12.1648 22.6059C12.269 22.6574 12.3838 22.6841 12.5001 22.6841C12.6163 22.6841 12.7311 22.6574 12.8353 22.6059C12.9396 22.5545 13.0307 22.4797 13.1015 22.3875L22.8397 9.75655C22.9355 9.63188 22.991 9.48103 22.999 9.32404C23.0069 9.16704 22.9669 9.01135 22.8843 8.87764L19.581 3.54561C19.513 3.43527 19.4179 3.34414 19.3048 3.28088C19.1916 3.21763 19.0642 3.18435 18.9346 3.1842H6.06552C5.9359 3.18435 5.80848 3.21763 5.69534 3.28088C5.58221 3.34414 5.48712 3.43527 5.41912 3.54561L2.11583 8.87764C2.03317 9.01135 1.99316 9.16704 2.00111 9.32404C2.00907 9.48103 2.06461 9.63188 2.16037 9.75655Z'
        stroke={color}
        strokeWidth='1.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.25 3.9342L17 9.1842M17 9.1842L12.5 3.1842L8 9.1842M17 9.1842L12.5 21.9342L8 9.1842M5.75 3.9342L8 9.1842'
        stroke={color}
        strokeWidth='1.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
