import { Modal, styled } from '@mui/material';
import { DEFAULT_SCROLLBAR_STYLE } from '../../../constants';

// Styled component for the centered modal
export const CenteredModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

// Styled component for the modal content
export const ModalContent = styled('div')({
  backgroundColor: '#EAEBE5',
  border: '1px solid white',
  borderRadius: '46px',
  textAlign: 'center',
  width: '100%',
  maxWidth: '360px',
  position: 'relative',
});

export const ModalHeaderContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px 16px 26px 26px',
});

export const ModalHeaderText = styled('p')({
  fontSize: '24px',
  fontWeight: 400,
  fontFamily: 'Aldrich',
  color: '#0C090C',
  margin: '-24px 0',
  textTransform: 'uppercase',
});

export const ModalCloseButton = styled('button')({
  height: '52px',
  width: '52px',
  borderRadius: '50%',
  border: '1px solid rgba(12, 9, 12, 0.2)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  zIndex: 10,
});

export const ScrollWrapper = styled('div')({
  overflow: 'auto',
  maxHeight: 'calc(100vh - 200px)',
  paddingRight: '10px',
  ...DEFAULT_SCROLLBAR_STYLE,
});

export const FaqText = styled('div')({
  height: '100%',
  fontSize: '14px',
});

export const ModalContentWrapper = styled('div')({
  background: '#0C090C',
  borderRadius: '46px',
  padding: '26px 26px 11px',
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});
