export const VoiceCircleIcon = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.50004 5.26754C8.50004 4.99139 8.27618 4.76754 8.00004 4.76754C7.7239 4.76754 7.50004 4.99139 7.50004 5.26754V10.6009C7.50004 10.877 7.7239 11.1009 8.00004 11.1009C8.27618 11.1009 8.50004 10.877 8.50004 10.6009V5.26754Z'
        fill='white'
      />
      <path
        d='M6.50004 5.9342C6.50004 5.65806 6.27618 5.4342 6.00004 5.4342C5.7239 5.4342 5.50004 5.65806 5.50004 5.9342V9.9342C5.50004 10.2103 5.7239 10.4342 6.00004 10.4342C6.27618 10.4342 6.50004 10.2103 6.50004 9.9342V5.9342Z'
        fill='white'
      />
      <path
        d='M10.5 5.9342C10.5 5.65806 10.2762 5.4342 10 5.4342C9.7239 5.4342 9.50004 5.65806 9.50004 5.9342V9.9342C9.50004 10.2103 9.7239 10.4342 10 10.4342C10.2762 10.4342 10.5 10.2103 10.5 9.9342V5.9342Z'
        fill='white'
      />
      <path
        d='M4.50004 7.26754C4.50004 6.99139 4.27618 6.76754 4.00004 6.76754C3.7239 6.76754 3.50004 6.99139 3.50004 7.26754V8.60087C3.50004 8.87701 3.7239 9.10087 4.00004 9.10087C4.27618 9.10087 4.50004 8.87701 4.50004 8.60087V7.26754Z'
        fill='white'
      />
      <path
        d='M12.5 7.26754C12.5 6.99139 12.2762 6.76754 12 6.76754C11.7239 6.76754 11.5 6.99139 11.5 7.26754V8.60087C11.5 8.87701 11.7239 9.10087 12 9.10087C12.2762 9.10087 12.5 8.87701 12.5 8.60087V7.26754Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.00004 0.767536C4.042 0.767536 0.833374 3.97616 0.833374 7.9342C0.833374 11.8922 4.042 15.1009 8.00004 15.1009C11.9581 15.1009 15.1667 11.8922 15.1667 7.9342C15.1667 3.97616 11.9581 0.767536 8.00004 0.767536ZM1.83337 7.9342C1.83337 4.52845 4.59428 1.76754 8.00004 1.76754C11.4058 1.76754 14.1667 4.52845 14.1667 7.9342C14.1667 11.34 11.4058 14.1009 8.00004 14.1009C4.59428 14.1009 1.83337 11.34 1.83337 7.9342Z'
        fill='white'
      />
    </svg>
  );
};
