import { Box, Button, Container, alpha, styled } from '@mui/material';

export const LayoutContainer = styled(Container)`
  position: relative;
  height: 100%;
  padding-inline: 0;
`;

export const LayoutWrapper = styled(Box)`
  height: 100%;
  overflow-x: auto;
  padding-bottom: 82px;
  padding-inline: 16px;
`;

export const BackButton = styled(Button)`
  min-width: 52px;
  max-width: 52px;
  background-color: ${({ theme }) => alpha(theme.palette.white.main, 0.07)};
  padding: 0;

  &::before {
    border-color: ${({ theme }) => alpha(theme.palette.white.main, 0.2)};
  }
`;

export const HeaderContainer = styled(Box)`
  display: flex;
  margin-top: 16px;
  gap: 12px;
`;

export const TitleContainer = styled(Box)`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
`;
