import {
  alpha,
  ExtendButtonBase,
  styled,
  Tab,
  Tabs,
  TabTypeMap,
} from '@mui/material';
import { transientOptions } from '../../utils';

export const StyledTabs = styled(Tabs, transientOptions)`
  border-radius: 1000px;
  background: ${({ theme }) => alpha(theme.palette.darkGrey.light, 0.7)};
  box-shadow: 0 6px 22px 0
    ${({ theme }) => alpha(theme.palette.black.dark, 0.25)};
  backdrop-filter: blur(2px);
  height: 68px;
  margin-bottom: 8px;
  display: flex;
  align-self: center;
  align-items: center;
  width: calc(100% - 32px * 2);
  padding: 0 16px;

  .MuiTabs-indicator {
    display: none;
  }

  .MuiTabs-flexContainer {
    justify-content: space-between;
  }

  .Mui-selected {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const StyledTab = styled(Tab)`
  background-color: ${({ theme }) => theme.palette.black.main};
  border-radius: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  width: 56px;
  min-width: 56px;
  padding: 0;
` as ExtendButtonBase<TabTypeMap<object, 'div'>>;
