import { styled, Box, alpha } from '@mui/material';
import { THEME } from '../../../../constants';

export const CardMarkerContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 36px;
  height: 32px;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  border-radius: ${THEME.BORDER_RADIUS.PILL};
  border: 1px solid ${({ theme }) => theme.palette.white.main};
  background: ${alpha(THEME.COLORS.WHITE, 0.8)};
`;
