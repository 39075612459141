import { Box, Button, Modal, Typography, styled } from '@mui/material';

import { THEME } from '../../constants';
import { transientOptions } from '../../utils';

export const MyBotEditPageContainer = styled(Box)`
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

export const ButtonContainer = styled(Box)`
  display: flex;
  gap: 26px;
  margin-inline: auto;
`;

type ButtonContainerType = {
  $minHeight: number;
};

export const EditButton = styled(Button, transientOptions)<ButtonContainerType>`
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  min-width: ${({ $minHeight }) => `${$minHeight}px`};
`;

export const EditButtonText = styled(Typography)`
  font-size: 16px;
  margin-left: 20px;
`;

export const EditButtonIconWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: ${THEME.BORDER_RADIUS.PILL};
  background-color: ${({ theme }) => theme.palette.black.main};
`;

export const ControlButtonsWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '60px',
  marginTop: '32px',
});

export const ControlButton = styled(Button)({
  borderRadius: THEME.BORDER_RADIUS.PILL,
  border: '1px solid',
  width: '100%',
});

// Styled component for the centered modal
export const CenteredModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WhiteBorderWrapper = styled('div')({
  width: 'inherit',
  height: '100%',
  position: 'absolute',
  border: '1px solid white',
  borderRadius: '46px',
});

// Styled component for the modal content
export const ModalContent = styled('div')({
  backgroundColor: '#EAEBE5',
  boreder: '1px solid white',
  borderRadius: '46px',
  textAlign: 'center',
  width: '100%',
  maxWidth: '343px',
  position: 'relative',
});

export const ModalHeaderContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px 16px 26px 26px',
});

export const ModalHeaderText = styled('p')({
  fontSize: '24px',
  fontWeight: 400,
  fontFamily: 'Aldrich',
  color: '#0C090C',
  margin: '-24px 0',
  textTransform: 'uppercase',
});

export const ModalCloseButton = styled('button')({
  height: '52px',
  width: '52px',
  borderRadius: '50%',
  border: '1px solid rgba(12, 9, 12, 0.2)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  zIndex: 10,
});

export const ModalContentWrapper = styled('div')({
  border: '1px solid #292929',
  background: '#0C090C',
  borderRadius: '46px',
  padding: '32px',
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const ModalControlButtonsWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '50px',
  width: '100%',
  gap: '8px',
});

export const ConfirmationTextWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const ConfirmationText = styled('p')({});

export const ModalCancelButton = styled('button')({
  height: '52px',
  width: '132px',
  background: '#0C090C',
  color: 'white',
  borderRadius: '100px',
  fontSize: '16px',
  fontFamily: 'Aldrich',
  fontWeight: 400,
  border: '1px solid white',
  cursor: 'pointer',
  textTransform: 'uppercase',
  zIndex: 10,
});

export const ModalConfirmButton = styled('button')({
  height: '52px',
  width: '132px',
  border: '1px solid #2CE72B',
  background: '#144934',
  color: '#2CE72B',
  borderRadius: '100px',
  fontSize: '16px',
  fontFamily: 'Aldrich',
  fontWeight: 400,
  cursor: 'pointer',
  textTransform: 'uppercase',
  zIndex: 10,
});
