export const FaqIcon = ({ onClick }: { onClick?: () => void }) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      cursor='pointer'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM11.5585 5.66138C10.5866 4.36543 8.55909 4.69444 8.04682 6.23126L7.71151 7.23717C7.58053 7.63013 7.15579 7.8425 6.76283 7.71152C6.36987 7.58053 6.1575 7.15579 6.28849 6.76283L6.62379 5.75691C7.5187 3.0722 11.0606 2.49743 12.7585 4.76138C13.7532 6.08755 13.6838 7.92892 12.5922 9.17648L11.673 10.227C11.078 10.907 10.75 11.7798 10.75 12.6834V13C10.75 13.4142 10.4142 13.75 10 13.75C9.58579 13.75 9.25 13.4142 9.25 13V12.6834C9.25 11.4165 9.70984 10.1927 10.5441 9.23924L11.4633 8.18872C12.0882 7.47458 12.1279 6.42052 11.5585 5.66138ZM10 17C10.5523 17 11 16.5523 11 16C11 15.4477 10.5523 15 10 15C9.44771 15 9 15.4477 9 16C9 16.5523 9.44771 17 10 17Z'
        fill='white'
      />
    </svg>
  );
};
