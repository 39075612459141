import { useBffLobbyQuery, useBffProfileQuery } from '../../redux/api';

export const useRoundResultsData = () => {
  const { data: profile } = useBffProfileQuery(undefined, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });
  const { data: lobbyData } = useBffLobbyQuery(undefined, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });
  const data = lobbyData?.battleResult?.botResults.map((result) => {
    return { ...result, isOwner: profile?.userId === result?.userId };
  });

  return { data };
};
