import {
  Divider,
  FormControlLabel,
  FormGroup,
  Typography,
  styled,
} from '@mui/material';

export const StyledForm = styled('form')`
  width: 100%;
  gap: 14px;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
`;

export const SwitchLabel = styled(FormControlLabel)`
  display: flex;
  justify-content: space-between;
  margin: 0;
`;

export const BlockFormGroup = styled(FormGroup)``;

export const BlockLabel = styled(FormControlLabel)`
  display: flex;
  margin: 0;
  justify-content: space-between;
`;

export const StyledDivider = styled(Divider)`
  background: ${({ theme }) => theme.palette.greyCustom.dark};
  width: 100%;
`;

export const TradingStrategyTitle = styled(Typography)`
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.dimGrey.main};
  margin-block: -8px -10px;
`;
