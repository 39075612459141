import { useState } from 'react';

import { ShopItemData } from '../../../../data-models';
import { GasCard } from './GasCard';
import { CloseIcon, GasIcon } from '../../../../components/icons';
import {
  useBuyItemMutation,
  useLazyBalancesQuery,
} from '../../../../redux/api';
import { generateIdempotencyKey } from '../../../../utils';
import {
  CenteredModal,
  ModalContent,
  ModalHeaderContainer,
  ModalHeaderText,
  ModalCloseButton,
  ModalControlButtonsWrapper,
  ModalCancelButton,
  ModalConfirmButton,
  ModalContentWrapper,
  WhiteBorderWrapper,
} from './GasTab.styled';

interface GasCardModalProps {
  data: ShopItemData;
  position: number;
}

export const GasCardModal = ({ data, position }: GasCardModalProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [balancesTrigger, { data: balances }] = useLazyBalancesQuery({
    pollingInterval: 10000,
  });
  const [open, setOpen] = useState<boolean>(false);
  const [buyItemMutation] = useBuyItemMutation();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleBuyGas = () => {
    const { idempotencyKey } = generateIdempotencyKey();
    try {
      buyItemMutation({ shopItemId: data.id, idempotencyKey }).then(() =>
        balancesTrigger(),
      );
      handleClose();
    } catch (error) {
      handleClose();
    }
  };

  return (
    <div>
      <GasCard onClick={handleOpen} data={data} position={position} />
      <CenteredModal
        open={open}
        onClose={handleClose}
        aria-labelledby='centered-modal-title'
        aria-describedby='centered-modal-description'
      >
        <ModalContent>
          <WhiteBorderWrapper />
          <ModalHeaderContainer>
            <ModalHeaderText>BUYING $PUMPs</ModalHeaderText>
            <ModalCloseButton onClick={handleClose}>
              <CloseIcon />
            </ModalCloseButton>
          </ModalHeaderContainer>
          <ModalContentWrapper>
            <GasCard data={data} position={position} isInModal />
            <ModalControlButtonsWrapper>
              <ModalCancelButton onClick={handleClose}>
                Cancel
              </ModalCancelButton>
              <ModalConfirmButton onClick={handleBuyGas}>
                <span>Pay</span>
                <div style={{ display: 'flex', gap: '2px' }}>
                  <GasIcon size='20px' />
                  <span>{data?.priceTag?.price}</span>
                </div>
              </ModalConfirmButton>
            </ModalControlButtonsWrapper>
          </ModalContentWrapper>
        </ModalContent>
      </CenteredModal>
    </div>
  );
};
