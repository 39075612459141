import React from 'react';

import { BasicModal } from './ModalController';

type ModalLayoutType = {
  children: React.ReactNode;
};

export const ModalLayout = ({ children }: ModalLayoutType) => {
  return (
    <>
      {children}
      <BasicModal />
    </>
  );
};
