import { useTheme } from '@mui/material';

import { ModalLayoutHeader } from '../../Layout';
import { TYPE_MODAL } from '../../../constants';
import {
  CongratulationsType,
  GameModalHeader,
} from '../../../data-models/enums';
import { BotStatisticsInfo } from '../components/BotStatisticsInfo/BotStatisticsInfo';
import {
  ActionButton,
  ActionButtonText,
  PumpText,
  StyledModalLayout,
} from './BuyBotModal.styled';
import { DiamondOutlineIcon } from '../../icons';
import { IconWrapper } from '../../IconWrapper/IconWrapper';
import { useModalContext } from '../../../context';

export const BuyBotModal = () => {
  const theme = useTheme();
  const { modalParameters, handleChangeModalParameters } = useModalContext();

  const botName = modalParameters?.value?.name || '';

  const handleClick = () => {
    handleChangeModalParameters([
      {
        key: 'type',
        value: TYPE_MODAL.CONGRATULATIONS,
      },
      {
        key: 'isOpen',
        value: true,
      },
      {
        key: 'value',
        value: { name: botName, variant: CongratulationsType.buy },
      },
    ]);
  };

  return (
    <StyledModalLayout
      modalType={TYPE_MODAL.BUY_BOT}
      titles={
        <ModalLayoutHeader
          variant={GameModalHeader.sellBuy}
          title={botName}
          subtitle='BUY BOT'
        />
      }
      minHeight='355px'
    >
      <BotStatisticsInfo />

      <ActionButton variant='contained' size='large' onClick={handleClick}>
        <ActionButtonText variant='button' noWrap>
          ${modalParameters?.value?.price}/
          <PumpText component='span'>pump</PumpText>
        </ActionButtonText>

        <IconWrapper
          text='BUY BOT'
          type='withTextRight'
          configText={{
            $bgColor: theme.palette.black.main,
            $color: theme.palette.white.main,
            $height: 54,
            $width: 153,
          }}
          configIcon={{
            $bgColor: theme.palette.white.main,
            $size: 38,
            $iconSize: 24,
          }}
        >
          <DiamondOutlineIcon color={theme.palette.black.main} />
        </IconWrapper>
      </ActionButton>
    </StyledModalLayout>
  );
};
