import { Button, Typography, styled } from '@mui/material';

export const StyledBuyButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  min-height: 48px;
  height: 48px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.palette.black.main};
`;

export const BuyButtonText = styled(Typography)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  line-height: normal;
  font-weight: 600;
  /* position: relative; */

  &:after {
    content: 'pump';
    /* position: absolute; */
    /* right: 0;
    top: 50%; */
    display: block;
    margin-left: -4px;
    transform: rotate(-90deg);
    font-size: 10px;
    line-height: normal;
    font-weight: 400;
  }
`;
