import { UserAuthData, UserData } from '../../../../data-models';

import {
  ProfileInfoContainer,
  StyledAvatar,
  UserFullName,
} from './ProfileInfo.styled';

type ProfileInfoType = {
  data: UserAuthData | UserData;
};

export const ProfileInfo = ({ data }: ProfileInfoType) => {
  if (typeof data === 'string') {
    data = JSON.parse(data as string);
  }
  return (
    <ProfileInfoContainer>
      <StyledAvatar
        src={(data as UserAuthData)?.user?.avatarUrl || ''}
        alt={(data as UserAuthData)?.user?.username}
      />
      <UserFullName variant='h3'>
        @
        {(data as UserAuthData)?.user?.username || (data as UserData)?.username}
      </UserFullName>
    </ProfileInfoContainer>
  );
};
