import { Colors } from '../../data-models';

type AwardThirdIconType = {
  color?: Colors;
};

export const AwardSecondIcon = ({ color = '#C1DEBA' }: AwardThirdIconType) => {
  return (
    <svg
      width='24'
      height='22'
      viewBox='0 0 24 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.8383 0.249955C10.132 0.249687 9.09078 0.249523 8.22326 0.531397C6.47274 1.10017 5.10031 2.47261 4.53153 4.22312L4.52291 4.24997H4.23087C0.525334 4.24997 -1.06647 8.95204 1.87705 11.203L1.8877 11.2111L4.35461 12.9615C4.44402 13.0249 4.53679 13.0833 4.63249 13.1364C4.64341 13.1651 4.65462 13.1937 4.66613 13.2223C4.92162 13.8569 5.42031 14.6184 5.89993 15.1062C6.68156 15.9012 7.49044 16.2537 8.78209 16.8166L8.86793 16.854C9.42429 17.0966 9.98686 17.3166 10.5092 17.4772C10.7566 17.5532 11.0063 17.6195 11.2501 17.6677V20.25H8.00011C7.58589 20.25 7.25011 20.5858 7.25011 21C7.25011 21.4142 7.58589 21.75 8.00011 21.75H16.0001C16.4143 21.75 16.7501 21.4142 16.7501 21C16.7501 20.5858 16.4143 20.25 16.0001 20.25H12.7501V17.6677C12.9939 17.6195 13.2436 17.5532 13.491 17.4772C14.0134 17.3166 14.5759 17.0966 15.1323 16.854L15.2181 16.8166C16.5098 16.2537 17.3187 15.9012 18.1003 15.1062C18.5799 14.6184 19.0786 13.8569 19.3341 13.2223C19.3453 13.1945 19.3562 13.1666 19.3668 13.1387C19.4646 13.0831 19.5595 13.0227 19.6513 12.9575L22.1126 11.2111L22.1233 11.203C25.0668 8.95204 23.475 4.24997 19.7695 4.24997H19.4773L19.4687 4.22312C18.8999 2.47261 17.5275 1.10017 15.777 0.531397C14.9094 0.249523 13.8682 0.249687 12.1619 0.249955H11.8383ZM4.23087 5.74997H4.28317C4.25004 6.33508 4.25007 7.03681 4.2501 7.91602L4.2501 9.41596C4.25002 10.041 4.24996 10.5801 4.27009 11.0623L2.77825 10.0038C0.987768 8.6212 1.96397 5.74997 4.23087 5.74997ZM21.2221 10.0038L19.7301 11.0624C19.7503 10.5803 19.7502 10.0416 19.7501 9.41688L19.7501 7.91596C19.7501 7.03687 19.7502 6.33503 19.717 5.74997H19.7695C22.0364 5.74997 23.0126 8.62119 21.2221 10.0038Z'
        fill={color}
      />
    </svg>
  );
};
