import { styled } from '@mui/material';
import { SnackbarProvider, MaterialDesignContent } from 'notistack';

import { THEME } from '../../constants';

export const StyledSnackbarProvider = styled(SnackbarProvider)``;

export const StyledNotistackComponents = styled(MaterialDesignContent)(
  ({ theme }) => ({
    '&': {
      borderRadius: THEME.BORDER_RADIUS.PILL,
      borderWidth: '1px',
      borderStyle: 'solid',
      width: '100%',
    },
    '&.notistack-MuiContent-error': {
      borderColor: theme.palette.error.main,
      backgroundColor: theme.palette.black.main,
    },
  }),
);
