export enum GameModalHeader {
  game = 'game',
  sellBuy = 'sellBuy',
  testBot = 'testBot',
}

export enum CongratulationsType {
  sell,
  buy,
}
