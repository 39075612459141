import { Box, Button, styled } from '@mui/material';

export const Container = styled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const StyledButton = styled(Button)`
  min-width: 24px;
  height: 24px;
  padding: 0;
`;

export const Value = styled(Box)`
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
`;
