import {
  PumpSwitchLabel,
  PumpSwitchThumb,
  PumpSwitchTrack,
} from './ControllerSwitch.styed';

interface PumpSwitchProps {
  name?: string;
  checked: boolean;
  onChange: (val: boolean) => void;
  isColored: boolean;
  switchLabel?: {
    active?: string;
    inactive?: string;
  };
}

export const PumpSwitch = ({
  checked,
  onChange,
  isColored,
  switchLabel,
}: PumpSwitchProps) => {
  return (
    <PumpSwitchTrack
      ischecked={checked}
      iscolored={isColored}
      onClick={() => onChange(!checked)}
    >
      <PumpSwitchLabel ischecked={checked} iscolored={isColored}>
        {checked ? switchLabel?.active : switchLabel?.inactive}
      </PumpSwitchLabel>
      <PumpSwitchThumb
        ischecked={checked}
        iscolored={isColored}
      ></PumpSwitchThumb>
    </PumpSwitchTrack>
  );
};
