import { Box, Button, alpha, styled } from '@mui/material';

type ModalLayoutContainerType = {
  minHeight: string;
};

export const ModalLayoutContainer = styled(Box)<ModalLayoutContainerType>`
  display: flex;
  flex-direction: column;
  border-radius: 46px;
  background-color: ${({ theme }) => alpha(theme.palette.platinum.main, 0.85)};
  border: 1px solid ${({ theme }) => theme.palette.white.main};
  max-height: 100%;
  min-height: ${({ minHeight }) => minHeight};
  width: 343px;
  max-width: 343px;
`;

export const ModalLayoutHeaderContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  padding-left: 26px;
`;

export const ModalLayoutTitlesContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const ModalLayoutCloseButton = styled(Button)`
  min-width: 52px;
  min-height: 52px;
  width: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => alpha(theme.palette.black.main, 0.07)};
  box-shadow: none;
`;

export const ModalLayoutContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${({ theme }) => theme.palette.black.main};
  border-radius: 46px;
  border-top-right-radius: 32px;
  border-top-left-radius: 32px;
  padding: 16px 26px;
  overflow-y: auto;
  overflow-y: scroll;
`;
