import { WrappedCircularProgress } from '../../../../components';
import { useShopListQuery } from '../../../../redux/api';
import { GasCardModal } from './BuyGasModal';
import { GasTabContainer, StyledMasonry } from './GasTab.styled';

export const GasTab = () => {
  const { data, isLoading } = useShopListQuery(undefined, {
    selectFromResult: (result) => ({
      ...result,
      data: result.data?.GAS.filter((gasItem) => gasItem.isActive),
    }),
  });

  return (
    <GasTabContainer>
      {isLoading && <WrappedCircularProgress />}

      {data && (
        <StyledMasonry columns={2} spacing={1.5}>
          {data.map((shopItem, index) => (
            <GasCardModal data={shopItem} position={index} key={shopItem.id} />
          ))}
        </StyledMasonry>
      )}
    </GasTabContainer>
  );
};
