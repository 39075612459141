/* eslint-disable @typescript-eslint/no-unused-vars */
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { BotData } from '../../data-models/game';
import {
  CreateEditBotValidationType,
  createEditBotValidationSchema,
} from './CreateEditBotForm.validation';

import { ControllerSwitch } from '../ControllerFields/ControllerSwitch/ControllerSwitch';
import {
  BlockFormGroup,
  BlockLabel,
  StyledDivider,
  StyledForm,
  SwitchLabel,
  TradingStrategyTitle,
} from './CreateEditBotForm.styled';
import { ControllerNumber } from '../ControllerFields/ControllerNumber/ControllerNumber';
import { ControllerTextInput } from '../ControllerFields/ControllerTextInput/ControllerTextInput';
import { FieldError } from '../FieldError/FieldError';

type CreateEditBotFormValue = {
  formValue?: BotData;
  onSubmit: (values: CreateEditBotValidationType) => void;
  idForm: string;
};

const SWITCH_STATE_LABELS = {
  active: 'pump',
  inactive: 'dump',
};

export const CreateEditBotForm = ({
  idForm,
  onSubmit,
  formValue,
}: CreateEditBotFormValue) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      strategyTitle: formValue?.name || '',
      firstBlock: formValue?.instructions?.firstBlock || false,
      opponentPumps: formValue?.instructions?.opponentPumps || false,
      opponentDumps: formValue?.instructions?.opponentDumps || false,
      forgiveRate: formValue?.instructions?.forgiveRate || 0,
      friendPumps: formValue?.instructions?.friendPumps || false,
      friendDumps: formValue?.instructions?.friendDumps || false,
    },
    resolver: yupResolver(createEditBotValidationSchema),
  });

  return (
    <StyledForm id={idForm} onSubmit={handleSubmit(onSubmit)}>
      <ControllerTextInput
        control={control}
        name='strategyTitle'
        placeholder='Enter bot name'
      />
      <TradingStrategyTitle>Trading Strategy</TradingStrategyTitle>
      <SwitchLabel
        label='First Trade'
        labelPlacement='start'
        control={
          <ControllerSwitch
            control={control}
            name='firstBlock'
            isPumpDumpSwitch
            isColored
            switchLabel={SWITCH_STATE_LABELS}
          />
        }
      />
      <StyledDivider flexItem orientation='horizontal' />
      <SwitchLabel
        label='Enemy bot pumps'
        labelPlacement='start'
        control={
          <ControllerSwitch
            control={control}
            name='opponentDumps'
            isPumpDumpSwitch
            isColored
            switchLabel={SWITCH_STATE_LABELS}
          />
        }
      />
      <SwitchLabel
        label='Enemy bot dumps'
        labelPlacement='start'
        control={
          <ControllerSwitch
            control={control}
            name='opponentPumps'
            isPumpDumpSwitch
            isColored
            switchLabel={SWITCH_STATE_LABELS}
          />
        }
      />
      <BlockFormGroup>
        <BlockLabel
          label='Forgive rate %'
          labelPlacement='start'
          control={
            <>
              <ControllerNumber control={control} name='forgiveRate' />
            </>
          }
        />

        <FieldError message={errors?.forgiveRate?.message} />
      </BlockFormGroup>

      <StyledDivider flexItem orientation='horizontal' />
      <SwitchLabel
        label='Friend bot pumps'
        labelPlacement='start'
        control={
          <ControllerSwitch
            control={control}
            name='friendPumps'
            isPumpDumpSwitch
            isColored
            switchLabel={SWITCH_STATE_LABELS}
          />
        }
      />
      <SwitchLabel
        label='Friend bot dumps'
        labelPlacement='start'
        control={
          <ControllerSwitch
            control={control}
            name='friendDumps'
            isPumpDumpSwitch
            isColored
            switchLabel={SWITCH_STATE_LABELS}
          />
        }
      />
    </StyledForm>
  );
};
