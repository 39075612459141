import { useCallback, useEffect, useRef } from 'react';
import * as d3 from 'd3';

import { GameBubbleNodeType } from '../../../../data-models/game';

import styles from './GameBubbleChart.module.css';
import { SimulationNodeDatum } from 'd3';

type GameBubbleChartType = {
  width: number;
  height: number;
  isMarkTop?: boolean;
  data?: GameBubbleNodeType[];
};

const ANIMATION_TICK_DURATION = 250;
const MARGIN_BETWEEN_CIRCLES = 0.5;

export const GameBubbleChart = ({
  width,
  height,
  data,
}: GameBubbleChartType) => {
  // useRef hook to access DOM element
  const svgRef = useRef(null);
  // useRef to hold a copy of data to prevent direct mutation
  const dataRef = useRef([]);
  // useRef to hold the D3 simulation instance
  const simulationRef = useRef<any>(null);

  const handleEnterBubblesSimulation = (
    enter: d3.Selection<
      d3.EnterElement,
      GameBubbleNodeType,
      HTMLDivElement | null,
      unknown
    >,
    styles: {
      readonly [key: string]: string;
    },
  ) => {
    // Add bubbles to the simulation
    const node = enter
      .append('div')
      .attr('class', styles.bubble)
      // Conditionally apply CSS classes based on bubble properties
      .classed(
        styles.bubble_first,
        (node: GameBubbleNodeType) => node.position === 1,
      )
      .classed(
        styles.bubble_second,
        (node: GameBubbleNodeType) => node.position === 2,
      )
      .classed(
        styles.bubble_third,
        (node: GameBubbleNodeType) => node.position === 3,
      )
      .classed(
        styles.bubble_owner,
        (node: GameBubbleNodeType) => !!node.isOwner,
      )
      // Set position and size of bubbles
      .style(
        'transform',
        (node: any) =>
          `translate(${node.x - node.r || 0}px, ${node.y - node.r || 0}px)`,
      )
      .style('width', (node: GameBubbleNodeType) => `${node.r * 2}px`)
      .style('height', (node: GameBubbleNodeType) => `${node.r * 2}px`);

    // Additional elements for large, mid, and small bubbles
    const largeNodes = node.filter((node: GameBubbleNodeType) => node.r >= 40);
    largeNodes
      .append('span')
      .attr('class', styles.name)
      .style('width', (node: GameBubbleNodeType) => `${node.r * 2 * 0.7}px`)
      .text((node: GameBubbleNodeType) => node.name);
    largeNodes
      .append('span')
      .attr('class', styles.largeText)
      .style('width', (node: GameBubbleNodeType) => `${node.r * 2 * 0.7}px`)
      .text((node: GameBubbleNodeType) => node.score);

    const midNodes = node.filter(
      (node: GameBubbleNodeType) => node.r < 40 && node.r >= 20,
    );
    midNodes
      .append('span')
      .attr('class', styles.name)
      .style('width', (node: GameBubbleNodeType) => `${node.r * 2 * 0.6}px`)
      .text((node: GameBubbleNodeType) => node.name);
    midNodes
      .append('span')
      .attr('class', styles.midText)
      .style('width', (node: GameBubbleNodeType) => `${node.r * 2 * 0.6}px`)
      .text((node: GameBubbleNodeType) => node.score);

    const smallNodes = node.filter((node: GameBubbleNodeType) => node.r < 20);
    smallNodes
      .append('span')
      .attr('class', styles.smallText)
      .style('width', (node: GameBubbleNodeType) => `${node.r * 2 * 0.6}px`)
      .text((node: GameBubbleNodeType) => node.score);

    return node;
  };

  // Function to handle updating bubbles in D3 simulation
  const handleUpdateBubblesSimulation = (
    update: d3.Selection<
      d3.BaseType,
      GameBubbleNodeType,
      HTMLDivElement | null,
      unknown
    >,
  ) => {
    // Update bubble positions and sizes with animation
    update
      .classed(
        styles.bubble_owner,
        (node: GameBubbleNodeType) => !!node.isOwner,
      )
      .transition()
      .duration(ANIMATION_TICK_DURATION)
      .ease(d3.easeLinear)
      .style(
        'transform',
        (node: any) =>
          `translate(${node.x - node.r || 0}px, ${node.y - node.r || 0}px)`,
      )
      .style('width', (node: GameBubbleNodeType) => `${node.r * 2}px`)
      .style('height', (node: GameBubbleNodeType) => `${node.r * 2}px`)
      .select(`span:last-child`)
      .text((node: GameBubbleNodeType) => node.score);

    return update;
  };

  // Callback function for simulation tick event
  const handleSimulationTick = useCallback(() => {
    if (!dataRef.current) return;

    // Select DOM elements, bind data, and apply D3 join pattern
    simulationRef.current = d3
      .select(svgRef.current)
      .selectAll('div')
      .data(dataRef.current);

    simulationRef.current.join(
      (enter: any) => handleEnterBubblesSimulation(enter, styles),
      (update: any) => handleUpdateBubblesSimulation(update),
      (exit: any) => exit.remove(),
    );
  }, []);

  // Initialize D3 simulation when component mounts or data changes
  useEffect(() => {
    if (!svgRef.current || !data) return;

    // Deep copy data to prevent direct mutation
    dataRef.current = JSON.parse(JSON.stringify(data));

    if (!dataRef.current) return;

    // Function to calculate effective radius
    const effectiveRadius = (node: any) => {
      return node.r + MARGIN_BETWEEN_CIRCLES;
    };

    // Initialize D3 force simulation

    simulationRef.current = d3
      .forceSimulation()
      .nodes(dataRef.current)
      .velocityDecay(0.5)
      .force('x', d3.forceX().strength(0.05))
      .force('y', d3.forceY().strength(0.01))
      .force('center', d3.forceCenter(width / 2, height / 2))
      .force(
        'charge',
        d3.forceManyBody().strength((node: NodeWithAdditionalProps) => {
          if (node?.isOwner) {
            return 0.1;
          }
          return 1;
        }),
      )
      .force(
        'collision',
        d3.forceCollide().radius(effectiveRadius).strength(0.5),
      )
      .force('bounding-box', (alpha) => {
        // Apply bounding box constraints to prevent bubbles from moving outside the chart area
        simulationRef.current?.nodes().forEach((node: any) => {
          const k = alpha * 0.01;

          if (node.position < 4) {
            node.vx -= k * (width / 2);
          }

          if (node.x - node.r + 5 < 0) {
            node.vx -= k * (node.x - width);
          }
          if (node.y - node.r < 0) {
            node.vy -= k * (node.y - height);
          }

          if (node.x + node.r + 5 > width) {
            node.vx -= k * (node.x - width);
          }
          if (node.y + node.r > height) {
            node.vy -= k * (node.y - height);
          }
        });
      })
      .nodes(dataRef.current)
      .on('tick', handleSimulationTick);
  }, [data]);

  return (
    <div
      style={{ position: 'relative', width: width, height: height }}
      ref={svgRef}
    />
  );
};

export type NodeWithAdditionalProps = SimulationNodeDatum & {
  isOwner?: boolean;
};
