import { SideMenuButton, SideMenuContainer } from './SideMenu.styled';
import { AddSquareIcon } from '../../../../components/icons';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../constants';

export const SideMenu = () => {
  const navigate = useNavigate();
  const handleCreateNew = () => {
    navigate(ROUTES.MY_BOTS);
  };

  return (
    <SideMenuContainer>
      <SideMenuButton
        color='darkGrey'
        variant='contained'
        startIcon={<AddSquareIcon />}
        onClick={handleCreateNew}
      >
        Add Bots
      </SideMenuButton>
    </SideMenuContainer>
  );
};
