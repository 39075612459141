import { GameModalHeader } from '../../../../../data-models/enums';
import {
  BuySellSubtitle,
  BuySellTitle,
  GameSubtitle,
  GameTitle,
  TestBotContainer,
  TestBotSpeedMarker,
  TestBotTitle,
} from './ModalLayoutHeader.styled';

type ModalLayoutHeader = {
  variant: GameModalHeader;
  title: string;
  subtitle: string;
};

export const ModalLayoutHeader = ({
  variant,
  title,
  subtitle,
}: ModalLayoutHeader) => {
  switch (variant) {
    case GameModalHeader.game:
      return (
        <>
          <GameTitle variant='h1'>{title}</GameTitle>
          <GameSubtitle variant='subtitle1'>{subtitle}</GameSubtitle>
        </>
      );

    case GameModalHeader.sellBuy:
      return (
        <>
          <BuySellSubtitle variant='subtitle1'>{subtitle}</BuySellSubtitle>
          <BuySellTitle variant='h1' noWrap>
            {title}
          </BuySellTitle>
        </>
      );

    case GameModalHeader.testBot:
      return (
        <TestBotContainer>
          <TestBotTitle variant='h1' noWrap>
            {title}
          </TestBotTitle>
          <TestBotSpeedMarker component='span'>Speed x4</TestBotSpeedMarker>
        </TestBotContainer>
      );

    default:
      return <div />;
  }
};
