import { Box, Typography, styled } from '@mui/material';
import { DEFAULT_SCROLLBAR_STYLE, THEME } from '../../constants';

export const MissionsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-bottom: 20px;
`;

export const MissionsHeader = styled(Box)`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const MissionsTitleContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const MissionsTitle = styled(Typography)`
  text-transform: uppercase;
`;

export const MissionsSubtitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.white.main};
  font-size: 13px;
`;

export const ProgressWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const MissionListWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: auto;
  margin-top: 15px;
  ${DEFAULT_SCROLLBAR_STYLE}
`;

type MissionPropsType = {
  $missionStatus: string;
};

export const MissionCardWrapper = styled(Box)<MissionPropsType>`
  padding: 12px;
  border-radius: 26px;
  background: ${({ $missionStatus }) =>
    $missionStatus === 'ACTIVE' ? THEME.COLORS.GREEN_SUCCESS : 'transparent'};
  border: 1px solid transparent;
  border-color: ${({ $missionStatus }) =>
    $missionStatus === 'COMPLETED'
      ? THEME.COLORS.GREEN_MAIN
      : THEME.COLORS.GREEN_SUCCESS};
  display: flex;
  flex-direction: column;
`;

export const MissionTargetText = styled(Typography)`
  color: ${({ theme }) => theme.palette.white.main};
  font-size: 20px;
  line-height: 20px;
`;

export const MissionRewardsWrapper = styled(Box)`
  color: ${({ theme }) => theme.palette.white.main};
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

export const MissionBtn = styled(Box)<MissionPropsType>`
  color: ${({ theme }) => theme.palette.white.main};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 161px;
  height: 32px;
  border: 1px solid transparent;
  border-radius: ${THEME.BORDER_RADIUS.PILL};
  background: ${({ $missionStatus }) =>
    $missionStatus === 'COMPLETED' ? THEME.COLORS.GREEN_SUCCESS : 'black'};
  border-color: ${({ $missionStatus }) =>
    $missionStatus === 'COMPLETED'
      ? THEME.COLORS.GREEN_MAIN
      : THEME.COLORS.GREEN_SUCCESS};
  cursor: ${({ $missionStatus }) =>
    $missionStatus === 'COMPLETED' ? 'pointer' : 'unset'};
  color: ${({ $missionStatus }) =>
    $missionStatus === 'COMPLETED' ? THEME.COLORS.GREEN_MAIN : '#fff'};
  font-size: ${({ $missionStatus }) =>
    $missionStatus === 'ACTIVE' ? '20px' : '16px'};
`;

export const RewardValueWrapper = styled(Box)`
  color: ${({ theme }) => theme.palette.white.main};
  display: flex;
  gap: 6px;
`;

export const RewardValueText = styled(Typography)`
  color: ${({ theme }) => theme.palette.white.main};
  font-size: 24px;
`;
