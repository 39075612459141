import { createApi } from '@reduxjs/toolkit/query/react';

import { client } from './client';
import { CLIENT_ENDPOINTS } from '../../constants';
import {
  ShopResponse,
  buyItemMutationRequest,
  buyItemMutationResponce,
  sellBotMutationRequest,
} from '../../data-models';

export const marketplaceAPI = createApi({
  reducerPath: 'marketplaceAPI',
  baseQuery: client,
  keepUnusedDataFor: 5,
  endpoints: (builder) => ({
    shopList: builder.query<ShopResponse, void>({
      query: () => ({
        url: CLIENT_ENDPOINTS.MARKETPLACE,
        method: 'GET',
      }),
    }),
    // ! Types
    sellBot: builder.mutation<any, sellBotMutationRequest>({
      query: ({ botId, price }) => ({
        url: CLIENT_ENDPOINTS.SELL_BOT,
        method: 'POST',
        body: { botId, price },
      }),
    }),
    buyItem: builder.mutation<buyItemMutationResponce, buyItemMutationRequest>({
      query: ({ shopItemId, idempotencyKey }) => ({
        url: CLIENT_ENDPOINTS.BUY_ITEM({ shopItemId, idempotencyKey }),
        method: 'POST',
        body: { shopItemId, idempotencyKey },
      }),
    }),
  }),
});

export const {
  useShopListQuery,
  useLazyShopListQuery,
  useSellBotMutation,
  useBuyItemMutation,
} = marketplaceAPI;
