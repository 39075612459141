import { ReactElement } from 'react';

import { Colors } from '../../data-models';
import {
  GameDataValueContainer,
  IconWrapper,
  StyledGameDateValue,
} from './GameDataValue.styled';
import { THEME } from '../../constants';

type GameDataValue = {
  backgroundColor: Colors;

  icon: ReactElement;
  value: string | number;
  iconBackground?: string;
};

export const GameDataValue = ({
  backgroundColor,
  iconBackground = THEME.COLORS.WHITE,
  icon,
  value,
}: GameDataValue) => {
  return (
    <GameDataValueContainer backgroundColor={backgroundColor}>
      <IconWrapper iconBackground={iconBackground}>{icon}</IconWrapper>

      <StyledGameDateValue variant='h3'>{value}</StyledGameDateValue>
    </GameDataValueContainer>
  );
};
