import { alpha, Box, Button, styled, Typography } from '@mui/material';
import { ModalLayout } from '../../Layout';

export const StyledModalLayout = styled(ModalLayout)`
  padding-block: 25px 28px;
`;

export const StyledForm = styled('form')`
  width: 100%;
  gap: 20px;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
`;

export const InputWrapper = styled(Box)`
  position: relative;
`;

export const PumpText = styled(Box)`
  transform: rotate(-90deg);
  margin-left: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.dimGrey.main};
`;

export const InputDecoratedValue = styled(Box)`
  display: flex;
  position: absolute;
  justify-content: center;
  width: 100%;
  font-size: 48px;
  font-weight: 600;
  text-align: center;
  padding-left: 40px;
  overflow: hidden;
`;

export const ControllerTextInputWrapper = styled(Box)`
  input {
    font-size: 48px;
    margin: auto;
    color: transparent;
    font-weight: 600;
    text-align: center;
  }
`;

export const ActionButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  padding: 4px 4px 4px 26px;
`;

export const ActionButtonText = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
`;

export const ActionWrapper = styled(Box)`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 26px;
  color: ${({ theme }) => theme.palette.white.main};
  background: ${({ theme }) => alpha(theme.palette.black.main, 0.79)};
  border-radius: 36px;
  backdrop-filter: blur(3px);
`;

export const ActionTitle = styled(Typography)`
  max-width: 200px;
`;
