export const NumericalStarIcon = () => {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.5 3.60087C7.5 3.32473 7.27614 3.10087 7 3.10087C6.72386 3.10087 6.5 3.32473 6.5 3.60087V6.01315L4.85346 4.3666C4.6582 4.17134 4.34161 4.17134 4.14635 4.3666C3.95109 4.56187 3.95109 4.87845 4.14635 5.07371L5.50684 6.4342H3.66667C3.39052 6.4342 3.16667 6.65806 3.16667 6.9342C3.16667 7.21034 3.39052 7.4342 3.66667 7.4342H5.79289L4.14645 9.08065C3.95118 9.27591 3.95118 9.59249 4.14645 9.78776C4.34171 9.98302 4.65829 9.98302 4.85355 9.78776L6.5 8.14131V10.2675C6.5 10.5437 6.72386 10.7675 7 10.7675C7.27614 10.7675 7.5 10.5437 7.5 10.2675V8.42736L8.8604 9.78776C9.05566 9.98302 9.37224 9.98302 9.5675 9.78776C9.76276 9.59249 9.76276 9.27591 9.5675 9.08065L7.92105 7.4342H10.3333C10.6095 7.4342 10.8333 7.21034 10.8333 6.9342C10.8333 6.65806 10.6095 6.4342 10.3333 6.4342H8.20711L9.5676 5.07371C9.76286 4.87845 9.76286 4.56187 9.5676 4.3666C9.37234 4.17134 9.05575 4.17134 8.86049 4.3666L7.5 5.7271V3.60087Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.97568 0.516688C9.21426 0.434193 8.25452 0.434197 7.03017 0.434203H6.96983C5.74548 0.434197 4.78574 0.434193 4.02432 0.516688C3.24729 0.600874 2.61771 0.775752 2.08016 1.1663C1.75473 1.40274 1.46854 1.68893 1.2321 2.01436C0.841549 2.55191 0.666671 3.1815 0.582485 3.95852C0.49999 4.71994 0.499995 5.67968 0.5 6.90404V6.96437C0.499995 8.18873 0.49999 9.14846 0.582485 9.90989C0.666671 10.6869 0.841549 11.3165 1.2321 11.854C1.46854 12.1795 1.75473 12.4657 2.08016 12.7021C2.61771 13.0927 3.24729 13.2675 4.02432 13.3517C4.78573 13.4342 5.74544 13.4342 6.96977 13.4342H7.03018C8.25451 13.4342 9.21427 13.4342 9.97568 13.3517C10.7527 13.2675 11.3823 13.0927 11.9198 12.7021C12.2453 12.4657 12.5315 12.1795 12.7679 11.854C13.1585 11.3165 13.3333 10.6869 13.4175 9.90989C13.5 9.14848 13.5 8.18876 13.5 6.96443V6.90402C13.5 5.6797 13.5 4.71993 13.4175 3.95852C13.3333 3.1815 13.1585 2.55191 12.7679 2.01436C12.5315 1.68893 12.2453 1.40274 11.9198 1.1663C11.3823 0.775752 10.7527 0.600874 9.97568 0.516688ZM2.66794 1.97532C3.00661 1.72926 3.44009 1.58584 4.13203 1.51087C4.83333 1.43489 5.73895 1.4342 7 1.4342C8.26105 1.4342 9.16667 1.43489 9.86797 1.51087C10.5599 1.58584 10.9934 1.72926 11.3321 1.97532C11.5726 2.15008 11.7841 2.36161 11.9589 2.60214C12.2049 2.94081 12.3484 3.37429 12.4233 4.06623C12.4993 4.76753 12.5 5.67315 12.5 6.9342C12.5 8.19525 12.4993 9.10087 12.4233 9.80217C12.3484 10.4941 12.2049 10.9276 11.9589 11.2663C11.7841 11.5068 11.5726 11.7183 11.3321 11.8931C10.9934 12.1391 10.5599 12.2826 9.86797 12.3575C9.16667 12.4335 8.26105 12.4342 7 12.4342C5.73895 12.4342 4.83333 12.4335 4.13203 12.3575C3.44009 12.2826 3.00661 12.1391 2.66794 11.8931C2.42741 11.7183 2.21588 11.5068 2.04112 11.2663C1.79506 10.9276 1.65163 10.4941 1.57667 9.80217C1.50069 9.10087 1.5 8.19525 1.5 6.9342C1.5 5.67315 1.50069 4.76753 1.57667 4.06623C1.65163 3.37429 1.79506 2.94081 2.04112 2.60214C2.21588 2.36161 2.42741 2.15008 2.66794 1.97532Z'
        fill='white'
      />
    </svg>
  );
};
