import { Box, Button, styled, Typography } from '@mui/material';

import imageUrl from '../../../assets/image/join-to-pump-modal-bg.png';

export const ModalWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 26px 16px 16px;
  margin-bottom: 90px;
  border-radius: 42px;
  width: 324px;
  height: 425px;
  color: ${({ theme }) => theme.palette.black.main};
  background: ${({ theme }) => theme.palette.black.light};
  background-image: url(${imageUrl});
  background-repeat: no-repeat;
  background-position: center;
  border: ${({ theme }) => `1px solid ${theme.palette.white.main}`};
`;

export const ActionWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.black.dark};
`;

export const ActionTitle = styled(Typography)`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  background: ${({ theme }) => theme.palette.white.main};
  line-height: 23.47px;
`;

export const StyledButton = styled(Button)`
  text-transform: uppercase;
  background: ${({ theme }) => theme.palette.bluePurple.main};
`;
