import React from 'react';
import { Provider } from 'react-redux';
import { CssBaseline, ThemeProvider } from '@mui/material';

import { store } from './redux/store';

import { RouterWrapper } from './router/Router';
import { ModalContextProvider } from './context';
import { ModalLayout } from './components/ModalController/ModalsLayout';
import { Snackbar } from './components';

import { muiDarkTheme } from './theme';

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={muiDarkTheme}>
        <ModalContextProvider>
          <ModalLayout>
            <CssBaseline />
            <RouterWrapper />
          </ModalLayout>
        </ModalContextProvider>
        <Snackbar />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
