import { Slide, Stack, Typography } from '@mui/material';

import { useModalContext } from '../../../context';
import { SolarSmileIcon } from '../../icons';

import {
  ActionTitle,
  ActionWrapper,
  ModalWrapper,
  StyledButton,
} from './JoinToPumpModal.styled';

export const JoinToPumpModal = () => {
  const { modalParameters } = useModalContext();

  const isOpen = !!modalParameters?.isOpen;

  return (
    <Slide in={isOpen} direction='up'>
      <ModalWrapper>
        <ActionWrapper>
          <Stack padding={'0 5.5px'}>
            <ActionTitle variant='h1' mb={'20px'} height={'65px'}>
              <span>BUILD YOUR</span> CRYPTO BOT FARM
            </ActionTitle>
            <ActionTitle variant='h1' mb={'22px'} height={'39px'}>
              FIGHT WITH OTHERS
            </ActionTitle>
            <ActionTitle
              variant='h1'
              mb={'39px'}
              maxWidth={'200px'}
              height={'39px'}
            >
              WIN PUMPS!
            </ActionTitle>
          </Stack>

          <StyledButton
            variant='contained'
            onClick={() => {
              modalParameters?.onCallBack();
            }}
            startIcon={<SolarSmileIcon />}
            size='large'
          >
            <Typography variant='h3' mt={'4px'}>
              START PUMPING
            </Typography>
          </StyledButton>
        </ActionWrapper>
      </ModalWrapper>
    </Slide>
  );
};
