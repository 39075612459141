import { Stack, Typography, alpha, styled } from '@mui/material';

type ProfitTextPropsType = {
  isreward?: number;
};

type ScoreTextPropsType = {
  isowner?: number;
};

type TableResultsItemPropsType = {
  isowner?: number;
};

export const RoundResultWrapper = styled(Stack)`
  position: absolute;
  top: 88px;
  left: 0;
  right: 0;
  margin: auto;
  width: 343px;
  height: calc(100% - 136px);
  border-radius: 46px;
  border: ${({ theme }) => `1px solid ${theme.palette.white.main}`};
  background: ${({ theme }) => alpha(theme.palette.dimGrey.dark, 0.85)};
`;

export const TableResultsWrapper = styled(Stack)`
  width: 341px;
  height: 100%;
  border-radius: 46px;
  border: ${({ theme }) => `1px solid ${theme.palette.white.main}`};
  background: ${({ theme }) => theme.palette.black.main};
  padding: 26px 26px 9px 26px;
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const TableResultsItem = styled(Stack)<TableResultsItemPropsType>`
  background: ${({ isowner }) =>
    isowner
      ? `radial-gradient(
    38.75% 38.75% at 41.41% 100%,
    #c5e961 0%,
    rgba(197, 233, 97, 0) 100%
  )`
      : ''};
  border-bottom: ${({ isowner, theme }) =>
    isowner ? `1px solid ${theme.palette.lime.main}` : ''};
  min-height: 40px;
  margin-bottom: 16px;
`;

export const ScoreText = styled(Typography)<ScoreTextPropsType>`
  color: ${({ theme, isowner }) =>
    isowner ? theme.palette.black.dark : theme.palette.white.main};
  background: ${({ theme, isowner }) =>
    isowner ? theme.palette.lime.main : ''};
  border-radius: 7px;
  height: 24px;
  padding: 0 2px;
  text-align: center;
`;

export const NameText = styled(Typography)`
  color: ${({ theme }) => theme.palette.greyCustom.light};
  max-width: 125px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ProfitText = styled(Typography)<ProfitTextPropsType>`
  text-wrap: nowrap;
  color: ${({ theme, isreward }) =>
    isreward
      ? theme.palette.green.main
      : theme.palette.greyCustom.contrastText};
`;
