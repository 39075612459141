import { useEffect, useRef, useState } from 'react';
import { Box, GlobalStyles, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useModalContext } from '../../context';
import { LOCAL_STORAGE_KEYS, ROUTES, TYPE_MODAL } from '../../constants';
import { BubbleChartWrapper, GameInfoContainer } from './HomePage.styled';
import { GameUserBalancesAndAirdrop } from './components/GameUserBalancesAndAirdrop/GameUserBalancesAndAirdrop';
import { GameBubbleChart } from './components/GameBubbleChart/GameBubbleChart';
import { ProgressBar, RoundResult } from '../../components';
import { SideMenu } from './components/SideMenu/SideMenu';
import { useGameBubbleData, useRoundResultsData } from '../../hooks';

import imageURL from '../../assets/image/home-background.svg';
import { PhasesEnum } from '../../data-models';
import { useBffLobbyQuery } from '../../redux/api';

export const HomePage = () => {
  const [isShowJoinToPumModal, setIsShowJoinToPumpModal] = useState(
    !!localStorage.getItem(LOCAL_STORAGE_KEYS.IS_SHOW_JOIN_TO_PUMP_MODAL),
  );
  const theme = useTheme();
  const { handleChangeModalParameters, modalParameters } = useModalContext();
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const [isActiveBattle, setIsActiveBattle] = useState<boolean>(false);
  const navigate = useNavigate();
  const { data: lobbyData } = useBffLobbyQuery(undefined, {
    pollingInterval: chartContainerRef.current || !isActiveBattle ? 5000 : 500,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });

  useEffect(() => {
    setIsActiveBattle(
      lobbyData?.battlePhase?.phase === PhasesEnum.ACTIVE_BATTLE,
    );
  }, [lobbyData]);

  const [gameChartSize, setGameChartSize] = useState<{
    width: number;
    height: number;
  } | null>(null);

  const { data: bubbleData } = useGameBubbleData(
    gameChartSize?.width,
    gameChartSize?.height,
  );

  const { data: resultBattleData } = useRoundResultsData();

  const handleCallBackModal = () => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.IS_SHOW_JOIN_TO_PUMP_MODAL, 'true');
    setIsShowJoinToPumpModal(true);
    navigate(ROUTES.HOME);
    handleChangeModalParameters([
      {
        key: 'type',
        value: TYPE_MODAL.JOIN_TO_PUMP,
      },
      {
        key: 'isOpen',
        value: false,
      },
    ]);
  };

  const openTestModal = () => {
    handleChangeModalParameters([
      {
        key: 'type',
        value: TYPE_MODAL.JOIN_TO_PUMP,
      },
      {
        key: 'isOpen',
        value: true,
      },
      {
        key: 'test_value',
        value: 'test_value',
      },
      {
        key: 'onCallBack',
        value: handleCallBackModal,
      },
    ]);
  };

  useEffect(() => {
    !modalParameters?.isOpen && !isShowJoinToPumModal && openTestModal();
  }, [isShowJoinToPumModal]);

  useEffect(() => {
    if (!chartContainerRef.current) return;

    setGameChartSize({
      height: chartContainerRef.current.offsetHeight,
      width: chartContainerRef.current.offsetWidth,
    });
  }, [chartContainerRef.current]);

  const startTime = lobbyData?.battlePhase?.startTime;
  const endTime = lobbyData?.battlePhase?.endTime;

  return (
    <Box>
      <GlobalStyles
        styles={{
          body: {
            backgroundColor: theme.palette.homeBg.main,
            position: 'relative',
            '&:before': {
              position: 'absolute',
              content: '" "',
              display: 'block',
              width: '100%',
              height: '100%',
              backgroundImage: `url(${imageURL})`,
              backgroundSize: '100%',
              backgroundRepeat: 'no-repeat',
            },
          },
        }}
      />
      <GameUserBalancesAndAirdrop />

      {isActiveBattle ? (
        <BubbleChartWrapper ref={chartContainerRef}>
          {gameChartSize && (
            <GameBubbleChart
              width={gameChartSize.width}
              height={gameChartSize.height}
              data={bubbleData}
            />
          )}
        </BubbleChartWrapper>
      ) : (
        <RoundResult data={resultBattleData} />
      )}
      <GameInfoContainer>
        <ProgressBar
          rtl={!isActiveBattle}
          title={isActiveBattle ? 'round progress' : 'next round starting...'}
          isActiveBattle={isActiveBattle}
          startTime={startTime}
          endTime={endTime}
        />
      </GameInfoContainer>
      {isActiveBattle && <SideMenu />}
    </Box>
  );
};
