import { useMemo } from 'react';
import { BotDataUI } from '../../data-models/game';
import { useBotListQuery, useBffLobbyQuery } from '../../redux/api';

export const useMyBotsInfo = () => {
  const { data: botsData, isLoading: areBotsLoading } = useBotListQuery();
  const { data: lobbyData, isLoading: isLobbyLoading } = useBffLobbyQuery();

  const myBotsData: BotDataUI[] = useMemo(() => {
    if (!botsData?.length) return [];
    const currentLobbyParticipants = lobbyData?.lobbyParticipants;
    return botsData?.map((botData) => {
      let isBotPresentInLobby: boolean;

      if (lobbyData?.participants?.length) {
        isBotPresentInLobby = false;
      } else {
        isBotPresentInLobby = Boolean(
          currentLobbyParticipants?.find(
            (lobbyParticipant) => lobbyParticipant?.botId === botData?.id,
          ),
        );
      }

      return {
        ...botData,
        totalScore: botData?.totalScore || 0,
        isActive: isBotPresentInLobby,
      };
    });
  }, [lobbyData, botsData]);

  return {
    isLoading: areBotsLoading || isLobbyLoading,
    myBotsData,
  };
};
