import { CircularProgress, Stack } from '@mui/material';
import { CircularProgressProps } from '@mui/material/CircularProgress';

export const WrappedCircularProgress = (props: CircularProgressProps) => {
  return (
    <Stack height='100%' justifyContent='center' alignItems='center'>
      <CircularProgress {...props} />
    </Stack>
  );
};
