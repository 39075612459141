/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, Button, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import {
  ProfileLogoColoredIcon,
  TelegramFillIcon,
} from '../../components/icons';
import { ProfileAuthButton } from './components';

import { selectClientProfile } from '../../redux/selectors/authSelector';

import {
  JoinToCommunityTitle,
  ContainerLogo,
  RegisterIconWrapper,
} from './ProfilePage.styled';
import {
  useLazyBffProfileQuery,
  useSignInMutation,
  useSignOutMutation,
} from '../../redux/api/authAPI';
import { useEffect } from 'react';
import { getUserId } from '../../utils';
import TelegramCircleIcon from '../../components/icons/TelegramCicrleIcon';
import { enqueueSnackbar } from 'notistack';

export const ProfilePage = () => {
  const [clientSignIn] = useSignInMutation();
  const [clientSignOut] = useSignOutMutation();
  const [fetchProfile] = useLazyBffProfileQuery();
  // const isFbInitialized = useSelector(selectIsFbInitialized);
  // const fbProfile = useSelector(selectFbProfile);
  const clientProfile = useSelector(selectClientProfile);
  // const dispatch = useAppDispatch();
  const handleClientLogin = async () => {
    const userId = getUserId();
    //@ts-ignore
    const telegramData = await window?.Telegram?.WebApp?.initDataUnsafe;
    const telegramUsername = (await telegramData?.user?.username) || userId;

    try {
      return await clientSignIn({
        username: telegramUsername,
        password: telegramUsername,
      }).unwrap();
    } catch {
      enqueueSnackbar('Error occured while logging in.', {
        variant: 'error',
      });
    }
  };

  const handleClientLogout = () =>
    clientSignOut()
      .unwrap()
      .catch(() => {});

  // const handleFbLogin = async () => {
  //   if (!isFbInitialized) return;

  //   try {
  //     await dispatch(loginWihFacebook()).unwrap();
  //   } catch (e) {
  //     enqueueSnackbar('Something went wrong', {
  //       variant: 'error',
  //     });
  //   }
  // };

  // const handleFbLogout = async () => {
  //   if (!isFbInitialized) return;

  //   try {
  //     await dispatch(logoutFromFacebook()).unwrap();
  //   } catch (e) {
  //     enqueueSnackbar('Something went wrong', {
  //       variant: 'error',
  //     });
  //   }
  // };

  useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <ContainerLogo>
        <ProfileLogoColoredIcon />
      </ContainerLogo>

      <Typography fontSize='32px'>Pump'n'Dump</Typography>

      <Typography variant='h1' mt='8px'>
        Crypto Hooligans Arena
      </Typography>

      {/* <StyledAlert
        icon={
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <DangerTriangleIcon />
          </Box>
        }
        severity='warning'
        variant='outlined'
      >
        Connect your social profile to save the progress
      </StyledAlert> */}

      {/* <ConnectTitle variant='h3' textAlign='center'>
        Connect your social account and get +500 Gas!
      </ConnectTitle> */}

      <ProfileAuthButton
        onLogin={handleClientLogin}
        onLogout={handleClientLogout}
        title={'Sign in as a demo user'}
        authData={clientProfile}
        icon={
          <RegisterIconWrapper>
            <TelegramCircleIcon />
          </RegisterIconWrapper>
        }
      />

      {/* <ProfileAuthButton
        onLogin={handleFbLogin}
        onLogout={handleFbLogout}
        title='Sign in with Facebook'
        authData={fbProfile}
        icon={
          <RegisterIconWrapper>
            <FacebookCircleFillIcon />
          </RegisterIconWrapper>
        }
      />

      <ProfileAuthButton
        title='Sign in with Google'
        icon={
          <RegisterIconWrapper>
            <GoogleFillIcon />
          </RegisterIconWrapper>
        }
      /> */}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <JoinToCommunityTitle variant='h3'>
          Join the community
        </JoinToCommunityTitle>
        <JoinToCommunityTitle variant='h3'>
          and get +500 $PUMPs !
        </JoinToCommunityTitle>
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            flexWrap: 'no-wrap',
            width: '100%',
          }}
        >
          <Button
            startIcon={<TelegramFillIcon />}
            color='white'
            variant='contained'
          >
            PUMP’n’DUMP
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
