import { ReactNode } from 'react';
import {
  StyledIconWrapper,
  StyledIconWrapperType,
  StyledTextWrapper,
  StyledTextWrapperType,
} from './IconWrapper.styled';

type IconWrapperType = {
  children: ReactNode;
  configIcon: StyledIconWrapperType;
  configText?: StyledTextWrapperType;
  text?: string;
  type?: 'iconOnly' | 'withTextRight';
};

export const IconWrapper = ({
  children,
  configIcon,
  configText,
  text,
  type = 'iconOnly',
}: IconWrapperType) => {
  if (type === 'withTextRight' && configText) {
    return (
      <StyledTextWrapper {...configText} noWrap>
        {text}
        <StyledIconWrapper {...configIcon}>{children}</StyledIconWrapper>
      </StyledTextWrapper>
    );
  }

  return <StyledIconWrapper {...configIcon}>{children}</StyledIconWrapper>;
};
