import { createAsyncThunk } from '@reduxjs/toolkit';

import { loadScript } from '../../utils';
import { FB_SKD_URL } from '../../constants';

export const initFacebookThunk = createAsyncThunk('init-facebook', async () => {
  await loadScript(FB_SKD_URL);
  FB.init({
    appId: process.env.REACT_APP_FB_ID,
    xfbml: true,
    version: process.env.REACT_APP_FB_V as string,
  });
});

export const loginWihFacebook = createAsyncThunk('login-facebook', async () => {
  const loginResponse = await new Promise<fb.StatusResponse>((res, rej) => {
    FB.login(
      (result) => {
        if (result.status === 'connected') {
          return res(result);
        }

        return rej(result);
      },
      { scope: 'public_profile' },
    );
  });

  const profileData = await new Promise<any>((res, rej) => {
    FB.api('/me', { fields: ['picture', 'name'] }, (result: any) => {
      if (result?.name && result?.picture?.data.url) {
        return res(result);
      }

      rej();
    });
  });

  return {
    accessToken: loginResponse?.authResponse?.accessToken,
    name: profileData?.name,
    avatarUrl: profileData?.picture?.data.url,
  };
});

export const logoutFromFacebook = createAsyncThunk(
  'logout-facebook',
  async () => {
    return await new Promise<void>((res, rej) => {
      FB.logout((response) => {
        if (response.status === 'unknown') {
          return res();
        }
        rej();
      });
    });
  },
);
