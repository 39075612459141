import { useNavigate } from 'react-router-dom';

import { WrappedCircularProgress } from '../../components';
import { AddCircleIcon } from '../../components/icons';
import { useMyBotsInfo } from '../../hooks';
import {
  MyBotsHeader,
  MyBotsCreateButton,
  MyBotsTitle,
  MyBotsTitleContainer,
  MyBotsList,
  MyBotsContainer,
  JoinBotsText,
  CreateBotText,
  EmptyBotList,
  EmptyBotListText,
  InstructionsList,
} from './MyBotsPage.styled';
import { MyBotsListItem } from './components/MyBotsListItem/MyBotsListItem';
import { ROUTES } from '../../constants';
import { useTheme } from '@mui/material';

export const MyBotsPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { myBotsData, isLoading } = useMyBotsInfo();

  const handleCreateNew = () => {
    navigate(ROUTES.MY_BOTS_CREATE);
  };

  return (
    <MyBotsContainer>
      <MyBotsHeader>
        <MyBotsTitleContainer>
          <MyBotsTitle variant='h1'>BOT FARM</MyBotsTitle>
        </MyBotsTitleContainer>

        <MyBotsCreateButton
          variant='outlined'
          color='lime'
          endIcon={<AddCircleIcon color={theme.palette.lime.main} />}
          size='small'
          onClick={handleCreateNew}
        >
          Create Bot
        </MyBotsCreateButton>
      </MyBotsHeader>

      {isLoading && <WrappedCircularProgress />}

      {!isLoading && !myBotsData?.length ? (
        <EmptyBotList>
          <CreateBotText>
            YOU DON’T HAVE ANY BOTS YET. Create ONE ⬆️
          </CreateBotText>
          <EmptyBotListText>
            Pump’n’Dump is a multiplayer strategy game for Crypto Hooligans.
            Every round your crypto bots engage in 1:1 battles, deciding whether
            to pump or dump crypto. Once the round starts, your bot’s actions
            are fully automated, based on the strategy you’ve set. Success
            depends on your strategy as your bot reacts to opponents in
            real-time.
          </EmptyBotListText>
          <EmptyBotListText>
            Compete, adapt, and see if your bots can outscore the rest in this
            fast-paced crypto challenge!
          </EmptyBotListText>
          <p>HOW TO PLAY:</p>
          <InstructionsList>
            <li>Create a bot and configure its trading strategy. </li>
            <li>
              Join your bots into the lobby, where they will automatically added
              the next round.
            </li>
            <li>
              Ensure you have enough $PUMPs in your balance, as each bot’s
              participation in a round costs 50 $PUMPs.
            </li>
            <li>
              Once the round starts, watch your bots and others as they progress
              in the arena.
            </li>
            <li>
              At the end of the round, view the results, including total scores
              and the amount of $PUMPs won by each bot.
            </li>
          </InstructionsList>
        </EmptyBotList>
      ) : (
        <MyBotsList>
          <JoinBotsText>JOIN BOTS TO THE LOBBY ⬇️</JoinBotsText>
          {myBotsData?.map((bot) => (
            <MyBotsListItem key={bot?.id} botData={bot} />
          ))}
        </MyBotsList>
      )}
    </MyBotsContainer>
  );
};
