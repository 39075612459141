import { PumpCurrencyCoinIcon } from '../../components/icons';
import { MissionData } from '../../data-models';
import { useClaimRewardMutation } from '../../redux/api';
import {
  MissionCardWrapper,
  MissionTargetText,
  MissionRewardsWrapper,
  MissionBtn,
  RewardValueWrapper,
  RewardValueText,
} from './MissionsPage.styled';

interface MissionCardProps {
  mission: MissionData;
}

export const MissionCard = ({ mission }: MissionCardProps) => {
  const [claimReward] = useClaimRewardMutation();

  const handleClaimReward = ({ missionId }: { missionId: number }) => {
    claimReward({ missionId });
  };
  return (
    <MissionCardWrapper $missionStatus={mission?.status}>
      <MissionTargetText>{mission?.missionEntity?.name}</MissionTargetText>
      <MissionRewardsWrapper>
        <MissionBtn
          $missionStatus={mission?.status}
          onClick={() => {
            handleClaimReward({ missionId: mission?.id });
          }}
        >
          {mission?.status === 'ACTIVE' &&
            `${mission?.currentProgress}/${mission?.missionEntity?.maxProgress}`}
          {mission?.status === 'COMPLETED' && 'CLAIM REWARD'}
          {mission?.status === 'CLAIMED' && 'CLAIMED'}
        </MissionBtn>
        <RewardValueWrapper>
          <PumpCurrencyCoinIcon size='32px' />
          <RewardValueText>
            {mission?.missionEntity?.reward?.amount}
          </RewardValueText>
        </RewardValueWrapper>
      </MissionRewardsWrapper>
    </MissionCardWrapper>
  );
};
