import { Box, Button, List, Typography, styled } from '@mui/material';

export const MyBotsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 16px;
  padding-bottom: 20px;
`;

export const MyBotsHeader = styled(Box)`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const MyBotsTitleContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const MyBotsTitle = styled(Typography)`
  text-transform: uppercase;
`;

export const MyBotsSubtitle = styled(Typography)`
  max-width: 120px;
  color: ${({ theme }) => theme.palette.greyCustom.main};
`;

export const MyBotsCreateButton = styled(Button)`
  max-width: 146px;
  margin-top: 32px;
`;

export const JoinBotsText = styled(Box)`
  align-self: flex-end;
  margin-right: 12px;
  font-size: 14px;
  margin-bottom: -10px;
`;

export const CreateBotText = styled(Box)`
  font-size: 14px;
  text-transform: uppercase;
  align-self: center;
  margin-bottom: 16px;

  @media (max-width: 420px) {
    font-size: 12.5px;
  }

  @media (max-width: 380px) {
    font-size: 12px;
  }

  @media (max-width: 365px) {
    font-size: 11.5px;
  }
`;

export const EmptyBotList = styled(Box)`
  text-indent: 20px;
  overflow-y: auto;
  font-size: 14px;
`;

export const EmptyBotListText = styled('p')`
  margin: 0;
`;

export const InstructionsList = styled('ol')`
  margin-top: -16px;
  padding-left: 16px;
  text-indent: 0px;
`;

export const MyBotsList = styled(List)`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 32px;
  overflow-y: scroll;
  padding-inline: 16px;
  margin-inline: -16px;

  ::-webkit-scrollbar {
    display: none;
  }
`;
