import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import {
  HomePage,
  NotFound,
  ProfilePage,
  MyBotsPage,
  MyBotCreatePage,
  MyBotEditPage,
  MarketplacePage,
  MissionsPage,
} from '../pages';

import { ROUTES } from '../constants';
import { BaseLayout } from '../components';

const baseRoutes = [
  {
    path: ROUTES.HOME,
    element: HomePage,
    index: true,
  },
  {
    path: ROUTES.PROFILE,
    element: ProfilePage,
  },
  {
    path: ROUTES.MY_BOTS,
    element: MyBotsPage,
  },
  {
    path: ROUTES.MARKETPLACE,
    element: MarketplacePage,
  },
  {
    path: ROUTES.MISSIONS,
    element: MissionsPage,
  },
];

export const addEditBotRoutes = [
  {
    path: ROUTES.MY_BOTS_CREATE,
    element: () => <MyBotCreatePage />,
  },
  {
    path: ROUTES.MY_BOTS_EDIT,
    element: () => <MyBotEditPage />,
  },
];

export const RouterWrapper = () => {
  return (
    <BrowserRouter>
      <Routes>
        {addEditBotRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={<ProtectedRoute {...route} />}
          />
        ))}

        <Route path={ROUTES.HOME} element={<BaseLayout />}>
          {baseRoutes.map((route) => (
            <Route
              index={route?.index}
              key={route.path}
              path={route.path}
              element={<ProtectedRoute {...route} />}
            />
          ))}
          <Route key={'NotFound'} path='*' element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

type ProtectedRouteType = {
  element: any;
  path: string;
  isAuth?: boolean;
};

const ProtectedRoute = ({
  element: Element,
  path,
  isAuth = true,
}: ProtectedRouteType) => {
  // исключение для страниц не требующих авторизации
  if (authRoutCheck(path)) {
    if (isAuth) return <Navigate to={ROUTES.HOME} />;
    return <Element />;
  }

  if (isAuth) {
    return <Element />;
  }

  return <Navigate to={ROUTES.PROFILE} />;
};

const authRoutCheck = (path: string) => {
  const authRout = ['not in use now'];

  return authRout.includes(path);
};
