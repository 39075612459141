import { Box, Typography, alpha, styled } from '@mui/material';

import { THEME } from '../../constants';

export const GameInfoWrapper = styled(Box)`
  gap: 6px;
  display: flex;
  justify-content: space-between;
  flex: 80px 1 0;
`;

type GameInfoItemContainerType = {
  minWidth?: string;
};

export const GameInfoItemContainer = styled(Box)<GameInfoItemContainerType>`
  width: fit-content;
  padding: 8px 12px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 6px;
  height: 40px;
  border-radius: ${THEME.BORDER_RADIUS.PILL};
  color: ${({ theme }) => theme.palette.white.main};
  background-color: ${({ theme }) => alpha(theme.palette.darkGrey.main, 0.7)};
  min-width: ${({ minWidth = '80px' }) => minWidth};
  flex-grow: 1;

  &::before {
    content: '';
    display: block;
    top: 0;
    right: 0;
    border-radius: ${THEME.BORDER_RADIUS.PILL};
    width: 100%;
    height: 100%;
    position: absolute;
    border: 1px solid ${({ theme }) => alpha(theme.palette.white.main, 0.15)};
  }
`;

type GameInfoItemIconWrapperType = {
  minWidth?: string;
};

export const GameInfoItemIconWrapper = styled(Box)<GameInfoItemIconWrapperType>`
  min-width: ${({ minWidth = '11px' }) => minWidth};
`;

export const GameInfoItemContent = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const GameInfoItemLabel = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
`;

export const GameInfoItemValue = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
`;
