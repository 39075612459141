import { Box, styled } from '@mui/material';

export const BubbleChartWrapper = styled(Box)`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const GameInfoContainer = styled(Box)`
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 312px;
  z-index: ${({ theme }) => theme.zIndex.modal};
`;
