import { styled, Box, ListItem, Typography } from '@mui/material';

import { THEME } from '../../../../../constants';
import { transientOptions } from '../../../../../utils';
import { UiGameBotType } from '../../../../../data-models/enums';

type StyledListItemType = {
  $type?: UiGameBotType;
};

export const StyledListItem = styled(
  ListItem,
  transientOptions,
)<StyledListItemType>`
  justify-content: space-between;
  align-items: center;
  padding: 0;

  padding-bottom: ${({ $type }) =>
    $type === UiGameBotType.owner || $type === UiGameBotType.third
      ? '10px'
      : '0'};

  border-bottom: ${({ $type, theme }) => {
    if ($type === UiGameBotType.third)
      return `1px solid ${theme.palette.darkGrey.main}`;
    if ($type === UiGameBotType.owner)
      return `1px solid ${theme.palette.lime.main}`;

    return 'none';
  }};

  background: ${({ $type }) =>
    $type === UiGameBotType.owner
      ? 'radial-gradient(38.75% 38.75% at 41.41% 100%, #C5E961 0%, rgba(197, 233, 97, 0.00) 100%)'
      : 'none'};
`;

type StyledListItemNumType = {
  $isOwner?: boolean;
};

export const StyledListItemNum = styled(
  Box,
  transientOptions,
)<StyledListItemNumType>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-size: 14px;
  font-style: normal;
  border-radius: ${THEME.BORDER_RADIUS.PILL};

  color: ${({ $isOwner, theme }) =>
    $isOwner ? theme.palette.black.main : theme.palette.white.main};

  background: ${({ $isOwner, theme }) =>
    $isOwner ? theme.palette.lime.main : 'none'};

  border: 2px solid
    ${({ $isOwner, theme }) =>
      $isOwner ? theme.palette.white.main : theme.palette.greyCustom.dark};
`;

type BotNameType = {
  $isOwner?: boolean;
};

export const BotName = styled(Typography, transientOptions)<BotNameType>`
  color: ${({ theme }) => theme.palette.greyCustom.light};
  max-width: 96px;
`;

export const PerformerNameWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const PerformerIconWrapper = styled(Box)`
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const YouBadge = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 41px;
  height: 20px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.darkGrey.main};
  border-radius: ${THEME.BORDER_RADIUS.PILL};
  background-color: ${({ theme }) => theme.palette.lime.main};
`;

export const BotScore = styled(Typography)`
  max-width: 100px;
  font-weight: bold;
`;
