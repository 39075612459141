import { Link } from 'react-router-dom';

import { useRouteMatch } from '../../hooks';

import { ROUTES, THEME } from '../../constants';

import { StyledTab, StyledTabs } from './Navigation.styled';
import {
  DexIcon,
  DiamondOutlineIcon,
  ProfileIcon,
  SettingIcon,
} from '../icons';
import { MissionsIcon } from '../icons/MissionsIcon';

const NAV_LIST = [
  {
    Icon: DexIcon,
    activeColor: THEME.COLORS.BLACK_MAIN,
    inactiveColor: THEME.COLORS.WHITE,
    to: ROUTES.HOME,
  },
  {
    Icon: SettingIcon,
    activeColor: THEME.COLORS.BLACK_MAIN,
    inactiveColor: THEME.COLORS.WHITE,
    to: ROUTES.MY_BOTS,
  },
  {
    Icon: MissionsIcon,
    activeColor: THEME.COLORS.BLACK_MAIN,
    inactiveColor: THEME.COLORS.WHITE,
    to: ROUTES.MISSIONS,
  },
  {
    Icon: DiamondOutlineIcon,
    activeColor: THEME.COLORS.BLACK_MAIN,
    inactiveColor: THEME.COLORS.WHITE,
    to: ROUTES.MARKETPLACE,
  },
  {
    Icon: ProfileIcon,
    activeColor: THEME.COLORS.BLACK_CONTRAST,
    to: ROUTES.PROFILE,
  },
];

export const Navigation = () => {
  const routeMatch = useRouteMatch(Object.values(ROUTES));
  const currentTab = routeMatch?.pattern?.path;

  return (
    <StyledTabs value={currentTab} scrollButtons={false} centered={false}>
      {NAV_LIST.map(({ Icon, to, activeColor, inactiveColor }) => (
        <StyledTab
          icon={
            <Icon color={currentTab === to ? activeColor : inactiveColor} />
          }
          key={to}
          value={to}
          to={to}
          component={Link}
        />
      ))}
    </StyledTabs>
  );
};
