import { Colors } from '../../data-models';
import { THEME } from '../../constants';

type GearIconType = {
  color?: Colors;
};

export const GearIcon = ({ color = THEME.COLORS.WHITE }: GearIconType) => {
  return (
    <svg
      width='22'
      height='24'
      viewBox='0 0 22 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Setting'>
        <g id='Vector'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M11.0001 15.75C13.0711 15.75 14.7501 14.0711 14.7501 12C14.7501 9.92892 13.0711 8.24998 11.0001 8.24998C8.92899 8.24998 7.25006 9.92892 7.25006 12C7.25006 14.0711 8.92899 15.75 11.0001 15.75ZM13.2501 12C13.2501 13.2426 12.2427 14.25 11.0001 14.25C9.75741 14.25 8.75006 13.2426 8.75006 12C8.75006 10.7573 9.75742 9.74998 11.0001 9.74998C12.2427 9.74998 13.2501 10.7573 13.2501 12Z'
            fill={color}
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M7.58534 20.7677C8.56575 24.2031 13.4344 24.2031 14.4148 20.7677C14.7185 19.7033 15.8121 19.0719 16.8858 19.3411C20.3511 20.2097 22.7854 15.9934 20.3005 13.4266C19.5306 12.6313 19.5306 11.3686 20.3005 10.5734C22.7854 8.0066 20.3511 3.79026 16.8858 4.65889C15.8121 4.92802 14.7185 4.29666 14.4148 3.23226C13.4344 -0.20312 8.56575 -0.20312 7.58534 3.23226C7.28157 4.29666 6.18803 4.92802 5.11434 4.65889C1.64901 3.79026 -0.785288 8.0066 1.69963 10.5733C2.46955 11.3686 2.46955 12.6313 1.69963 13.4266C-0.785289 15.9934 1.64901 20.2097 5.11434 19.3411C6.18803 19.0719 7.28157 19.7033 7.58534 20.7677ZM12.9724 20.3561C12.4061 22.3403 9.59403 22.3403 9.02775 20.3561C8.50183 18.5132 6.60854 17.4201 4.74963 17.8861C2.74809 18.3878 1.34206 15.9525 2.77733 14.47C4.11031 13.0931 4.11031 10.9069 2.77733 9.53001C1.34207 8.04748 2.74809 5.61217 4.74963 6.11388C6.60854 6.57983 8.50183 5.48674 9.02775 3.6439C9.59403 1.65967 12.4061 1.65967 12.9724 3.6439C13.4983 5.48675 15.3916 6.57984 17.2505 6.11388C19.252 5.61217 20.658 8.04748 19.2228 9.53001C17.8898 10.9069 17.8898 13.0931 19.2228 14.47C20.658 15.9525 19.252 18.3878 17.2505 17.8861C15.3916 17.4201 13.4983 18.5132 12.9724 20.3561Z'
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};
