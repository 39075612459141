import {
  BotStatisticsInfoContainer,
  BotStatisticsInfoContent,
  BotStatisticsInfoHeader,
  BotStatisticsInfoHeaderScore,
  BotStatisticsInfoItem,
  BotStatisticsInfoLabel,
  BotStatisticsInfoLine,
  BotStatisticsInfoSubValue,
  BotStatisticsInfoValue,
} from './BotStatisticsInfo.styled';

type BotStatisticsInfo = {
  botId?: string;
};

export const BotStatisticsInfo = () => {
  return (
    <BotStatisticsInfoContainer>
      <BotStatisticsInfoHeader>
        2 High
        <BotStatisticsInfoHeaderScore>64.356</BotStatisticsInfoHeaderScore>
      </BotStatisticsInfoHeader>

      <BotStatisticsInfoContent>
        <BotStatisticsInfoItem>
          <BotStatisticsInfoLabel>Live now:</BotStatisticsInfoLabel>

          <BotStatisticsInfoValue>
            188{' '}
            <BotStatisticsInfoSubValue component='span'>
              instances
            </BotStatisticsInfoSubValue>
          </BotStatisticsInfoValue>
        </BotStatisticsInfoItem>

        <BotStatisticsInfoLine />

        <BotStatisticsInfoItem>
          <BotStatisticsInfoLabel>Sold:</BotStatisticsInfoLabel>
          <BotStatisticsInfoValue>264</BotStatisticsInfoValue>
        </BotStatisticsInfoItem>
      </BotStatisticsInfoContent>
    </BotStatisticsInfoContainer>
  );
};
