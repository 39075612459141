import { useTheme } from '@mui/material';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import isNumber from 'lodash/isNumber';

import { ModalLayoutHeader } from '../../Layout';
import { TYPE_MODAL } from '../../../constants';
import {
  CongratulationsType,
  GameModalHeader,
} from '../../../data-models/enums';
import { BotStatisticsInfo } from '../components/BotStatisticsInfo/BotStatisticsInfo';
import {
  ActionButton,
  ActionButtonText,
  ControllerTextInputWrapper,
  InputDecoratedValue,
  InputWrapper,
  PumpText,
  StyledForm,
  StyledModalLayout,
} from './SellBotModal.styled';
import { DiamondOutlineIcon } from '../../icons';
import { IconWrapper } from '../../IconWrapper/IconWrapper';
import { sellBotModalValidationSchema } from './SellBotModal.validation';
import { ControllerTextInput } from '../../ControllerFields/ControllerTextInput/ControllerTextInput';
import { useModalContext } from '../../../context';

export const SellBotModal = () => {
  const theme = useTheme();
  const { modalParameters, handleChangeModalParameters } = useModalContext();
  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(sellBotModalValidationSchema),
  });

  const sellPriceWatch = useWatch({ control, name: 'sellPrice' });
  const botName = modalParameters?.value?.name;

  const onSubmit = () => {
    handleChangeModalParameters([
      {
        key: 'type',
        value: TYPE_MODAL.CONGRATULATIONS,
      },
      {
        key: 'isOpen',
        value: true,
      },
      {
        key: 'value',
        value: { name: botName, variant: CongratulationsType.sell },
      },
    ]);
  };

  const handleChangePrice = (value: string) => {
    if (isNumber(Number(value))) setValue('sellPrice', Number(value));
  };

  return (
    <StyledModalLayout
      modalType={TYPE_MODAL.SELL_BOT}
      titles={
        <ModalLayoutHeader
          variant={GameModalHeader.sellBuy}
          title={botName}
          subtitle='SELL BOT'
        />
      }
      minHeight='445px'
    >
      <BotStatisticsInfo />

      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <InputWrapper>
          <InputDecoratedValue>
            ${sellPriceWatch || '0'}/<PumpText component='span'>pump</PumpText>
          </InputDecoratedValue>

          <ControllerTextInputWrapper>
            <ControllerTextInput
              control={control}
              name='sellPrice'
              type='number'
              onChangeCustom={handleChangePrice}
            />
          </ControllerTextInputWrapper>
        </InputWrapper>

        <ActionButton variant='contained' size='large' type='submit'>
          <ActionButtonText variant='button'>Sell bot</ActionButtonText>
          <IconWrapper
            configIcon={{
              $bgColor: theme.palette.black.main,
              $size: 54,
              $iconSize: 24,
            }}
          >
            <DiamondOutlineIcon color={theme.palette.white.main} />
          </IconWrapper>
        </ActionButton>
      </StyledForm>
    </StyledModalLayout>
  );
};
