export const CloseIcon = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Close'>
        <path
          id='Vector'
          d='M0.469644 14.4697C0.17675 14.7626 0.17675 15.2374 0.469644 15.5303C0.762536 15.8232 1.23741 15.8232 1.5303 15.5303L7.99999 9.06064L14.4697 15.5303C14.7626 15.8232 15.2374 15.8232 15.5303 15.5303C15.8232 15.2374 15.8232 14.7626 15.5303 14.4697L9.06065 7.99998L15.5303 1.53033C15.8232 1.23744 15.8232 0.762563 15.5303 0.46967C15.2374 0.176777 14.7625 0.176777 14.4696 0.46967L7.99999 6.93932L1.53033 0.469666C1.23744 0.176773 0.762564 0.176773 0.469672 0.469666C0.176778 0.762559 0.176778 1.23743 0.469672 1.53033L6.93933 7.99998L0.469644 14.4697Z'
          fill='#0C090C'
        />
      </g>
    </svg>
  );
};
