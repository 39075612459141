import { ReactNode } from 'react';
import { BuyButtonText, StyledBuyButton } from './BuyButton.styled';

type BuyButtonType = {
  onClick: () => void;
  className?: string;
  price: string | number;
  icon?: ReactNode;
};

export const BuyButton = ({
  price,
  icon,
  className,
  onClick,
}: BuyButtonType) => {
  return (
    <StyledBuyButton
      className={className}
      variant='contained'
      color='darkGrey'
      onClick={onClick}
    >
      <BuyButtonText variant='button'>${price}/</BuyButtonText>
      {icon}
    </StyledBuyButton>
  );
};
