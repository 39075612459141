import { Box, BoxProps, Typography, alpha, styled } from '@mui/material';

import { Colors } from '../../data-models';
import { THEME } from '../../constants';

type GameDataValueContainerType = {
  backgroundColor: Colors;
};

interface GasTabCardContainerProps extends BoxProps {
  iconBackground?: string;
}

interface GameInfoWrapperProps extends BoxProps {
  isTimerOver?: boolean;
}

export const GameDataValueContainer = styled(Box)<GameDataValueContainerType>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  box-shadow: 0 22px 14px -16px ${({ theme }) => alpha(theme.palette.black.dark, 0.25)};
  border-radius: ${THEME.BORDER_RADIUS.PILL};
  padding: 12px;
  min-width: 98px;

  &:before {
    content: '';
    top: 0;
    right: 0;
    border-radius: ${THEME.BORDER_RADIUS.PILL};
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    border: 1px solid ${({ theme }) => alpha(theme.palette.black.main, 0.25)};
  }
`;

export const IconWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'iconBackground',
})<GasTabCardContainerProps>(() => ({
  height: '32px',
}));

export const StyledGameDateValue = styled(Typography)`
  font-weight: bold;
  flex-grow: 1;
`;

export const BalanceWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'iconBackground',
})<GasTabCardContainerProps>(() => ({
  border: '1px solid #3A3834',
  borderRadius: THEME.BORDER_RADIUS.PILL,
  height: '56px',
  width: '100%',
  background:
    'linear-gradient(0deg, rgba(12,9,12,0.6474964985994398) 19%, rgba(0,0,0,1) 77%)',
  display: 'flex',
  alignItems: 'center',
  paddingInline: '12px',
}));

export const GameInfoWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isTimerOver',
})<GameInfoWrapperProps>(({ theme, isTimerOver }) => ({
  border: '1px solid #3A3834',
  borderRadius: THEME.BORDER_RADIUS.PILL,
  height: '56px',
  width: '100%',
  background: isTimerOver
    ? '#F21A43'
    : 'linear-gradient(0deg, rgba(12,9,12,0.6474964985994398) 25%, rgba(0,0,0,1) 65%)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingInline: '12px',
  color: theme.palette.white.main,
  fontSize: '16px',
  gap: '8px',
  position: 'relative',
}));

export const ValueWrapper = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
}));

export const ProfitValue = styled(
  Box,
  {},
)(() => ({
  position: 'absolute',
  right: '16px',
  top: '-1px',
  color: '#2CB94B',
}));
