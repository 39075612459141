import { THEME } from '../../constants';
import { Colors } from '../../data-models';

type MissionsIconType = {
  color?: Colors;
};

export function MissionsIcon({ color = THEME.COLORS.WHITE }: MissionsIconType) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='37'
      height='35'
      fill='none'
      viewBox='0 0 37 35'
    >
      <path
        stroke={color}
        strokeLinecap='round'
        strokeWidth='1.4'
        d='M18 22h9M9 21s2 5 2.5 4 2.5-4.5 4.5-8M18 13h9M9 12s2 5 2.5 4S14 11.5 16 8'
      ></path>
    </svg>
  );
}
