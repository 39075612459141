import { UiGameBotType } from '../../../../../data-models/enums';
import { LeaderboardItemData } from '../../../../../data-models/game';
import { numberWithDots } from '../../../../../utils';
import {
  AwardFirstIcon,
  AwardSecondIcon,
  AwardThirdIcon,
} from '../../../../icons';
import {
  BotName,
  StyledListItem,
  BotScore,
  StyledListItemNum,
  PerformerNameWrapper,
  PerformerIconWrapper,
  YouBadge,
} from './TopPerformer.styled';

type TopPerformerType = {
  listItemNum: number;
  data: LeaderboardItemData;
};

const getPerformerIcon = (listItemNum: number) => {
  switch (listItemNum) {
    case 1:
      return <AwardFirstIcon />;
    case 2:
      return <AwardSecondIcon />;
    case 3:
      return <AwardThirdIcon />;
    default:
      return null;
  }
};

export const TopPerformer = ({ data, listItemNum }: TopPerformerType) => {
  const performerIcon = getPerformerIcon(listItemNum);

  const borderType: UiGameBotType =
    listItemNum === 3
      ? UiGameBotType.third
      : data.isOwner
      ? UiGameBotType.owner
      : UiGameBotType.another;

  return (
    <StyledListItem $type={borderType}>
      <PerformerNameWrapper>
        <StyledListItemNum $isOwner={data.isOwner}>
          {listItemNum}
        </StyledListItemNum>
        {performerIcon && (
          <PerformerIconWrapper>{performerIcon}</PerformerIconWrapper>
        )}
        <BotName $isOwner={data.isOwner} noWrap>
          {data.strategyName}
        </BotName>

        {data.isOwner && <YouBadge>You</YouBadge>}
      </PerformerNameWrapper>

      <BotScore>{numberWithDots(data.score)}</BotScore>
    </StyledListItem>
  );
};
