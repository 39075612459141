function AirdropIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='32'
      fill='none'
      viewBox='0 0 32 32'
    >
      <rect width='32' height='32' fill='#fff' rx='16'></rect>
      <path
        fill='#F21A43'
        d='M22.736 15.736a6.736 6.736 0 00-13.472 0 .396.396 0 00.15.31l.009.007 6.18 4.636v1.518h-.66a.396.396 0 000 .793h2.114a.396.396 0 100-.793h-.66V20.69l6.18-4.636a.395.395 0 00.159-.317zm-.806-.396h-2.899c-.086-2.835-1.09-4.532-1.877-5.435a5.956 5.956 0 014.776 5.435zM16 9.887c.396.333.74.724 1.018 1.16.914 1.392 1.177 3.038 1.22 4.293h-4.476c.07-2.106.693-3.491 1.22-4.293A5.064 5.064 0 0116 9.887zm1.968 6.245L16 19.675l-1.968-3.543h3.936zm-4.843 0l1.626 2.927-3.902-2.927h2.276zm5.75 0h2.276l-3.902 2.927 1.626-2.927zm-4.029-6.227c-.787.903-1.791 2.6-1.877 5.435h-2.9a5.957 5.957 0 014.777-5.435z'
      ></path>
    </svg>
  );
}

export default AirdropIcon;
