import { THEME } from '../../constants';
import { Colors } from '../../data-models';

type DexIconType = {
  color?: Colors;
};

export function DexIcon({ color = THEME.COLORS.WHITE }: DexIconType) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='39'
      height='17'
      fill='none'
      viewBox='0 0 39 17'
    >
      <path
        fill={color}
        d='M9.187 14.598c1.117 0 1.976-.301 2.578-.903.601-.601.902-1.46.902-2.578V6.078c0-.547-.078-1.035-.234-1.465a2.861 2.861 0 00-.692-1.09 2.862 2.862 0 00-1.09-.691 4.269 4.269 0 00-1.464-.234h-6.14v12h6.14zM.644 17V.195h8.543c.898 0 1.707.14 2.426.422a5.06 5.06 0 011.851 1.184 5.06 5.06 0 011.184 1.851c.28.727.421 1.535.421 2.426v5.04c0 .89-.14 1.698-.421 2.425a5.175 5.175 0 01-1.184 1.863 5.269 5.269 0 01-1.851 1.184c-.72.273-1.528.41-2.426.41H.644zM14.125.195h12.246v2.403h-9.844v4.804h8.403v2.403h-8.403v4.793h9.844V17H14.125V.195zm12.626 0l4.488 6.293L35.751.195h2.906l-5.953 8.332L38.786 17H35.88l-4.64-6.457L26.633 17h-2.907l6.082-8.473L23.856.195h2.895z'
      ></path>
    </svg>
  );
}
