import { Typography, styled } from '@mui/material';
import { Box, alpha } from '@mui/system';

import congratulationsSvg from '../../../assets/image/congratulations_bg.svg';

export const CongratulationsModalContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 26px;
  padding: 16px 16px 26px 16px;
  border: 1px solid ${({ theme }) => theme.palette.white.main};
  background: ${({ theme }) => alpha(theme.palette.white.main, 0.63)};
  backdrop-filter: blur(2px);
  min-height: 371px;
  width: 237px;
  border-radius: 42px;
  position: relative;
  margin-top: 120px;
  margin-bottom: auto;
`;

export const IconContainer = styled(Box)`
  position: absolute;
  width: 52px;
  top: -26px;
  left: 0;
  right: 0;
  margin-inline: auto;
`;

export const CongratulationsModalBg = styled(Box)`
  width: 205px;
  height: 221px;
  background-image: url(${congratulationsSvg});
  background-repeat: no-repeat;
  background-size: cover;
`;

export const CongratulationsModalContent = styled(Box)`
  display: flex;
  gap: 16px;
  align-items: center;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.black.main};
`;

export const YouBotText = styled(Typography)`
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
`;

export const YouBuyBotText = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  line-height: 24px;
  text-transform: uppercase;
`;

export const BotName = styled(Typography)`
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;
  text-transform: uppercase;
  text-align: center;
`;

export const GreenText = styled(Typography)`
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.palette.green.main};
  text-transform: uppercase;
`;
