export function GasIcon({ size = '33px' }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 33 33'
    >
      <g filter='url(#filter0_b_785_2482)'>
        <g clipPath='url(#clip0_785_2482)'>
          <rect
            width='32'
            height='32'
            x='0.25'
            y='0.467'
            fill='#0C090C'
            rx='16'
          ></rect>
          <path
            fill='#19473E'
            d='M16.474-4.444c.411-.039.818-.013 1.23-.018.72-.003 1.44.005 2.16-.005.15 0 .308-.003.455.027.114.058.086.222.1.326-.008.689-.003 1.378-.01 2.067 0 .17.016.339.033.508.168-.093.327-.167.53-.156.503.017 1.01-.021 1.511.025.028.364.008.726.01 1.09 0 .742.002 1.485 0 2.228.002.312-.03.614.035.924.384.073.754.19 1.115.33.278.114.526.28.807.39.43.169.766.43 1.139.685.202.138.382.297.553.468.237.239.504.433.725.685a8.77 8.77 0 011.278 2.016c.183.45.338.924.389 1.403.074.417.174.823.164 1.25.004 1.449.004 2.898.003 4.347 0 .31.04.598-.07.899l-.112-.021c-.53-.144-1.076-.206-1.606-.346.05-.506.031-1.002.036-1.51-.003-.815.01-1.63-.007-2.445-.005-.412-.12-.792-.208-1.191a5.397 5.397 0 00-.274-.83c-.163-.388-.48-.693-.75-1.031-.481-.595-1.093-1.047-1.751-1.46-.208-.121-.455-.204-.684-.289a6.821 6.821 0 00-2.536-.451c-1.303.008-2.607-.001-3.912.008-.11-.012-.288.015-.348-.093-.052-.243-.023-.504-.026-.75.002-2.662 0-5.325.001-7.988.003-.365-.02-.728.02-1.092z'
          ></path>
          <path
            fill='#A63325'
            d='M10.526-2.98c.445-.034.895-.007 1.341-.014.49.007.981-.021 1.47.013.024.275.022.55.018.824V7.951c.002.35-.013.7.01 1.051.377.021.752.008 1.129.01 1.498 0 2.996.003 4.495-.001.496-.013.925.067 1.378.252.599.22 1.08.63 1.434 1.124.176.272.31.605.376.916a.577.577 0 01-.025.305c-.413.019-.825.006-1.237.007-3.54-.006-7.075-.004-10.614.002.064-.095.129-.176.127-.293-.006-3.012.002-6-.004-9.025-.01-.23.04-.512-.052-.727-.124-.058-.294-.042-.43-.047-1.577.02-3.151-.007-4.728.026-.099.006-.186.008-.212.115-.046.223-.024.462-.027.688-.014 3.568-.009 7.137-.007 10.705-.012.423.036.853-.013 1.273l-.09.03c.021-.416.007-.83.01-1.248 0-3.768-.004-7.536.003-11.304.02-.515-.007-1.03.02-1.545.242-.017.481-.023.723-.019 1.5.01 2.999-.003 4.497.008a.951.951 0 00.32-.045c.06-.145.053-.31.054-.464-.01-.779.005-1.56-.007-2.338a1.46 1.46 0 01.041-.387z'
          ></path>
          <path
            fill='#FFE0DB'
            d='M5.215 1.55c1.576-.033 3.15-.005 4.727-.025.136.005.306-.011.43.047.092.215.041.498.052.727.006 3.024-.002 6.013.004 9.025.002.117-.063.198-.127.293-1.138.006-2.278-.016-3.415.01-.01.757-.013 1.52.003 2.277.312-.003.589-.105.892-.158.35-.064.701-.123 1.056-.16a7.757 7.757 0 012.738.29c.67.257 1.305.524 1.78 1.052-.011.139-.014.278-.018.417-.17.1-.381.27-.589.276-.257-.046-.498-.246-.717-.373a2.509 2.509 0 00-.62-.254c-.303-.09-.569-.2-.885-.242-.468-.06-.928-.115-1.402-.08-.924.069-1.853.306-2.755.505-.128.032-.275.056-.388.122a.857.857 0 00-.116.196L5.8 15.36c-.132.036-.264.074-.398.106a4.18 4.18 0 00-1.064.14c-.52.129-1.031.142-1.53.326-.307.048-.73.144-.935-.147-.235-.28-.119-.695.228-.838.382-.142.816-.178 1.216-.262.523-.082 1.04-.18 1.547-.323l.09-.03c.05-.42.002-.85.014-1.273-.002-3.568-.007-7.137.007-10.705.003-.226-.019-.465.027-.688.026-.107.113-.109.213-.115zM23.236 13.945c.292.068.588.11.884.159 1.089.204 2.133.54 3.225.738.395.062.77.172 1.174.182l.113.021c.311.064.62.13.939.16.25.028.505.038.751.087.268.052.422.298.428.54-.01.26-.2.49-.491.51-.532.046-1.083-.043-1.61-.108-.239-.049-.475-.108-.718-.14-.385-.086-.78-.122-1.163-.217l-.188-.05c-.558-.152-1.122-.285-1.68-.436a11.664 11.664 0 00-1.602-.34 11.744 11.744 0 00-1.49.005c-.462.077-.96.17-1.371.39-.206.095-.41.243-.63.296a.841.841 0 01-.497-.047c-.104-.057-.17-.182-.23-.275-.012-.123-.017-.244-.026-.367.155-.14.308-.277.492-.386.293-.18.61-.272.931-.398.847-.349 1.848-.411 2.76-.324z'
          ></path>
          <path
            fill='#FFE0DB'
            d='M23.236 13.946c.31.001.595.03.896.1.615.114 1.217.27 1.824.42.32.093.626.162.957.213.53.14 1.077.202 1.606.345-.403-.01-.78-.12-1.174-.182-1.092-.199-2.136-.534-3.225-.738-.296-.048-.592-.091-.884-.158z'
          ></path>
          <path
            fill='#F5F7F5'
            d='M13.355 14.928c.613.002 1.226.014 1.84-.003.253-.014.504-.05.758-.056.566-.029 1.127.038 1.687.117.47.046.942.047 1.413.067.01.122.015.244.026.366-.391.348-.652.839-.804 1.317-.131.483-.194.994-.088 1.487.17.99.832 1.907 1.737 2.447.473.307 1.013.463 1.575.56.348.034.704.021 1.053.015 1.132-.106 2.187-.627 2.907-1.449a4.108 4.108 0 001.077-2.019c.05-.355.031-.721.025-1.079 0-.322-.127-.582-.195-.899l.214.028.188.05c.074.288.15.582.184.877.011.252.014.51-.002.762-.035.261-.116.518-.187.771a5.23 5.23 0 01-1.717 2.364 5.063 5.063 0 01-2.436.934c-.508.07-1 .016-1.5-.07-1.54-.313-2.873-1.49-3.248-2.922-.091-.47-.19-.908-.101-1.39.055-.237.073-.457.175-.682.137-.301.323-.58.465-.88-.308-.082-.616-.083-.93-.118-.25-.04-.5-.084-.75-.112a3.673 3.673 0 00-1.057-.004c-.369.048-.741.059-1.11.111-.171.018-.347.018-.52.022.078.217.18.424.26.64.175.536.284 1.086.18 1.645-.068.352-.157.724-.34 1.04-.195.354-.383.701-.683.992-.369.427-.828.77-1.34 1.037-.587.234-1.175.417-1.818.452-.962.046-1.977-.172-2.77-.695-.742-.396-1.306-1.004-1.703-1.702-.206-.362-.392-.739-.471-1.143-.154-.586-.114-1.216-.076-1.82.054-.171.1-.342.129-.52.134-.032.265-.07.398-.106l.065.135c-.26.611-.237 1.215-.216 1.86.025.394.158.768.327 1.128.294.67.837 1.258 1.438 1.71.505.342 1.09.608 1.703.733 1.234.225 2.549-.043 3.535-.78.422-.343.785-.736 1.045-1.201.162-.321.304-.646.37-.997.17-.798-.028-1.589-.435-2.296a1.347 1.347 0 00-.295-.307c.004-.14.007-.278.018-.417z'
          ></path>
          <path
            fill='#222023'
            d='M21.808 15.055a11.744 11.744 0 011.49-.005c.54.077 1.08.189 1.601.34.559.151 1.123.284 1.68.437a7.2 7.2 0 00-.213-.028c.069.317.195.577.195.899.006.358.024.724-.025 1.079a4.108 4.108 0 01-1.077 2.019c.166-.341.454-.601.625-.94.14-.277.273-.568.342-.868.076-.417.114-.835.064-1.257-.032-.265-.048-.579-.18-.82-.057-.124-.242-.154-.367-.19-.946-.211-1.818-.543-2.806-.61-.522-.061-1.04-.02-1.557.047-.387.069-.762.194-1.143.287.41-.22.91-.313 1.37-.39z'
          ></path>
          <path
            fill='#A63325'
            d='M15.664 15.408c.357-.05.7-.05 1.056.003-.09.03-.255.036-.254.153-.04.357-.003.723-.009 1.082-.006 1.214-.001 2.428-.002 3.641.002.371-.015.737.03 1.107-.439.028-.868.118-1.31.127.378-.158.769-.165 1.172-.218.02-1.044.005-2.09 0-3.135-.003-.59.032-1.184-.008-1.773-.53-.014-1.08-.016-1.603.065.076.37.248.712.322 1.083.178.755.097 1.526-.152 2.258-.11.33-.334.62-.538.909-.33.464-.79.798-1.301 1.076-.375.2-.775.298-1.197.368-.325.052-.666.134-.994.1-.25-.025-.5-.075-.748-.119-.642-.118-1.25-.401-1.8-.726a1.843 1.843 0 01-.543-.483c.27.058.519.167.78.25.346.102.718.181 1.082.182.293 0 .588.004.88-.004.334-.01.669-.113.988-.204.212-.06.407-.148.596-.255.512-.267.971-.61 1.34-1.038.3-.29.489-.638.684-.992.182-.316.271-.688.34-1.04.103-.559-.006-1.109-.181-1.645-.08-.215-.182-.423-.26-.64.173-.003.35-.003.52-.021.369-.053.741-.063 1.11-.111zM5.402 15.466a3.554 3.554 0 01-.13.52c-.128.343-.114.685-.125 1.042-.005.372-.163.71-.157 1.084a288.64 288.64 0 00-.014 4.022c-.002.478.046.949-.008 1.427a2.26 2.26 0 01-.086-.611c-.013-2.174-.003-4.348.005-6.522.005-.276.01-.55-.018-.826-.689.063-1.37.296-2.062.33.5-.184 1.01-.197 1.53-.325a4.176 4.176 0 011.065-.14z'
          ></path>
          <path
            fill='#19473E'
            d='M16.72 15.41c.252.03.5.073.75.113.316.036.623.037.931.117-.142.3-.328.58-.465.881-.102.225-.12.445-.175.683-.09.481.01.918.101 1.389.375 1.432 1.707 2.609 3.247 2.923.501.085.993.14 1.5.07a5.062 5.062 0 002.437-.935 5.23 5.23 0 001.716-2.363c.252-.495.233-1 .214-1.535-.023-.273-.118-.534-.131-.81.365.044.717.134 1.086.15.243.033.48.092.719.14.08.448.05.887.053 1.337.002 1.267-.012 2.536-.007 3.804.005.453-.043.907-.064 1.36-.063.597-.209 1.195-.315 1.786-.128.642-.41 1.191-.632 1.802-.147.377-.375.724-.582 1.08a11.584 11.584 0 01-3.091 3.487c-.292.252-.65.4-.958.632-.3.225-.624.39-.95.58-.552.307-1.21.426-1.797.68-.448.203-.922.286-1.398.414-.8.166-1.617.245-2.436.187a7.095 7.095 0 01-.02-.488c.005-2.409.001-4.818.002-7.227 0-.349-.02-.694.024-1.041.295.115.552.29.841.415.406.183.836.333 1.278.422 1.24.215 2.578.154 3.836.127.623-.045 1.256-.075 1.868-.194.298-.05.588-.073.878-.166.33-.109.67-.189.996-.31a10.841 10.841 0 01-1.857-.19 11.79 11.79 0 01-2.178-.742c-.23-.105-.426-.25-.635-.385-.27-.177-.579-.284-.85-.461-.513-.335-1.015-.698-1.547-1.008-.523-.3-1.096-.498-1.688-.642-.308-.053-.624-.066-.936-.098-.045-.37-.029-.736-.03-1.107 0-1.214-.004-2.428.002-3.641.006-.36-.03-.725.009-1.082 0-.117.164-.122.254-.153z'
          ></path>
          <path
            fill='#222023'
            d='M26.768 15.877c.383.094.778.13 1.163.216-.37-.016-.721-.106-1.086-.15.013.275.108.536.13.81.02.534.04 1.04-.212 1.534.071-.253.152-.51.188-.77.015-.253.012-.51 0-.763-.033-.295-.109-.589-.183-.877z'
          ></path>
          <path
            fill='#FFE0DB'
            d='M5.273 15.986c-.038.604-.078 1.234.076 1.82.079.404.265.781.47 1.142.398.699.962 1.307 1.704 1.702.793.524 1.808.742 2.77.696.643-.035 1.231-.218 1.818-.452a2.676 2.676 0 01-.596.255c-.319.092-.654.194-.987.204-.293.008-.588.004-.88.004-.365 0-.737-.08-1.083-.181-.261-.084-.51-.192-.78-.251.153.2.321.35.543.483.55.325 1.158.609 1.8.726.248.044.497.095.748.12.328.034.669-.048.994-.1.422-.071.822-.168 1.197-.368.51-.28.972-.613 1.301-1.077.204-.288.429-.58.538-.909.25-.732.33-1.503.152-2.258-.074-.37-.246-.712-.322-1.082.523-.081 1.074-.08 1.603-.066.04.59.005 1.183.007 1.773.006 1.044.02 2.091 0 3.135-.402.053-.793.06-1.17.218-.364.106-.713.18-1.043.337-.278.132-.563.266-.821.43-.383.24-.746.506-1.134.74-.467.321-.944.623-1.478.842-1.058.52-2.201.796-3.383.932-.336.034-.67.004-1.007.033.227.124.467.157.716.223.566.143 1.16.295 1.743.363.515.045 1.007.112 1.522.11 1.012.006 2.024.01 3.036 0 .479 0 .932-.1 1.393-.21.323-.078.636-.232.94-.362.264-.11.476-.29.75-.382.018.145.03.29.028.438-.009.706.008 1.413-.011 2.12-.037.49-.018.975-.024 1.467.003.89-.015 1.774.031 2.662-.01.705.023 1.413-.015 2.116-1.207.008-2.43-.23-3.57-.59-.517-.187-1.048-.373-1.526-.633-.75-.352-1.441-.83-2.112-1.298-.367-.247-.632-.557-.962-.845a9.587 9.587 0 01-1.108-1.258c-.237-.323-.497-.619-.71-.957-.152-.235-.266-.484-.388-.732-.584-1.083-.918-2.306-1.075-3.506.055-.477.006-.948.008-1.426-.007-1.34.002-2.681.014-4.022-.006-.373.152-.712.157-1.084.012-.357-.002-.7.126-1.042z'
          ></path>
        </g>
        <rect
          width='31'
          height='31'
          x='0.75'
          y='0.967'
          stroke='#fff'
          rx='15.5'
        ></rect>
      </g>
      <defs>
        <filter
          id='filter0_b_785_2482'
          width='44'
          height='44'
          x='-5.75'
          y='-5.533'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feGaussianBlur
            in='BackgroundImageFix'
            stdDeviation='3'
          ></feGaussianBlur>
          <feComposite
            in2='SourceAlpha'
            operator='in'
            result='effect1_backgroundBlur_785_2482'
          ></feComposite>
          <feBlend
            in='SourceGraphic'
            in2='effect1_backgroundBlur_785_2482'
            result='shape'
          ></feBlend>
        </filter>
        <clipPath id='clip0_785_2482'>
          <rect
            width='32'
            height='32'
            x='0.25'
            y='0.467'
            fill='#fff'
            rx='16'
          ></rect>
        </clipPath>
      </defs>
    </svg>
  );
}
