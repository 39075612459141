import * as yup from 'yup';
import { FORM_ERRORS } from '../../constants';

const PERCENTAGE_MIN = 0;
const PERCENTAGE_MAX = 100;

export const createEditBotValidationSchema = yup.object({
  strategyTitle: yup.string().required(FORM_ERRORS.REQUIRED('Bot name')),
  firstBlock: yup.boolean().required(),
  opponentPumps: yup.boolean().required(),
  opponentDumps: yup.boolean().required(),
  forgiveRate: yup
    .number()
    .required()
    .min(PERCENTAGE_MIN, FORM_ERRORS.MIN_PERCENT(PERCENTAGE_MIN))
    .max(PERCENTAGE_MAX, FORM_ERRORS.MAX_PERCENT(PERCENTAGE_MAX)),
  friendPumps: yup.boolean().required(),
  friendDumps: yup.boolean().required(),
});

export type CreateEditBotValidationType = yup.InferType<
  typeof createEditBotValidationSchema
>;
