import { CardMarkerContainer } from './CardMarker.styled';

type CardMarkerType = {
  title: string;
  className?: string;
};

export const CardMarker = ({ title, className }: CardMarkerType) => {
  return (
    <CardMarkerContainer className={className}>{title}</CardMarkerContainer>
  );
};
