import { alpha, Box, Button, List, styled, Typography } from '@mui/material';

export const MyBotsTitle = styled(Typography)`
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.darkGrey.main};
`;

export const MyBotsSubtitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.greyCustom.main};
`;

export const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LogoWrapper = styled(Box)`
  display: flex;

  svg {
    height: 30px;
  }
`;

export const ActionWrapper = styled(Box)`
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 26px;
  color: ${({ theme }) => theme.palette.white.main};
  border-radius: 36px;
  background: ${({ theme }) => alpha(theme.palette.black.main, 79)};
  backdrop-filter: blur(3px);
`;

export const ActionTitle = styled(Typography)`
  max-width: 200px;
`;

export const StyledButton = styled(Button)`
  text-transform: uppercase;
`;

export const StyledList = styled(List)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
