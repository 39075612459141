import { Alert, Box, styled, Typography } from '@mui/material';

import { THEME } from '../../constants';

export const ContainerLogo = styled(Box)`
  margin-top: 26px;
`;

export const ConnectTitle = styled(Typography)`
  max-width: 240px;
  margin-bottom: 12px;
`;

export const StyledAlert = styled(Alert)`
  width: 100%;
  margin-block: 16px 22px;
  padding: 6px 20px 6px 16px;
  display: flex;
  align-items: center;

  .MuiAlert-message {
    padding: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }
`;

export const RegisterIconWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${THEME.BORDER_RADIUS.PILL};
  width: 44px;
  height: 44px;
`;

export const JoinToCommunityTitle = styled(Typography)`
  margin-bottom: 16px;
  text-align: center;
`;
