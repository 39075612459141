export const FB_SKD_URL = 'https://connect.facebook.net/en_US/sdk.js';

export const REFRESH_TOKEN_KEY = 'refreshToken';

export const CLIENT_ENDPOINTS = {
  AUTH_SING_IN: '/auth/signin',
  AUTH_SING_OUT: '/auth/signout',
  REFRESH_TOKEN: '/auth/refresh',
  BFF_PROFILE: '/bff/profile',
  BOT: '/bot',
  SELL_BOT: '/bot',

  BFF_LOBBY: '/bff/lobby',
  WALLET_BALANCES: '/wallet/balances',
  JOIN_STRATEGY_TO_BATTLE: '/lobby/join',
  REMOVE_STRATEGY_FROM_BATTLE: '/lobby/remove',
  BATTLE: '/battle',
  BFF_BATTLE: '/bff/battle',
  LEADER_BOARD: '/leaderboard',
  MARKETPLACE: '/shop',
  BUY_ITEM({
    shopItemId,
    idempotencyKey,
  }: {
    shopItemId: string;
    idempotencyKey: string;
  }) {
    return `/shop/buy/${shopItemId}?idempotencyKey=${idempotencyKey}`;
  },
  MISSIONS: '/mission',
  CLAIM_MISSION_REWARD({ missionId }: { missionId: string }) {
    return `/mission/${missionId}`;
  },
};
