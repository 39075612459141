import { Box, Button, ButtonProps, Modal, styled } from '@mui/material';
import Masonry from '@mui/lab/Masonry';
import { CardMarker } from '../CardMarker/CardMarker';

interface GasTabCardContainerProps extends ButtonProps {
  isInModal?: boolean;
}

export const GasTabContainer = styled(Box)`
  margin-inline: -6px;
`;

export const GasTabCardContainer = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isInModal',
})<GasTabCardContainerProps>(({ theme, isInModal }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative',
  gap: '16px',
  height: '118px',
  padding: '4px',
  backgroundColor: theme.palette.orange.main,
  borderRadius: '26px',
  cursor: isInModal ? 'auto' : 'pointer',
  maxWidth: isInModal ? '168px' : 'auto',
  '&:hover': {
    backgroundColor: theme.palette.orange.main,
  },
}));

export const GasTabCardAmountContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 10%;
  align-items: center;
  gap: 6px;
  color: white;
  @media (max-width: 768px) {
    padding: 0 8%;
  }
`;

export const GasTabCardAmount = styled(Box)`
  font-size: 32px;
  line-height: normal;
  font-family: Aldrich;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
`;

export const StyledMasonry = styled(Masonry)`
  margin-inline: 0;
`;

export const StyledGasCardMarker = styled(CardMarker)`
  position: absolute;
  top: -10px;
  right: -6px;
  transform: rotate(-26deg);
  backdrop-filter: blur(2px);
  border: 1px solid white;
  color: black;
`;

// Styled component for the centered modal
export const CenteredModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WhiteBorderWrapper = styled('div')({
  width: 'inherit',
  height: '100%',
  position: 'absolute',
  border: '1px solid white',
  borderRadius: '46px',
});

// Styled component for the modal content
export const ModalContent = styled('div')({
  backgroundColor: '#EAEBE5',
  boreder: '1px solid white',
  borderRadius: '46px',
  textAlign: 'center',
  width: '100%',
  maxWidth: '343px',
  position: 'relative',
});

export const ModalHeaderContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px 16px 26px 26px',
});

export const ModalHeaderText = styled('p')({
  fontSize: '24px',
  fontWeight: 400,
  fontFamily: 'Aldrich',
  color: '#0C090C',
  margin: '-24px 0',
  textTransform: 'uppercase',
});

export const ModalCloseButton = styled('button')({
  height: '52px',
  width: '52px',
  borderRadius: '50%',
  border: '1px solid rgba(12, 9, 12, 0.2)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  zIndex: 10,
});

export const ModalContentWrapper = styled('div')({
  border: '1px solid #292929',
  background: '#0C090C',
  borderRadius: '46px',
  padding: '74px 32px 26px',
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const ModalControlButtonsWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '106px',
  width: '100%',
});

export const ModalCancelButton = styled('button')({
  height: '52px',
  width: '132px',
  background: '#0C090C',
  color: 'white',
  borderRadius: '100px',
  fontSize: '16px',
  fontFamily: 'Aldrich',
  fontWeight: 400,
  border: '1px solid white',
  cursor: 'pointer',
  textTransform: 'uppercase',
  zIndex: 10,
});

export const ModalConfirmButton = styled('button')({
  height: '52px',
  width: '132px',
  border: '1px solid #2CE72B',
  background: '#144934',
  color: '#2CE72B',
  borderRadius: '100px',
  fontSize: '16px',
  fontFamily: 'Aldrich',
  fontWeight: 400,
  cursor: 'pointer',
  textTransform: 'uppercase',
  alignItems: 'center',
  justifyContent: 'space-between',
  display: 'flex',
  padding: '0px 10px',
  zIndex: 10,
});
