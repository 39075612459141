import { ReactNode } from 'react';

import { CloseIcon } from '../../icons';
import { ModalsType } from '../../../data-models';
import { useModalContext } from '../../../context';

import {
  ModalLayoutCloseButton,
  ModalLayoutContainer,
  ModalLayoutContentContainer,
  ModalLayoutHeaderContainer,
  ModalLayoutTitlesContainer,
} from './ModalLayout.styled';
import { Slide } from '@mui/material';
import { useWindowDimensions } from '../../../hooks';

type modalLayoutType = {
  titles: ReactNode;
  children: ReactNode;
  modalType: ModalsType;
  minHeight?: string;
  className?: string;
};

const MODAL_MARKING_BLOCK = 20;

export const ModalLayout = ({
  titles,
  children,
  modalType,
  className,
  minHeight = '540px',
}: modalLayoutType) => {
  const { handleChangeModalParameters, modalParameters } = useModalContext();
  const { height } = useWindowDimensions();

  const handleCloseClick = () => {
    handleChangeModalParameters([
      {
        key: 'type',
        value: modalType,
      },
      {
        key: 'isOpen',
        value: false,
      },
    ]);
  };

  const minHeightModal =
    parseInt(minHeight) + MODAL_MARKING_BLOCK * 2 > height
      ? `${height - MODAL_MARKING_BLOCK * 2}px`
      : minHeight;

  return (
    <Slide in={modalParameters?.isOpen} direction='up'>
      <ModalLayoutContainer minHeight={minHeightModal}>
        <ModalLayoutHeaderContainer>
          <ModalLayoutTitlesContainer>{titles}</ModalLayoutTitlesContainer>

          <ModalLayoutCloseButton
            color='white'
            variant='contained'
            onClick={handleCloseClick}
          >
            <CloseIcon />
          </ModalLayoutCloseButton>
        </ModalLayoutHeaderContainer>

        <ModalLayoutContentContainer className={className}>
          {children}
        </ModalLayoutContentContainer>
      </ModalLayoutContainer>
    </Slide>
  );
};
