import { LinearProgress, Typography, alpha, styled } from '@mui/material';

type LinearProgressCustomProps = {
  rtl?: number;
};

export const LinearProgressCustom = styled(
  LinearProgress,
)<LinearProgressCustomProps>`
  width: 312px;
  height: 24px;
  border-radius: 8px;
  background-color: ${({ theme }) => alpha(theme.palette.black.main, 0.72)};
  transform: ${({ rtl }) => `rotate(${rtl ? '180deg' : 0})`};

  & .MuiLinearProgress-bar {
    background-color: ${({ theme }) => theme.palette.lime.main};
  }
`;

export const ProgressBarTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.black.dark};
  position: absolute;
  top: 14px;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  text-wrap: nowrap;
  text-transform: uppercase;
`;
