import { useTheme } from '@mui/material';
import omit from 'lodash/omit';

import { CreateEditBotForm, MyBotsCreateEditLayout } from '../../components';
import {
  CreateButton,
  CreateButtonIconWrapper,
  CreateButtonText,
  MyBotCreatePageContainer,
} from './MyBotCreatePage.styled';
import { AddCircleIcon } from '../../components/icons';
import { CreateEditBotValidationType } from '../../components/CreateEditBotForm/CreateEditBotForm.validation';
import { useBotCreateEditMutation } from '../../redux/api';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants';

export const MyBotCreatePage = () => {
  const formId = 'MyBotCreatePage';
  const theme = useTheme();
  const navigate = useNavigate();
  const [createEditBot, { isLoading }] = useBotCreateEditMutation();

  const handleSubmit = (value: CreateEditBotValidationType) => {
    createEditBot({
      name: value.strategyTitle,
      instructions: {
        ...omit(value, 'strategyTitle'),
      },
    })
      .unwrap()
      .catch(() => {})
      .finally(() => navigate(ROUTES.MY_BOTS, { replace: true }));
  };

  return (
    <MyBotsCreateEditLayout title='New bot' isShowFaq>
      <MyBotCreatePageContainer>
        <CreateEditBotForm onSubmit={handleSubmit} idForm={formId} />

        <CreateButton
          variant='contained'
          type='submit'
          form={formId}
          disabled={isLoading}
          color='lime'
        >
          <CreateButtonText variant='button'>Create bot</CreateButtonText>
          <CreateButtonIconWrapper>
            <AddCircleIcon color={theme.palette.white.main} />
          </CreateButtonIconWrapper>
        </CreateButton>
      </MyBotCreatePageContainer>
    </MyBotsCreateEditLayout>
  );
};
