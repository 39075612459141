import { FieldErrorText } from './FieldError.styled';

export type FieldErrorType = {
  message?: string;
};

export const FieldError = ({ message }: FieldErrorType) => {
  if (!message) return null;

  return <FieldErrorText>{message}</FieldErrorText>;
};
