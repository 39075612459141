import { Box, styled } from '@mui/material';
import { THEME } from '../../../constants';

interface PumpSwitchProps {
  iscolored: boolean;
  ischecked: boolean;
}

export const PumpSwitchTrack = styled(Box)<PumpSwitchProps>`
  width: 72px;
  height: 28px;
  display: flex;
  align-items: center;
  padding: 0 1px;
  position: relative;
  cursor: pointer;
  border-radius: ${THEME.BORDER_RADIUS.PILL};
  background: ${({ iscolored, ischecked }) => {
    if (iscolored) {
      if (ischecked) {
        return THEME.COLORS.GREEN_DARK;
      } else {
        return THEME.COLORS.RED_SWITCH_DARK;
      }
    } else {
      if (ischecked) {
        return THEME.COLORS.GREEN_DARK;
      } else {
        return THEME.COLORS.DARK_GREY_LIGHT;
      }
    }
  }};
  border: 1px solid;
  border-color: ${({ iscolored, ischecked }) => {
    if (iscolored) {
      if (ischecked) {
        return THEME.COLORS.GREEN_MAIN;
      } else {
        return THEME.COLORS.RED_SWITCH;
      }
    } else {
      if (ischecked) {
        return THEME.COLORS.GREEN_MAIN;
      } else {
        return '#FFFFFF4D';
      }
    }
  }};
`;

export const PumpSwitchThumb = styled(Box)<PumpSwitchProps>`
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: ${({ iscolored, ischecked }) => {
    if (iscolored) {
      if (ischecked) {
        return THEME.COLORS.GREEN_MAIN;
      } else {
        return THEME.COLORS.RED_SWITCH;
      }
    } else {
      if (ischecked) {
        return THEME.COLORS.GREEN_MAIN;
      } else {
        return THEME.COLORS.BLACK_SECONDARY;
      }
    }
  }};
  transform: ${({ ischecked }) =>
    ischecked ? 'translateX(43px)' : 'translateX(0px)'};
  transition-duration: 100ms;
`;

export const PumpSwitchLabel = styled(Box)<PumpSwitchProps>`
  font-size: 12.5px;
  color: ${({ ischecked }) =>
    ischecked ? THEME.COLORS.GREEN_MAIN : THEME.COLORS.RED_SWITCH};
  position: absolute;
  left: ${({ ischecked }) => (ischecked ? '6px' : 'auto')};
  right: ${({ ischecked }) => (ischecked ? 'auto' : '6px')};
`;
