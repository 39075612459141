import { RootState } from '../store';
import { createSelector } from '@reduxjs/toolkit';

export const selectAuthState = (state: RootState) => state.authReducer;

export const selectIsFbInitialized = createSelector(
  [selectAuthState],
  (state) => state.isFbInitialized,
);

export const selectFbProfile = createSelector(
  [selectAuthState],
  (state) => state.facebookProfile,
);

export const selectClientProfile = createSelector(
  [selectAuthState],
  (state) => state.clientAuth,
);
