/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createApi } from '@reduxjs/toolkit/query/react';

import { client } from './client';
import { CLIENT_ENDPOINTS } from '../../constants';
import {
  BalanceData,
  BattleParticipantData,
  BattleRenderData,
  LobbyData,
  BotData,
  MissionsResultData,
} from '../../data-models/game';
import { serviceActions } from '../reducers/serviceSlice';
import { BalanceAssetType } from '../../data-models/enums';

export const battleAPI = createApi({
  reducerPath: 'battleApi',
  keepUnusedDataFor: 0,
  baseQuery: client,
  tagTypes: ['lobby', 'balances', 'battle', 'bots', 'missions'],
  endpoints: (builder) => ({
    bffLobby: builder.query<LobbyData, void>({
      query: () => ({
        url: CLIENT_ENDPOINTS.BFF_LOBBY,
        method: 'GET',
      }),
      providesTags: ['lobby'],
      transformResponse: (result: LobbyData) => {
        const secondsTillNextAirdrop =
          Date.now() + result.airdrop.secondsTillNextAirdrop * 1000;

        return {
          ...result,
          airdrop: {
            ...result.airdrop,
            secondsTillNextAirdrop,
          },
        };
      },
    }),

    balances: builder.query<BalanceData[], void>({
      query: () => ({
        url: CLIENT_ENDPOINTS.WALLET_BALANCES,
        method: 'GET',
      }),
      providesTags: ['balances'],
      async onQueryStarted(arg, { dispatch, getState, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const coinValue =
            data?.find(
              (currency) => currency?.assetType === BalanceAssetType.coin,
            )?.amount || 0;

          const gasValue =
            data?.find(
              (currency) => currency?.assetType === BalanceAssetType.gas,
            )?.amount || 0;

          const state = getState();
          //@ts-ignore
          const latestCoinBalance = state?.serviceReducer?.latestCoinBalance;
          //@ts-ignore
          const latestGasBalance = state?.serviceReducer?.latestGasBalance;

          const isFirstBalanceRequest =
            //@ts-ignore
            state?.serviceReducer?.isFirstBalanceRequest;

          const profitCoinDelta = coinValue - latestCoinBalance;
          const profitGasDelta = gasValue - latestGasBalance;

          if (profitCoinDelta > 0) {
            dispatch(serviceActions.updateCoinProfitDelta(profitCoinDelta));
          }

          if (profitGasDelta > 0) {
            dispatch(serviceActions.updateGasProfitDelta(profitGasDelta));
          }

          dispatch(serviceActions.updateLatestCoinBalanceValue(coinValue || 0));
          dispatch(serviceActions.updateLatestGasBalanceValue(gasValue || 0));

          if (isFirstBalanceRequest) {
            dispatch(serviceActions.updateCoinProfitDelta(0));
            dispatch(serviceActions.updateGasProfitDelta(0));
            dispatch(serviceActions.setIsFirstBalanceRequest(false));
          }

          if (!isFirstBalanceRequest && profitCoinDelta > 0) {
            dispatch(serviceActions.updateCoinProfitDelta(profitCoinDelta));
          }

          if (!isFirstBalanceRequest && profitGasDelta > 0) {
            dispatch(serviceActions.updateGasProfitDelta(profitGasDelta));
          }
        } catch (error) {
          console.error('Error fetching balances:', error);
        }
      },
    }),

    bffBattle: builder.query<BattleRenderData, void>({
      query: () => ({
        url: CLIENT_ENDPOINTS.BFF_BATTLE,
        method: 'GET',
      }),
      transformResponse: (result: BattleRenderData) => {
        return {
          battle: {
            ...result.battle,
            participants: result.battle.participants.map((participant) => ({
              ...participant,
              score: result.battle.totalScores[participant.botId] || 0,
            })),
          },
        };
      },
      providesTags: ['battle'],
    }),

    joinToBattle: builder.mutation<BattleParticipantData, number>({
      query: (id) => ({
        url: CLIENT_ENDPOINTS.JOIN_STRATEGY_TO_BATTLE,
        method: 'POST',
        body: {
          botId: id,
        },
      }),
      invalidatesTags: ['bots'],
    }),

    removeFromBattle: builder.mutation<BattleParticipantData, number>({
      query: (id) => ({
        url: CLIENT_ENDPOINTS.REMOVE_STRATEGY_FROM_BATTLE,
        method: 'POST',
        body: {
          botId: id,
        },
      }),
      invalidatesTags: ['bots'],
    }),

    botList: builder.query<BotData[], void>({
      query: () => ({
        url: CLIENT_ENDPOINTS.BOT,
        method: 'GET',
      }),
      providesTags: ['bots'],
      keepUnusedDataFor: 5,
    }),

    botCreateEdit: builder.mutation<BotData, any>({
      query: (bot) => ({
        url: CLIENT_ENDPOINTS.BOT,
        method: 'POST',
        body: bot,
      }),
      invalidatesTags: ['battle', 'bots', 'lobby'],
    }),
    deleteBot: builder.mutation<BotData, any>({
      query: (bot) => ({
        url: CLIENT_ENDPOINTS.BOT,
        method: 'DELETE',
        body: bot,
      }),
      invalidatesTags: ['battle', 'bots', 'lobby'],
    }),
    missionsList: builder.query<MissionsResultData, void>({
      query: () => ({
        url: CLIENT_ENDPOINTS.MISSIONS,
        method: 'GET',
      }),
      providesTags: ['missions'],
    }),
    claimReward: builder.mutation<any, any>({
      query: ({ missionId }) => ({
        url: CLIENT_ENDPOINTS.CLAIM_MISSION_REWARD({ missionId }),
        method: 'PATCH',
      }),
      invalidatesTags: ['balances', 'missions'],
    }),
  }),
});

export const {
  useLazyBffLobbyQuery,
  useBffLobbyQuery,
  useLazyBalancesQuery,
  useBffBattleQuery,
  useJoinToBattleMutation,
  useRemoveFromBattleMutation,
  useBotListQuery,
  useLazyBotListQuery,
  useMissionsListQuery,
  useDeleteBotMutation,
  useBotCreateEditMutation,
  useClaimRewardMutation,
} = battleAPI;
