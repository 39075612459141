import React, { useContext, useState, FC } from 'react';

export const ModalContext = React.createContext<any>('');

export function useModalContext() {
  const modalContext = useContext(ModalContext);
  if (!modalContext) {
    throw new Error('useModalContext must be used within a AuthProvider');
  }
  return modalContext;
}

export const ModalContextProvider: FC<{ children: any }> = ({ children }) => {
  const [modalParameters, setModalParameters] = useState({
    onCallback: () => {},
    value: '',
    type: '',
    isOpen: false,
  });

  type paramsType = {
    key: string;
    value: any;
  };

  const handleChangeModalParameters = (params: Array<paramsType>) => {
    params?.map(({ key, value }) => {
      return setModalParameters((prevModalParameters) => ({
        ...prevModalParameters,
        [key]: value,
      }));
    });
  };

  return (
    <ModalContext.Provider
      value={{
        isAuth: false,
        handleChangeModalParameters: handleChangeModalParameters,
        modalParameters: modalParameters,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
