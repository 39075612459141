import { Box, Stack, Typography, useTheme } from '@mui/material';
import {
  NameText,
  ProfitText,
  RoundResultWrapper,
  ScoreText,
  TableResultsItem,
  TableResultsWrapper,
} from './RoundResult.styled';
import { BotResultData } from '../../data-models';
import { roundToTwo } from '../../utils';
import { PumpCurrencyCoinIcon } from '../icons';

export const RoundResult = ({ data }: { data?: BotResultData[] }) => {
  const theme = useTheme();

  return (
    <RoundResultWrapper>
      <Typography variant='h1' m={'16px 26px'} color={theme.palette.black.main}>
        ROUND RESULTS
      </Typography>
      <TableResultsWrapper>
        {data?.map(({ botId, botName, reward, score, isOwner }) => {
          return (
            <Box key={botId}>
              <TableResultsItem direction={'row'} isowner={isOwner ? 1 : 0}>
                <ScoreText variant='body1' mr={'6px'} isowner={isOwner ? 1 : 0}>
                  {score}
                </ScoreText>
                <NameText variant='body1'>{botName}</NameText>
                <ProfitText
                  variant='body1'
                  ml={'auto'}
                  isreward={reward ? 1 : 0}
                >
                  {reward ? (
                    <Stack direction='row' alignItems={'center'}>
                      +
                      <Stack sx={{ margin: '0 5px' }}>
                        <PumpCurrencyCoinIcon size={22} />
                      </Stack>
                      {roundToTwo(reward)}
                    </Stack>
                  ) : (
                    0
                  )}
                </ProfitText>
              </TableResultsItem>
            </Box>
          );
        })}
      </TableResultsWrapper>
    </RoundResultWrapper>
  );
};
