import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import omit from 'lodash/omit';

import {
  CreateEditBotForm,
  MyBotsCreateEditLayout,
  WrappedCircularProgress,
} from '../../components';
import {
  ControlButton,
  ControlButtonsWrapper,
  MyBotEditPageContainer,
} from './MyBotEditPage.styled';
import { BotEditQueryParams } from '../../data-models';
import { useBotCreateEditMutation, useLazyBotListQuery } from '../../redux/api';
import { ROUTES } from '../../constants';
import { CreateEditBotValidationType } from '../../components/CreateEditBotForm/CreateEditBotForm.validation';
import { DeleteBotModal } from './DeleteBotModal';

export const MyBotEditPage = () => {
  const formId = 'MyBotEditPage';
  const navigate = useNavigate();

  const { id } = useParams<BotEditQueryParams>();
  const [fetchBotList, { data, isFetching }] = useLazyBotListQuery({
    selectFromResult: (result) => ({
      ...result,
      data: result.data?.find((bot) => bot.id === Number(id)),
    }),
  });

  const [createEditBot, { isLoading: isLoadingEditBot }] =
    useBotCreateEditMutation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchBotList();
      } catch (e) {
        navigate(ROUTES.MY_BOTS);
      }
    };

    fetchData();
  }, []);

  const botDeletionData = {
    id: Number(id),
    botName: data?.name || '',
  };

  const handleSubmit = (value: CreateEditBotValidationType) => {
    createEditBot({
      name: value.strategyTitle,
      id: Number(id),
      instructions: {
        ...omit(value, 'strategyTitle'),
      },
    })
      .unwrap()
      .catch(() => {})
      .finally(() => navigate(ROUTES.MY_BOTS, { replace: true }));
  };

  return (
    <MyBotsCreateEditLayout title='Bot settings' isShowFaq>
      {isFetching && <WrappedCircularProgress />}

      {data && !isFetching && (
        <MyBotEditPageContainer>
          <CreateEditBotForm
            onSubmit={handleSubmit}
            idForm={formId}
            formValue={data}
          />

          <ControlButtonsWrapper>
            <DeleteBotModal botData={botDeletionData} />
            <ControlButton
              disabled={isLoadingEditBot}
              type='submit'
              form={formId}
              variant='outlinedSuccess'
            >
              Save
            </ControlButton>
          </ControlButtonsWrapper>
        </MyBotEditPageContainer>
      )}
    </MyBotsCreateEditLayout>
  );
};
