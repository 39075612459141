import { useModalContext } from '../../context';
import { JoinToPumpModal, TopPerformersModal } from '../Modal';
import { TYPE_MODAL } from '../../constants';
import { SellBotModal } from '../Modal/SellBotModal/SellBotModal';
import { BuyBotModal } from '../Modal/BuyBotModal/BuyBotModal';
import { CongratulationsModal } from '../Modal/CongratulationsModal/CongratulationsModal';
import { StyledModal, StyledModalContainer } from './ModalController.styled';
import { TestBotModal } from '../Modal/TestBotModal/TestBotModal';

export const ModalController = () => {
  const { modalParameters } = useModalContext();

  switch (modalParameters.type) {
    case TYPE_MODAL.JOIN_TO_PUMP:
      return <JoinToPumpModal />;
    case TYPE_MODAL.HOME_TOP_PERFORMERS:
      return <TopPerformersModal />;
    case TYPE_MODAL.SELL_BOT:
      return <SellBotModal />;
    case TYPE_MODAL.BUY_BOT:
      return <BuyBotModal />;
    case TYPE_MODAL.CONGRATULATIONS:
      return <CongratulationsModal />;
    case TYPE_MODAL.TEST_BOT:
      return <TestBotModal />;
    default:
      return <div />;
  }
};

export const BasicModal = () => {
  const { modalParameters } = useModalContext();

  const isOpen = !!modalParameters?.isOpen;

  return (
    <StyledModal open={isOpen}>
      <StyledModalContainer>
        <ModalController />
      </StyledModalContainer>
    </StyledModal>
  );
};
