export const TelegramFillIcon = () => {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.25 22C6.727 22 2.25 17.523 2.25 12C2.25 6.477 6.727 2 12.25 2C17.773 2 22.25 6.477 22.25 12C22.25 17.523 17.773 22 12.25 22ZM9.14 13.17L9.153 13.163L10.023 16.033C10.135 16.344 10.289 16.4 10.476 16.374C10.664 16.349 10.763 16.248 10.886 16.13L12.074 14.982L14.624 16.87C15.09 17.127 15.425 16.994 15.541 16.438L17.198 8.616C17.381 7.888 17.061 7.596 16.496 7.828L6.763 11.588C6.099 11.854 6.103 12.226 6.643 12.391L9.14 13.171V13.17Z'
        fill='#0C090C'
      />
    </svg>
  );
};
