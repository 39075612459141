export const AddSquareIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M23 12C23 17.1854 23 19.7781 21.3885 21.3885C19.7792 23 17.1854 23 12 23C6.8146 23 4.2219 23 2.6104 21.3885C1 19.7792 1 17.1854 1 12C1 6.8146 1 4.2219 2.6104 2.6104C4.223 1 6.8146 1 12 1C17.1854 1 19.7781 1 21.3885 2.6104C22.4599 3.6818 22.8185 5.1877 22.9395 7.6'
        stroke='#C5E961'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <line
        x1='12'
        y1='7'
        x2='12'
        y2='17'
        stroke='#C5E961'
        strokeWidth='2'
        strokeLinecap='round'
      />
      <line
        x1='7'
        y1='12'
        x2='17'
        y2='12'
        stroke='#C5E961'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  );
};
