import { Colors } from '../../data-models';

type AwardThirdIconType = {
  color?: Colors;
};

export const AwardThirdIcon = ({ color = '#EAEBE5' }: AwardThirdIconType) => {
  return (
    <svg
      width='16'
      height='22'
      viewBox='0 0 16 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.38004 5.71631C6.31758 5.71631 7.12684 5.09399 7.40546 4.20819C7.59762 3.59727 8.40238 3.59727 8.59454 4.20819C8.87316 5.09399 9.68242 5.71631 10.62 5.71631C10.91 5.71631 11.1265 5.89471 11.2145 6.17451C11.303 6.45601 11.2307 6.75176 10.9794 6.9404C10.236 7.49829 9.93629 8.47391 10.2138 9.35607C10.3162 9.68175 10.1936 9.96063 9.97864 10.122C9.76719 10.2807 9.50239 10.304 9.25981 10.122C8.5103 9.55945 7.4897 9.55944 6.74019 10.122C6.49761 10.304 6.23281 10.2807 6.02136 10.122C5.80639 9.96062 5.68379 9.68175 5.78623 9.35607C6.06371 8.47391 5.76396 7.49829 5.02062 6.9404C4.76928 6.75176 4.69695 6.45601 4.7855 6.17451C4.87351 5.89471 5.08997 5.71631 5.38004 5.71631Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 15C12.1421 15 15.5 11.6421 15.5 7.5C15.5 3.35786 12.1421 0 8 0C3.85786 0 0.5 3.35786 0.5 7.5C0.5 11.6421 3.85786 15 8 15ZM10.0254 3.75812C9.39293 1.74729 6.60707 1.74729 5.97458 3.75812C5.8844 4.0448 5.63594 4.21631 5.38004 4.21631C3.27098 4.21631 2.5079 6.93003 4.12023 8.1401C4.3424 8.30685 4.44667 8.61567 4.35535 8.90599C4.04546 9.89119 4.44292 10.8128 5.12096 11.3217C5.80252 11.8332 6.80368 11.9498 7.64058 11.3217C7.8566 11.1595 8.1434 11.1595 8.35942 11.3217C9.19632 11.9498 10.1975 11.8332 10.879 11.3217C11.5571 10.8128 11.9545 9.89119 11.6447 8.90599C11.5533 8.61567 11.6576 8.30685 11.8798 8.1401C13.4921 6.93003 12.729 4.21631 10.62 4.21631C10.3641 4.21631 10.1156 4.0448 10.0254 3.75812Z'
        fill={color}
      />
      <path
        d='M8 16.5C10.3051 16.5 12.4077 15.6334 14 14.2083V18.8787C14 20.4429 14 21.225 13.501 21.4482C13.002 21.6713 12.3914 21.1623 11.1701 20.1444L10.2313 19.3619C9.16653 18.4744 8.63415 18.0307 8 18.0307C7.36585 18.0307 6.83346 18.4744 5.76871 19.3619L4.82993 20.1444C3.60862 21.1623 2.99796 21.6713 2.49898 21.4482C2 21.225 2 20.4429 2 18.8787V14.2083C3.59227 15.6334 5.69494 16.5 8 16.5Z'
        fill={color}
      />
    </svg>
  );
};
