import { Box, Container, styled } from '@mui/material';

export const LayoutContainer = styled(Container)({
  position: 'relative',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'hidden',
  paddingInline: 0,
});

export const LayoutWrapper = styled(Box)`
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-inline: 16px;
  position: relative;
`;
