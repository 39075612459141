import { Box, Button, Fade, Typography, styled } from '@mui/material';

import { THEME } from '../../constants';
import { transientOptions } from '../../utils';

export const MarketplacePageContainer = styled(Box)`
  display: flex;
  gap: 16px;
  margin-top: 12px;
  flex-direction: column;
  flex: 1;
`;

export const MarketplacePageTitle = styled(Typography)`
  text-transform: uppercase;
  margin-bottom: 4px;
`;

export const TabsControl = styled(Box)`
  display: flex;
  gap: 6px;
  height: 39px;
  position: relative;
  background-color: ${({ theme }) => theme.palette.darkGrey.main};
  border-radius: ${THEME.BORDER_RADIUS.PILL};
  padding: 2px;
  margin-bottom: 10px;

  &::before {
    content: '';
    top: 0;
    right: 0;
    border-radius: ${THEME.BORDER_RADIUS.PILL};
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    border: 1px solid ${({ theme }) => theme.palette.darkGrey.light};
  }
`;

type TabButtonType = {
  $isActive: boolean;
};

export const TabButton = styled(Button, transientOptions)<TabButtonType>`
  display: flex;
  justify-content: center;
  flex: 1;
  height: 35px;
  color: ${({ $isActive, theme }) =>
    $isActive ? theme.palette.black.main : theme.palette.dimGrey.main};

  &:disabled {
    color: ${THEME.COLORS.DIM_GREY_MAIN};
    &::before {
      border-color: ${THEME.COLORS.DIM_GREY_MAIN};
    }
  }

  &::before {
    content: ${({ $isActive }) => ($isActive ? 'none' : '')};
    border-color: ${({ theme }) => theme.palette.dimGrey.main};
  }
`;

type StyledFadeType = {
  $isShow: boolean;
};

export const StyledFade = styled(Fade, transientOptions)<StyledFadeType>`
  display: ${({ $isShow }) => ($isShow ? 'block' : 'none')};
  flex: 1;
`;

// -------------

export const ButtonContainer = styled(Box)`
  display: flex;
  gap: 26px;
  margin-inline: auto;
`;

export const EditButtonText = styled(Typography)`
  font-size: 16px;
  margin-left: 20px;
`;

export const EditButtonIconWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: ${THEME.BORDER_RADIUS.PILL};
  background-color: ${({ theme }) => theme.palette.black.main};
`;

export const CurrencyDataWrapper = styled(Box)(() => ({
  width: '100%',
  height: '56px',
  marginBlock: '16px',
  display: 'flex',
  gap: '24px',
}));
