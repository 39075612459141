import { ReactNode } from 'react';
import { Fade } from '@mui/material';

import {
  GameInfoItemContainer,
  GameInfoItemContent,
  GameInfoItemIconWrapper,
  GameInfoItemLabel,
  GameInfoItemValue,
  GameInfoWrapper,
} from './GameInfo.styled';
import { CategoryIcon, NumericalStarIcon, VoiceCircleIcon } from '../icons';

type GameInfoItemType = {
  minWidth?: string;
  label: string;
  value: string | number;
  icon: ReactNode;
};

const GameInfoItem = ({ icon, label, value, minWidth }: GameInfoItemType) => {
  return (
    <GameInfoItemContainer minWidth={minWidth}>
      {icon}
      <GameInfoItemContent>
        <GameInfoItemLabel noWrap>{label}</GameInfoItemLabel>

        <GameInfoItemValue noWrap>{value}</GameInfoItemValue>
      </GameInfoItemContent>
    </GameInfoItemContainer>
  );
};

type GameInfoType = {
  blocksValue: string;
  nextRoundInValue: string | number;
  botsValue: string | number;
  className?: string;
};

export const GameInfo = ({
  blocksValue,
  nextRoundInValue,
  botsValue,
  className,
}: GameInfoType) => {
  return (
    <>
      <Fade in appear>
        <GameInfoWrapper className={className}>
          <GameInfoItem
            icon={
              <GameInfoItemIconWrapper minWidth='11px'>
                <CategoryIcon />
              </GameInfoItemIconWrapper>
            }
            label='Blocks:'
            value={blocksValue}
          />

          <GameInfoItem
            icon={
              <GameInfoItemIconWrapper minWidth='13px'>
                <NumericalStarIcon />
              </GameInfoItemIconWrapper>
            }
            label='Next round in:'
            value={nextRoundInValue}
          />

          <GameInfoItem
            icon={
              <GameInfoItemIconWrapper minWidth='14px'>
                <VoiceCircleIcon />
              </GameInfoItemIconWrapper>
            }
            label='Bots:'
            value={botsValue}
          />
        </GameInfoWrapper>
      </Fade>
    </>
  );
};
