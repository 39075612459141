import { Box, Input, styled } from '@mui/material';

export const StyledInputContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

export const StyledInput = styled(Input)`
  width: 100%;
  padding-bottom: 4px;

  &::before {
    border-color: ${({ theme }) => theme.palette.dimGrey.main};
  }

  &:hover:not(.Mui-disabled, .Mui-error):before {
    border-color: ${({ theme }) => theme.palette.dimGrey.main};
  }

  &::placeholder {
    color: ${({ theme }) => theme.palette.dimGrey.main};
  }

  &::after {
    content: none;
  }
`;
