import { styled, Box, Typography } from '@mui/material';

export const BotStatisticsInfoContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 4px;
  border: 1px solid ${({ theme }) => theme.palette.darkGrey.light};
  border-radius: 38px;
  max-height: 134px;
`;

export const BotStatisticsInfoHeader = styled(Box)`
  display: flex;
  align-items: end;
  padding: 16px 8px 8px 16px;
  font-size: 24px;
  line-height: 24px;
  font-weight: 600;
  text-align: end;

  &::before {
    content: '#';
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
    margin-right: 2px;
    margin-bottom: 2px;
  }

  &::after {
    content: 'PUMP/block:';
    margin-left: 2px;
    margin-bottom: 2px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
  }
`;

export const BotStatisticsInfoHeaderScore = styled(Typography)`
  display: flex;
  align-items: end;
  margin-left: 12px;
  font-size: 24px;
  font-weight: 600;
  text-align: end;
  line-height: 24px;
`;

export const BotStatisticsInfoContent = styled(Box)`
  display: flex;
  padding: 12px 26px;
  position: relative;
  gap: 26px;
`;

export const BotStatisticsInfoLine = styled(Box)`
  width: 1px;
  height: 100%;
  background: ${({ theme }) => theme.palette.darkGrey.light};
`;

export const BotStatisticsInfoItem = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 6px;
  flex: 1;
`;

export const BotStatisticsInfoLabel = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
`;

export const BotStatisticsInfoValue = styled(Typography)`
  display: flex;
  align-items: end;
  gap: 2px;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
`;

export const BotStatisticsInfoSubValue = styled(Box)`
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  margin-bottom: 2px;
`;
