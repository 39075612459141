import { TopPerformer } from './components/TopPerformer/TopPerformer';
import { ModalLayout, ModalLayoutHeader } from '../../Layout';
import { GameModalHeader } from '../../../data-models/enums';
import { useLeaderBoardListQuery } from '../../../redux/api';
import { TYPE_MODAL } from '../../../constants';
import { StyledList } from './TopPerformersModal.styled';
import { WrappedCircularProgress } from '../../WrappedCircularProgress/WrappedCircularProgress';

export const TopPerformersModal = () => {
  const { data, isLoading } = useLeaderBoardListQuery();

  return (
    <ModalLayout
      modalType={TYPE_MODAL.HOME_TOP_PERFORMERS}
      minHeight='610px'
      titles={
        <ModalLayoutHeader
          variant={GameModalHeader.game}
          title='TOP PERFORMERS'
          subtitle='Description'
        />
      }
    >
      {isLoading && <WrappedCircularProgress />}

      {data && (
        <StyledList>
          {data?.map((data, index) => (
            <TopPerformer
              key={data.strategyId}
              listItemNum={index + 1}
              data={data}
            />
          ))}
        </StyledList>
      )}
    </ModalLayout>
  );
};
