import { GasIcon } from '../../../../components/icons';
import {
  StyledPriceBlock,
  StyledPriceText,
  BuyText,
  PriceWrapper,
} from './PriceBlock.styled';

type BuyButtonType = {
  className?: string;
  price: string | number;
};

export const PriceBlock = ({ price, className }: BuyButtonType) => {
  return (
    <StyledPriceBlock className={className}>
      <BuyText>Buy for</BuyText>
      <PriceWrapper>
        <GasIcon size='20px' />
        <StyledPriceText variant='button'>{price}</StyledPriceText>
      </PriceWrapper>
    </StyledPriceBlock>
  );
};
