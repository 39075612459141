import React, { useState } from 'react';
import { CircularProgress } from '@mui/material';

import { StyledButton } from './ProfileAuthButton.styled';

import { UserAuthData, UserDataUI } from '../../../../data-models';
import { ProfileInfo } from '../ProfileInfo/ProfileInfo';

type ProfileAuthButtonType = {
  onLogin?: () => Promise<void | undefined | UserAuthData>;
  onLogout?: () => Promise<void | undefined>;
  icon: React.ReactNode;
  title: string;
  authData?: UserDataUI;
};

export const ProfileAuthButton = ({
  icon,
  title,
  onLogin,
  onLogout,
  authData,
}: ProfileAuthButtonType) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleClick = () => {
    if (!authData && onLogin) {
      setIsLoading(true);
      onLogin().finally(() => setIsLoading(false));
      return;
    }

    if (authData && onLogout) {
      setIsLoading(true);
      onLogout().finally(() => setIsLoading(false));
    }
  };

  return (
    <StyledButton
      color='darkGrey'
      variant='contained'
      startIcon={!authData && !isLoading ? icon : null}
      endIcon={authData && !isLoading ? icon : null}
      size='medium'
      onClick={handleClick}
      disabled={isLoading}
    >
      {isLoading && (
        <div
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          <CircularProgress />
        </div>
      )}

      {!isLoading && (
        <>
          {authData && <ProfileInfo data={authData as UserAuthData} />}
          {!authData && title}
        </>
      )}
    </StyledButton>
  );
};
