import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import { Switch } from '@mui/material';
import { PumpSwitch } from './PumpSwitch';

interface FormTextFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends UseControllerProps<TFieldValues, TFieldName> {
  isPumpDumpSwitch?: boolean;
  switchLabel?: {
    active?: string;
    inactive?: string;
  };
  isColored?: boolean;
}

export function ControllerSwitch<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  isPumpDumpSwitch,
  switchLabel,
  isColored = true,
}: FormTextFieldProps<TFieldValues, TFieldName>) {
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
  });

  if (isPumpDumpSwitch)
    return (
      <PumpSwitch
        name={name}
        checked={value}
        switchLabel={switchLabel}
        isColored={isColored}
        onChange={(val) => onChange(val)}
      />
    );

  return (
    <Switch
      color='secondary'
      name={name}
      checked={value}
      onChange={(event, val) => onChange(val)}
    />
  );
}
