import { THEME } from '../../constants';
import { Colors } from '../../data-models';

type SolarSmileIconType = {
  color?: Colors;
  width?: number;
  height?: number;
};

export const SolarSmileIcon = ({
  color = THEME.COLORS.BLACK_MAIN,
  width = 24,
  height = 25,
}: SolarSmileIconType) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9 16.9671C9.85 17.5971 10.885 17.9671 12 17.9671C13.115 17.9671 14.15 17.5971 15 16.9671'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M15 12.9671C15.5523 12.9671 16 12.2955 16 11.4671C16 10.6387 15.5523 9.9671 15 9.9671C14.4477 9.9671 14 10.6387 14 11.4671C14 12.2955 14.4477 12.9671 15 12.9671Z'
        fill={color}
      />
      <path
        d='M9 12.9671C9.55228 12.9671 10 12.2955 10 11.4671C10 10.6387 9.55228 9.9671 9 9.9671C8.44772 9.9671 8 10.6387 8 11.4671C8 12.2955 8.44772 12.9671 9 12.9671Z'
        fill={color}
      />
      <path
        d='M22 12.9671C22 17.6811 22 20.0381 20.535 21.5021C19.072 22.9671 16.714 22.9671 12 22.9671C7.286 22.9671 4.929 22.9671 3.464 21.5021C2 20.0391 2 17.6811 2 12.9671C2 8.2531 2 5.8961 3.464 4.4311C4.93 2.9671 7.286 2.9671 12 2.9671C16.714 2.9671 19.071 2.9671 20.535 4.4311C21.509 5.4051 21.835 6.7741 21.945 8.9671'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  );
};
