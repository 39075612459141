/* eslint-disable @typescript-eslint/ban-ts-comment */
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';
import Cookies from 'js-cookie';

import { authActions } from '../reducers/authSlice';
import { CLIENT_ENDPOINTS } from '../../constants';
import { getRefreshToken, getUserId } from '../../utils';

const mutex = new Mutex();

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_HOST,
  credentials: 'include',
});

export const client: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();

  let result = await baseQuery(args, api, extraOptions);
  const refreshToken = getRefreshToken();

  const handleRemoveCookie = () => {
    Cookies.remove('pnd', ['localhost', 'api.dev.pumpndump.io']);
  };

  const handleUserAutoLogin = async () => {
    const userId = getUserId();
    //@ts-ignore
    const telegramData = window?.Telegram?.WebApp?.initDataUnsafe;
    const telegramUsername = telegramData?.user?.username || userId;

    const refreshResult = await baseQuery(
      {
        url: CLIENT_ENDPOINTS.AUTH_SING_IN,
        method: 'POST',
        body: {
          username: telegramUsername,
          password: telegramUsername,
        },
        responseHandler: (response) => response.text(),
      },
      api,
      extraOptions,
    );

    if (refreshResult.data) {
      api.dispatch(authActions.loginClient(refreshResult.data));
    } else {
      api.dispatch(authActions.logoutClient());
    }
  };

  if (
    result.error &&
    (result.error.status === 401 || result.error.status === 403)
  ) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        if (refreshToken) {
          const refreshResult = await baseQuery(
            {
              url: CLIENT_ENDPOINTS.REFRESH_TOKEN,
              method: 'POST',
              body: { token: refreshToken },
            },
            api,
            extraOptions,
          );

          if (refreshResult.data) {
            result = await baseQuery(args, api, extraOptions);
          } else {
            console.log('Token expired');
            api.dispatch(authActions.logoutClient());
            localStorage.removeItem('refreshToken');
            handleRemoveCookie();
            handleUserAutoLogin();
          }
        } else {
          handleUserAutoLogin();
        }
        result = await baseQuery(args, api, extraOptions);
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};
