import { useState } from 'react';
import {
  CenteredModal,
  ConfirmationText,
  ConfirmationTextWrapper,
  ControlButton,
  ModalCloseButton,
  ModalContent,
  ModalContentWrapper,
  ModalControlButtonsWrapper,
  ModalHeaderContainer,
  ModalHeaderText,
  WhiteBorderWrapper,
} from './MyBotEditPage.styled';
import { CloseIcon } from '../../components/icons';
import { useDeleteBotMutation } from '../../redux/api';
import { ROUTES } from '../../constants';
import { useNavigate } from 'react-router';
import { enqueueSnackbar } from 'notistack';

interface DeleteBotModalProps {
  botData: {
    id: number;
    botName: string;
  };
}

export const DeleteBotModal = ({ botData }: DeleteBotModalProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const [deleteBot] = useDeleteBotMutation();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteBot = () => {
    deleteBot({
      name: botData?.botName,
      id: botData?.id,
    })
      .unwrap()
      .catch(() => {
        enqueueSnackbar('Error occured while deleting bot.', {
          variant: 'error',
        });
      })
      .finally(() => {
        navigate(ROUTES.MY_BOTS, { replace: true });
        enqueueSnackbar('Bot deleted successfully.', {
          variant: 'success',
        });
      });
  };

  return (
    <div style={{ width: '100%' }}>
      <ControlButton variant='outlinedError' onClick={handleOpen}>
        Delete bot
      </ControlButton>
      <CenteredModal
        open={open}
        onClose={handleClose}
        aria-labelledby='centered-modal-title'
        aria-describedby='centered-modal-description'
      >
        <ModalContent>
          <WhiteBorderWrapper />
          <ModalHeaderContainer>
            <ModalHeaderText>Delete bot</ModalHeaderText>
            <ModalCloseButton onClick={handleClose}>
              <CloseIcon />
            </ModalCloseButton>
          </ModalHeaderContainer>
          <ModalContentWrapper>
            <ConfirmationTextWrapper>
              <ConfirmationText>You are about to delete:</ConfirmationText>
              <ConfirmationText>{botData?.botName}</ConfirmationText>
              <ConfirmationText>Please confirm.</ConfirmationText>
            </ConfirmationTextWrapper>
            <ModalControlButtonsWrapper>
              <ControlButton onClick={handleClose} variant='outlinedInfo'>
                Cancel
              </ControlButton>
              <ControlButton variant='outlinedError' onClick={handleDeleteBot}>
                Confirm
              </ControlButton>
            </ModalControlButtonsWrapper>
          </ModalContentWrapper>
        </ModalContent>
      </CenteredModal>
    </div>
  );
};
