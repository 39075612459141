import { Stack } from '@mui/material';
import { LinearProgressCustom, ProgressBarTitle } from './ProgressBar.styled';
import { useProgress } from '../../hooks/useProgress';

type ProgressBarPropsType = {
  title: string;
  rtl: boolean;
  startTime?: string;
  endTime?: string;
  isActiveBattle?: boolean;
};

export const ProgressBar = ({
  title,
  rtl = false,
  startTime,
  endTime,
  isActiveBattle,
}: ProgressBarPropsType) => {
  const progressValue = useProgress({
    startTime: startTime,
    endTime: endTime,
    isActiveBattle,
  });

  return (
    <Stack sx={{ position: 'relative', width: '312px', height: '24px' }}>
      <LinearProgressCustom
        variant='determinate'
        value={progressValue}
        rtl={rtl ? 1 : 0}
      />
      <ProgressBarTitle variant='h3'>{title}</ProgressBarTitle>
    </Stack>
  );
};
