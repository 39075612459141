import { createSlice } from '@reduxjs/toolkit';

import {
  initFacebookThunk,
  loginWihFacebook,
  logoutFromFacebook,
} from '../asyncThunks/facebookThunk';
import { FacebookAuthData, UserData } from '../../data-models';

export type AuthSliceState = {
  isFbInitialized: boolean;
  clientAuth?: UserData;
  facebookProfile?: FacebookAuthData;
};

const initialState: AuthSliceState = {
  isFbInitialized: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginClient: (state, action) => {
      state.clientAuth = action.payload;
    },
    logoutClient: (state) => {
      state.clientAuth = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initFacebookThunk.fulfilled, (state) => {
      state.isFbInitialized = true;
    });

    builder.addCase(loginWihFacebook.fulfilled, (state, action) => {
      state.facebookProfile = {
        avatarUrl: action.payload.avatarUrl,
        fullName: action.payload.name,
        accessToken: action.payload.accessToken || '',
      };
    });

    builder.addCase(logoutFromFacebook.fulfilled, (state) => {
      state.facebookProfile = undefined;
    });
  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
