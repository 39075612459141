export const TickIcon = () => {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Tick Circle'>
        <g id='Vector'>
          <path
            d='M16.5588 8.50027C16.8351 8.19167 16.8089 7.71752 16.5003 7.44123C16.1917 7.16493 15.7175 7.19113 15.4412 7.49973L12.0721 11.2629C11.3894 12.0254 10.9296 12.5363 10.5365 12.8667C10.1621 13.1814 9.94213 13.25 9.75 13.25C9.55787 13.25 9.33794 13.1814 8.96348 12.8667C8.5704 12.5363 8.11064 12.0254 7.42794 11.2629L6.55877 10.2921C6.28248 9.98349 5.80833 9.9573 5.49973 10.2336C5.19113 10.5099 5.16493 10.984 5.44123 11.2926L6.34751 12.3049C6.98337 13.0152 7.51374 13.6076 7.99835 14.0149C8.51099 14.4458 9.06393 14.75 9.75 14.75C10.4361 14.75 10.989 14.4458 11.5016 14.0149C11.9862 13.6076 12.5166 13.0152 13.1524 12.305L16.5588 8.50027Z'
            fill='white'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M11 0.25C5.06294 0.25 0.25 5.06294 0.25 11C0.25 16.9371 5.06294 21.75 11 21.75C16.9371 21.75 21.75 16.9371 21.75 11C21.75 5.06294 16.9371 0.25 11 0.25ZM1.75 11C1.75 5.89137 5.89137 1.75 11 1.75C16.1086 1.75 20.25 5.89137 20.25 11C20.25 16.1086 16.1086 20.25 11 20.25C5.89137 20.25 1.75 16.1086 1.75 11Z'
            fill='white'
          />
        </g>
      </g>
    </svg>
  );
};
