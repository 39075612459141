import * as yup from 'yup';
import { FORM_ERRORS } from '../../../constants';

const MIN_SELL_PRICE = 1;

export const sellBotModalValidationSchema = yup.object({
  sellPrice: yup
    .number()
    .required(FORM_ERRORS.MIN_AMOUNT('Sell price', MIN_SELL_PRICE))
    .min(MIN_SELL_PRICE, FORM_ERRORS.MIN_AMOUNT('Sell price', MIN_SELL_PRICE)),
});

export type SellBotValidationType = yup.InferType<
  typeof sellBotModalValidationSchema
>;
