import { alpha, Box, Button, styled, Typography } from '@mui/material';
import { ModalLayout } from '../../Layout';

export const StyledModalLayout = styled(ModalLayout)`
  padding-block: 25px 28px;
`;

export const InputWrapper = styled(Box)`
  position: relative;
`;

export const PumpText = styled(Box)`
  transform: rotate(-90deg);
  margin-left: 6%;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
`;

export const ActionButton = styled(Button)`
  margin-top: 26px;
  display: flex;
  justify-content: space-between;
  padding: 4px 4px 4px 26px;
`;

export const ActionButtonText = styled(Typography)`
  display: flex;
  font-size: 32px;
  font-weight: 600;
`;

export const ActionDecorationWrapper = styled(Box)`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 26px;
  color: ${({ theme }) => theme.palette.white.main};
  border-radius: 36px;
  background: ${({ theme }) => alpha(theme.palette.black.main, 0.79)};
  backdrop-filter: blur(3px);
`;

export const ActionDecorationTitle = styled(Typography)`
  max-width: 200px;
`;
