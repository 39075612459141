import { ShopItemData } from '../../../../data-models';
import {
  GasTabCardAmount,
  GasTabCardAmountContainer,
  GasTabCardContainer,
  StyledGasCardMarker,
} from './GasTab.styled';
import { PumpCurrencyCoinIcon } from '../../../../components/icons';
import { PriceBlock } from '../PriceBlock/PriceBlock';

type GasCardType = {
  isInModal?: boolean;
  onClick?: any;
  data: ShopItemData;
  position: number;
};

export const GasCard = ({
  data,
  position,
  onClick,
  isInModal = false,
}: GasCardType) => {
  return (
    <GasTabCardContainer onClick={onClick} isInModal={isInModal}>
      {!isInModal && position < 2 && <StyledGasCardMarker title='HOT' />}

      <GasTabCardAmountContainer>
        <div style={{ display: 'flex' }}>
          <PumpCurrencyCoinIcon size='42px' />
        </div>

        <GasTabCardAmount>
          {data.listingItems[0].assetQuantity}
        </GasTabCardAmount>
      </GasTabCardAmountContainer>
      {!isInModal && <PriceBlock price={data?.priceTag?.price} />}
    </GasTabCardContainer>
  );
};
