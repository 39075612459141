import { useTheme, alpha } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import { ModalLayoutHeader } from '../../Layout';
import { TYPE_MODAL } from '../../../constants';
import { GameModalHeader } from '../../../data-models/enums';
import {
  BubbleChartWrapper,
  StyledGameInfo,
  StyledModalLayout,
  TestRunDataValues,
} from './TestBotModal.styled';
import { useModalContext } from '../../../context';
import { GameBubbleChart } from '../../../pages/HomePage/components/GameBubbleChart/GameBubbleChart';
import { GameDataValue } from '../../GameDataValue/GameDataValue';
import { StarIcon } from '../../icons';
import { useGameBubbleData } from '../../../hooks';

export const TestBotModal = () => {
  const theme = useTheme();
  const chartContainerRef = useRef<HTMLDivElement>(null);

  const { modalParameters } = useModalContext();
  const [gameChartSize, setGameChartSize] = useState<{
    width: number;
    height: number;
  } | null>(null);

  const { data: bubbleData } = useGameBubbleData(
    gameChartSize?.width,
    gameChartSize?.height,
  );

  const isOpen =
    modalParameters?.type === TYPE_MODAL.TEST_BOT && modalParameters?.isOpen;

  useEffect(() => {
    if (!chartContainerRef.current) return;

    setGameChartSize({
      height: chartContainerRef.current.offsetHeight,
      width: chartContainerRef.current.offsetWidth,
    });
  }, [chartContainerRef.current]);

  return (
    <StyledModalLayout
      modalType={TYPE_MODAL.BUY_BOT}
      titles={
        <ModalLayoutHeader
          variant={GameModalHeader.testBot}
          title='TEST RUN'
          subtitle='BUY BOT'
        />
      }
      minHeight='546px'
    >
      {isOpen && (
        <BubbleChartWrapper ref={chartContainerRef}>
          <TestRunDataValues>
            {/* <GameDataValue
              backgroundColor={alpha(theme.palette.black.main, 0.7)}
              icon={<GasIcon color={theme.palette.black.main} />}
              value={100}
            /> */}
            <GameDataValue
              backgroundColor={alpha(theme.palette.black.main, 0.7)}
              icon={
                <StarIcon variant='fill' color={theme.palette.black.main} />
              }
              value={999.999}
            />
          </TestRunDataValues>

          <StyledGameInfo blocksValue='0' botsValue={0} nextRoundInValue={0} />

          {gameChartSize && (
            <GameBubbleChart
              width={gameChartSize.width}
              height={gameChartSize.height}
              isMarkTop={false}
              data={bubbleData}
            />
          )}
        </BubbleChartWrapper>
      )}
    </StyledModalLayout>
  );
};
