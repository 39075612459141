import { useEffect } from 'react';
import { GameInfoBlock } from '../../components/GameDataValue/GameInfoBlock';
import { GasIcon, PumpCurrencyCoinIcon } from '../../components/icons';
import { BalanceAssetType } from '../../data-models/enums';
import { useLazyBalancesQuery, useMissionsListQuery } from '../../redux/api';
import { CurrencyDataWrapper } from '../MarketplacePage/MarketplacePage.styled';
import {
  MissionsContainer,
  MissionsHeader,
  MissionsTitleContainer,
  MissionsSubtitle,
  MissionsTitle,
  MissionListWrapper,
  ProgressWrapper,
} from './MissionsPage.styled';
import { MissionCard } from './MissionCard';
import { CircularProgress } from '@mui/material';

export const MissionsPage = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [balancesTrigger, { data: balances }] = useLazyBalancesQuery({
    pollingInterval: 5000,
  });
  const { data: missionsData, isLoading } = useMissionsListQuery();

  useEffect(() => {
    balancesTrigger();
  }, []);

  const gasValue = balances?.find(
    (balance) => balance.assetType === BalanceAssetType.gas,
  );

  const coinValue = balances?.find(
    (balance) => balance.assetType === BalanceAssetType.coin,
  );

  return (
    <MissionsContainer>
      <CurrencyDataWrapper>
        <GameInfoBlock
          icon={<GasIcon />}
          infoValue={coinValue?.amount || '...'}
          isCoinBalance
        />
        <GameInfoBlock
          icon={<PumpCurrencyCoinIcon />}
          infoValue={gasValue?.amount || '...'}
          isGasBalance
        />
      </CurrencyDataWrapper>
      <MissionsHeader>
        <MissionsTitleContainer>
          <MissionsTitle variant='h1'>Missions</MissionsTitle>
          <MissionsSubtitle variant='subtitle1'>
            COMPLETE MISSIONS TO EARN $PUMPs
          </MissionsSubtitle>
        </MissionsTitleContainer>
      </MissionsHeader>
      {isLoading ? (
        <ProgressWrapper>
          <CircularProgress />
        </ProgressWrapper>
      ) : (
        <MissionListWrapper>
          {missionsData?.map((mission) => (
            <MissionCard mission={mission} key={mission?.missionEntity?.id} />
          ))}
        </MissionListWrapper>
      )}
    </MissionsContainer>
  );
};
