import { Box, styled } from '@mui/material';

import { ModalLayout } from '../../Layout';
import { GameInfo } from '../../GameInfo/GameInfo';

export const StyledModalLayout = styled(ModalLayout)`
  padding-block: 25px 28px;
  position: relative;
  background-color: transparent;
  overflow-y: hidden;
`;

export const BubbleChartWrapper = styled(Box)`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const TestRunDataValues = styled(Box)`
  display: flex;
  justify-content: center;
  gap: 16px;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
`;

export const StyledGameInfo = styled(GameInfo)`
  gap: 16px;
  position: absolute;
  bottom: 16px;
  left: 14px;
  right: 14px;
`;
