import { createApi } from '@reduxjs/toolkit/query/react';
import omit from 'lodash/omit';

import { client } from './client';
import { authActions } from '../reducers/authSlice';
import { CLIENT_ENDPOINTS, LOCAL_STORAGE_KEYS } from '../../constants';
import { UserData, UserAuthData, UserDataResponse } from '../../data-models';
import { setRefreshToken } from '../../utils';
import { serviceActions } from '../reducers/serviceSlice';

export const authAPI = createApi({
  reducerPath: 'authApi',
  keepUnusedDataFor: 5,
  baseQuery: client,
  endpoints: (builder) => ({
    signIn: builder.mutation<
      UserAuthData,
      { username: string; password: string }
    >({
      query: ({ username, password }) => ({
        url: CLIENT_ENDPOINTS.AUTH_SING_IN,
        method: 'POST',
        body: {
          username,
          password,
        },
      }),
      transformResponse: (response: UserAuthData) => {
        return {
          ...omit(response, 'fullname'),
          fullName: response?.user?.username,
        };
      },
      onQueryStarted: (arg, api) => {
        const { dispatch, queryFulfilled } = api;

        queryFulfilled
          .then(({ data }) => {
            dispatch(authActions.loginClient(data));
            setRefreshToken(data?.refreshToken || '');
            api?.dispatch(serviceActions.setIsFirstBalanceRequest(true));
          })
          .catch(() => {});
      },
    }),

    signOut: builder.mutation<undefined, void>({
      query: () => ({
        url: CLIENT_ENDPOINTS.AUTH_SING_OUT,
        method: 'POST',
        responseHandler: (response) => response.text(),
      }),

      onQueryStarted: (arg, api) => {
        const { dispatch, queryFulfilled } = api;

        queryFulfilled
          .then(() => {
            localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_UUID);
            dispatch(authActions.logoutClient());
          })
          .catch(() => {});
      },
    }),

    bffProfile: builder.query<UserData, void>({
      query: () => ({
        url: CLIENT_ENDPOINTS.BFF_PROFILE,
        method: 'GET',
      }),
      transformResponse: (response: UserDataResponse) => {
        return {
          ...omit(response, 'fullname'),
          fullName: response.username,
        };
      },
      onQueryStarted: (arg, api) => {
        const { dispatch, queryFulfilled } = api;

        queryFulfilled
          .then(({ data }) => {
            dispatch(authActions.loginClient(data));
          })
          .catch(() => {});
      },
    }),
  }),
});

export const {
  useSignOutMutation,
  useSignInMutation,
  useBffProfileQuery,
  useLazyBffProfileQuery,
} = authAPI;
