import React, { useEffect, useState } from 'react';
import { Fade, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  LayoutContainer,
  LayoutWrapper,
} from '../BaseLayout/BaseLayout.styled';
import { ROUTES } from '../../../constants';
import { ArrowLeftIcon } from '../../icons';
import {
  BackButton,
  HeaderContainer,
  TitleContainer,
} from './MyBotsCreateEditLayout.styled';
import { FaqModal } from '../../Modal/FaqModal/FaqModal';

type MyBotsCreateEditType = {
  children: React.ReactNode;
  title: string;
  isShowFaq?: boolean;
};

export const MyBotsCreateEditLayout = ({
  children,
  title,
  isShowFaq,
}: MyBotsCreateEditType) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState<string>();

  useEffect(() => {
    if (location.pathname !== displayLocation) {
      setDisplayLocation(location.pathname);
    }
  }, [location.pathname]);

  const handleNavigateBack = () => navigate(ROUTES.MY_BOTS);

  return (
    <LayoutContainer maxWidth='sm'>
      <Fade
        in={location.pathname === displayLocation}
        timeout={{ exit: 0, enter: 500 }}
      >
        <LayoutWrapper>
          <HeaderContainer>
            <BackButton
              variant='contained'
              color='darkGrey'
              size='medium'
              onClick={handleNavigateBack}
            >
              <ArrowLeftIcon />
            </BackButton>

            <TitleContainer>
              <Typography variant='h1'>{title}</Typography>

              {isShowFaq && <FaqModal />}
            </TitleContainer>
          </HeaderContainer>

          {children}
        </LayoutWrapper>
      </Fade>
    </LayoutContainer>
  );
};
