import { RootState } from '../store';

export const getCoinProfitDelta = (state: RootState) =>
  state.serviceReducer?.profitCoinDelta;

export const getGasProfitDelta = (state: RootState) =>
  state.serviceReducer?.profitGasDelta;

export const getIsFirstLottieRender = (state: RootState) =>
  state.serviceReducer?.isFirstLottieRender;
