export enum UiGameBotType {
  owner = 'owner',
  first = 'first',
  second = 'second',
  third = 'third',
  another = 'another',
}

export enum BalanceAssetType {
  coin = 'COIN',
  gas = 'GAS',
}
