export const ArrowLeftIcon = () => {
  return (
    <svg
      width='6'
      height='12'
      viewBox='0 0 6 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Left 2'>
        <path
          id='Vector'
          d='M5.52796 1.53269C5.82216 1.24111 5.82427 0.766238 5.53269 0.472041C5.24111 0.177844 4.76624 0.175726 4.47204 0.467309L2.67731 2.2461C2.00134 2.91604 1.44886 3.46359 1.05706 3.95146C0.649628 4.45878 0.354695 4.97372 0.275909 5.59184C0.241364 5.86286 0.241364 6.13714 0.275909 6.40816C0.354695 7.02628 0.649628 7.54122 1.05706 8.04854C1.44886 8.53641 2.00133 9.08395 2.6773 9.75389L4.47204 11.5327C4.76624 11.8243 5.24111 11.8222 5.53269 11.528C5.82427 11.2338 5.82216 10.7589 5.52796 10.4673L3.76499 8.72C3.0495 8.01086 2.55869 7.52282 2.22659 7.10929C1.904 6.7076 1.79332 6.44958 1.76387 6.2185C1.74538 6.07341 1.74538 5.92659 1.76387 5.7815C1.79332 5.55042 1.904 5.2924 2.22659 4.89071C2.55869 4.47718 3.0495 3.98914 3.76499 3.28L5.52796 1.53269Z'
          fill='white'
        />
      </g>
    </svg>
  );
};
