import { Modal, styled, Box } from '@mui/material';

export const StyledModal = styled(Modal)`
  max-height: 100vh;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  align-items: end;
`;

export const StyledModalContainer = styled(Box)`
  height: 100%;
  display: flex;
  align-items: end;
  padding-block: 20px;
  outline: none;
`;
