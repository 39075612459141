export const CategoryIcon = () => {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.4302 0.114716C3.22713 0.10086 2.97977 0.100864 2.68347 0.100869H2.64979C2.35349 0.100864 2.10612 0.10086 1.90305 0.114716C1.69165 0.12914 1.49197 0.160235 1.29837 0.240424C0.849155 0.426496 0.492253 0.783398 0.306181 1.23262C0.225992 1.42621 0.194897 1.62589 0.180473 1.83729C0.166617 2.04037 0.166621 2.28772 0.166626 2.58403V2.6177C0.166621 2.91401 0.166617 3.16137 0.180473 3.36445C0.194897 3.57585 0.225992 3.77553 0.306181 3.96912C0.492253 4.41834 0.849155 4.77524 1.29837 4.96132C1.49197 5.0415 1.69165 5.0726 1.90305 5.08702C2.10612 5.10088 2.35348 5.10087 2.64979 5.10087H2.68346C2.97977 5.10087 3.22713 5.10088 3.4302 5.08702C3.6416 5.0726 3.84129 5.0415 4.03488 4.96132C4.4841 4.77524 4.841 4.41834 5.02707 3.96912C5.10726 3.77553 5.13836 3.57585 5.15278 3.36445C5.16664 3.16137 5.16663 2.91401 5.16663 2.61771V2.58403C5.16663 2.28772 5.16664 2.04037 5.15278 1.83729C5.13836 1.62589 5.10726 1.42621 5.02707 1.23262C4.841 0.783398 4.4841 0.426496 4.03488 0.240424C3.84129 0.160235 3.6416 0.12914 3.4302 0.114716ZM1.68106 1.1643C1.73249 1.143 1.81064 1.12335 1.97112 1.1124C2.1361 1.10114 2.34916 1.10087 2.66663 1.10087C2.9841 1.10087 3.19716 1.10114 3.36213 1.1124C3.52262 1.12335 3.60076 1.143 3.6522 1.1643C3.85639 1.24888 4.01861 1.41111 4.10319 1.6153C4.1245 1.66674 4.14415 1.74488 4.1551 1.90536C4.16636 2.07034 4.16663 2.2834 4.16663 2.60087C4.16663 2.91834 4.16636 3.1314 4.1551 3.29637C4.14415 3.45686 4.1245 3.535 4.10319 3.58644C4.01861 3.79063 3.85639 3.95286 3.6522 4.03744C3.60076 4.05874 3.52262 4.07839 3.36213 4.08934C3.19716 4.1006 2.9841 4.10087 2.66663 4.10087C2.34916 4.10087 2.1361 4.1006 1.97112 4.08934C1.81064 4.07839 1.73249 4.05874 1.68106 4.03744C1.47687 3.95286 1.31464 3.79063 1.23006 3.58644C1.20875 3.535 1.1891 3.45686 1.17815 3.29637C1.1669 3.1314 1.16663 2.91834 1.16663 2.60087C1.16663 2.2834 1.1669 2.07034 1.17815 1.90536C1.1891 1.74488 1.20875 1.66674 1.23006 1.6153C1.31464 1.41111 1.47687 1.24888 1.68106 1.1643Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.0969 0.114716C9.8938 0.10086 9.64644 0.100864 9.35014 0.100869H9.31646C9.02015 0.100864 8.77279 0.10086 8.56972 0.114716C8.35832 0.12914 8.15863 0.160235 7.96504 0.240424C7.51582 0.426496 7.15892 0.783398 6.97285 1.23262C6.89266 1.42621 6.86156 1.62589 6.84714 1.83729C6.83328 2.04037 6.83329 2.28772 6.83329 2.58403V2.61771C6.83329 2.91401 6.83328 3.16137 6.84714 3.36445C6.86156 3.57585 6.89266 3.77553 6.97285 3.96912C7.15892 4.41834 7.51582 4.77524 7.96504 4.96132C8.15863 5.0415 8.35832 5.0726 8.56972 5.08702C8.77279 5.10088 9.02015 5.10087 9.31645 5.10087H9.35013C9.64644 5.10087 9.8938 5.10088 10.0969 5.08702C10.3083 5.0726 10.508 5.0415 10.7015 4.96132C11.1508 4.77524 11.5077 4.41834 11.6937 3.96912C11.7739 3.77553 11.805 3.57585 11.8194 3.36445C11.8333 3.16137 11.8333 2.91401 11.8333 2.61771V2.58403C11.8333 2.28772 11.8333 2.04037 11.8194 1.83729C11.805 1.62589 11.7739 1.42621 11.6937 1.23262C11.5077 0.783398 11.1508 0.426496 10.7015 0.240424C10.508 0.160235 10.3083 0.12914 10.0969 0.114716ZM8.34772 1.1643C8.39916 1.143 8.4773 1.12335 8.63779 1.1124C8.80276 1.10114 9.01582 1.10087 9.33329 1.10087C9.65076 1.10087 9.86382 1.10114 10.0288 1.1124C10.1893 1.12335 10.2674 1.143 10.3189 1.1643C10.5231 1.24888 10.6853 1.41111 10.7699 1.6153C10.7912 1.66674 10.8108 1.74488 10.8218 1.90536C10.833 2.07034 10.8333 2.2834 10.8333 2.60087C10.8333 2.91834 10.833 3.1314 10.8218 3.29637C10.8108 3.45686 10.7912 3.535 10.7699 3.58644C10.6853 3.79063 10.5231 3.95286 10.3189 4.03744C10.2674 4.05874 10.1893 4.07839 10.0288 4.08934C9.86382 4.1006 9.65076 4.10087 9.33329 4.10087C9.01582 4.10087 8.80276 4.1006 8.63779 4.08934C8.4773 4.07839 8.39916 4.05874 8.34772 4.03744C8.14353 3.95286 7.9813 3.79063 7.89673 3.58644C7.87542 3.535 7.85577 3.45686 7.84482 3.29637C7.83356 3.1314 7.83329 2.91834 7.83329 2.60087C7.83329 2.2834 7.83356 2.07034 7.84482 1.90536C7.85577 1.74488 7.87542 1.66674 7.89673 1.6153C7.9813 1.41111 8.14353 1.24888 8.34772 1.1643Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.4302 6.78138C3.22713 6.76753 2.97977 6.76753 2.68346 6.76754H2.64979C2.35348 6.76753 2.10612 6.76753 1.90305 6.78138C1.69165 6.79581 1.49197 6.8269 1.29837 6.90709C0.849155 7.09316 0.492253 7.45007 0.306181 7.89928C0.225992 8.09288 0.194897 8.29256 0.180473 8.50396C0.166617 8.70703 0.166621 8.95439 0.166626 9.25069V9.28437C0.166621 9.58067 0.166617 9.82804 0.180473 10.0311C0.194897 10.2425 0.225992 10.4422 0.306181 10.6358C0.492253 11.085 0.849155 11.4419 1.29837 11.628C1.49197 11.7082 1.69165 11.7393 1.90305 11.7537C2.10612 11.7675 2.35348 11.7675 2.64979 11.7675H2.68347C2.97977 11.7675 3.22713 11.7675 3.4302 11.7537C3.6416 11.7393 3.84129 11.7082 4.03488 11.628C4.4841 11.4419 4.841 11.085 5.02707 10.6358C5.10726 10.4422 5.13836 10.2425 5.15278 10.0311C5.16664 9.82804 5.16663 9.58068 5.16663 9.28437V9.2507C5.16663 8.95439 5.16664 8.70703 5.15278 8.50396C5.13836 8.29256 5.10726 8.09288 5.02707 7.89928C4.841 7.45007 4.4841 7.09316 4.03488 6.90709C3.84129 6.8269 3.6416 6.79581 3.4302 6.78138ZM1.68106 7.83097C1.73249 7.80966 1.81064 7.79001 1.97112 7.77906C2.1361 7.76781 2.34916 7.76754 2.66663 7.76754C2.9841 7.76754 3.19716 7.76781 3.36213 7.77906C3.52262 7.79001 3.60076 7.80966 3.6522 7.83097C3.85639 7.91555 4.01861 8.07778 4.10319 8.28197C4.1245 8.3334 4.14415 8.41155 4.1551 8.57203C4.16636 8.73701 4.16663 8.95007 4.16663 9.26754C4.16663 9.585 4.16636 9.79806 4.1551 9.96304C4.14415 10.1235 4.1245 10.2017 4.10319 10.2531C4.01861 10.4573 3.85639 10.6195 3.6522 10.7041C3.60076 10.7254 3.52262 10.7451 3.36213 10.756C3.19716 10.7673 2.9841 10.7675 2.66663 10.7675C2.34916 10.7675 2.1361 10.7673 1.97112 10.756C1.81064 10.7451 1.73249 10.7254 1.68106 10.7041C1.47687 10.6195 1.31464 10.4573 1.23006 10.2531C1.20875 10.2017 1.1891 10.1235 1.17815 9.96304C1.1669 9.79806 1.16663 9.585 1.16663 9.26754C1.16663 8.95007 1.1669 8.73701 1.17815 8.57203C1.1891 8.41155 1.20875 8.3334 1.23006 8.28197C1.31464 8.07778 1.47687 7.91555 1.68106 7.83097Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.0969 6.78138C9.8938 6.76753 9.64644 6.76753 9.35013 6.76754H9.31645C9.02015 6.76753 8.77279 6.76753 8.56972 6.78138C8.35832 6.79581 8.15863 6.8269 7.96504 6.90709C7.51582 7.09316 7.15892 7.45006 6.97285 7.89928C6.89266 8.09288 6.86156 8.29256 6.84714 8.50396C6.83328 8.70703 6.83329 8.95439 6.83329 9.2507V9.28437C6.83329 9.58068 6.83328 9.82804 6.84714 10.0311C6.86156 10.2425 6.89266 10.4422 6.97285 10.6358C7.15892 11.085 7.51582 11.4419 7.96504 11.628C8.15863 11.7082 8.35832 11.7393 8.56972 11.7537C8.77279 11.7675 9.02015 11.7675 9.31645 11.7675H9.35013C9.64644 11.7675 9.8938 11.7675 10.0969 11.7537C10.3083 11.7393 10.508 11.7082 10.7015 11.628C11.1508 11.4419 11.5077 11.085 11.6937 10.6358C11.7739 10.4422 11.805 10.2425 11.8194 10.0311C11.8333 9.82804 11.8333 9.58068 11.8333 9.28437V9.2507C11.8333 8.95439 11.8333 8.70703 11.8194 8.50396C11.805 8.29256 11.7739 8.09288 11.6937 7.89928C11.5077 7.45006 11.1508 7.09316 10.7015 6.90709C10.508 6.8269 10.3083 6.79581 10.0969 6.78138ZM8.34772 7.83097C8.39916 7.80966 8.4773 7.79001 8.63779 7.77906C8.80276 7.76781 9.01582 7.76754 9.33329 7.76754C9.65076 7.76754 9.86382 7.76781 10.0288 7.77906C10.1893 7.79001 10.2674 7.80966 10.3189 7.83097C10.5231 7.91555 10.6853 8.07778 10.7699 8.28197C10.7912 8.3334 10.8108 8.41155 10.8218 8.57203C10.833 8.73701 10.8333 8.95007 10.8333 9.26754C10.8333 9.585 10.833 9.79806 10.8218 9.96304C10.8108 10.1235 10.7912 10.2017 10.7699 10.2531C10.6853 10.4573 10.5231 10.6195 10.3189 10.7041C10.2674 10.7254 10.1893 10.7451 10.0288 10.756C9.86382 10.7673 9.65076 10.7675 9.33329 10.7675C9.01582 10.7675 8.80276 10.7673 8.63779 10.756C8.4773 10.7451 8.39916 10.7254 8.34772 10.7041C8.14353 10.6195 7.98131 10.4573 7.89673 10.2531C7.87542 10.2017 7.85577 10.1235 7.84482 9.96304C7.83356 9.79806 7.83329 9.585 7.83329 9.26754C7.83329 8.95007 7.83356 8.73701 7.84482 8.57203C7.85577 8.41155 7.87542 8.3334 7.89673 8.28197C7.98131 8.07778 8.14353 7.91555 8.34772 7.83097Z'
        fill='white'
      />
    </svg>
  );
};
