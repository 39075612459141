import { useEffect, useState } from 'react';
import { Box, GlobalStyles } from '@mui/material';

import {
  MarketplacePageContainer,
  TabsControl,
  TabButton,
  MarketplacePageTitle,
  StyledFade,
  CurrencyDataWrapper,
} from './MarketplacePage.styled';
import { SkinsTab } from './components/SkinsTab/SkinsTab';
import { GasTab } from './components/GasTab/GasTab';
import imageURL from '../../assets/image/market-background.svg';
import { GameInfoBlock } from '../../components/GameDataValue/GameInfoBlock';
import { GasIcon, PumpCurrencyCoinIcon } from '../../components/icons';
import { BalanceAssetType } from '../../data-models/enums';
import { useLazyBalancesQuery } from '../../redux/api';

enum TabsTape {
  $PUMPs,
  SKINS,
  BOOSTERS,
}

const tabs = [
  {
    id: TabsTape.$PUMPs,
    title: '$PUMPs',
  },
  {
    id: TabsTape.SKINS,
    title: 'SKINS',
    isDisabled: true,
  },
  {
    id: TabsTape.BOOSTERS,
    title: 'BOOSTERS',
    isDisabled: true,
  },
];

const tabsContent = [
  {
    id: TabsTape.$PUMPs,
    Content: GasTab,
  },
  {
    id: TabsTape.SKINS,
    Content: SkinsTab,
  },
  {
    id: TabsTape.BOOSTERS,
    Content: Box,
  },
];

export const MarketplacePage = () => {
  const [activeTab, setActiveTab] = useState(TabsTape.$PUMPs);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [balancesTrigger, { data: balances }] = useLazyBalancesQuery({
    pollingInterval: 5000,
  });

  useEffect(() => {
    balancesTrigger();
  }, []);

  const gasValue = balances?.find(
    (balance) => balance.assetType === BalanceAssetType.gas,
  );

  const coinValue = balances?.find(
    (balance) => balance.assetType === BalanceAssetType.coin,
  );

  const handleChangeActiveTab = (id: TabsTape) => () => {
    setActiveTab(id);
  };
  return (
    <MarketplacePageContainer>
      <GlobalStyles
        styles={{
          body: {
            backgroundColor: '#0C090C',
            position: 'relative',
            '&:before': {
              position: 'absolute',
              content: '" "',
              display: 'block',
              width: '100%',
              height: '100%',
              backgroundImage: `url(${imageURL})`,
              backgroundSize: '100%',
              backgroundRepeat: 'no-repeat',
            },
          },
        }}
      />
      <CurrencyDataWrapper>
        <GameInfoBlock
          icon={<GasIcon />}
          infoValue={coinValue?.amount || '...'}
          isCoinBalance
        />
        <GameInfoBlock
          icon={<PumpCurrencyCoinIcon />}
          infoValue={gasValue?.amount || '...'}
          isGasBalance
        />
      </CurrencyDataWrapper>

      <MarketplacePageTitle variant='h1'>Marketplace</MarketplacePageTitle>
      <TabsControl>
        {tabs.map(({ id, title, isDisabled }) => (
          <TabButton
            $isActive={id === activeTab}
            variant='contained'
            color={id === activeTab ? 'primary' : 'darkGrey'}
            key={title}
            onClick={handleChangeActiveTab(id)}
            disabled={isDisabled}
          >
            {title}
          </TabButton>
        ))}
      </TabsControl>

      {tabsContent.map(({ Content, id }) => (
        <StyledFade $isShow={id === activeTab} in={id === activeTab} key={id}>
          <Box>
            <Content />
          </Box>
        </StyledFade>
      ))}
    </MarketplacePageContainer>
  );
};
