import { Box, styled, Typography } from '@mui/material';

import { CardMarker } from '../CardMarker/CardMarker';
import { transientOptions } from '../../../../utils';

type SkinsCardVarianType = {
  $isOwner?: boolean;
};

export const SkinsTabContainer = styled(Box)``;

export const SkinsTabCardContainer = styled(
  Box,
  transientOptions,
)<SkinsCardVarianType>`
  display: flex;
  flex-direction: column;
  border: 1px solid
    ${({ theme, $isOwner }) =>
      $isOwner ? theme.palette.white.main : theme.palette.darkGrey.light};
  background-color: ${({ theme, $isOwner }) =>
    $isOwner ? theme.palette.lime.main : theme.palette.darkGrey.main};
  color: ${({ theme, $isOwner }) =>
    $isOwner ? theme.palette.black.main : theme.palette.white.main};
  border-radius: 26px;
  padding: 4px;
  backdrop-filter: blur(3px);
  gap: 16px;
`;

export const SkinsTabCardHeader = styled(
  Box,
  transientOptions,
)<SkinsCardVarianType>`
  width: 100%;
  padding: 18px 14px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: -4px;
    right: -4px;
    bottom: 0;
    border-bottom: 1px solid
      ${({ theme, $isOwner }) =>
        $isOwner ? theme.palette.white.light : theme.palette.darkGrey.light};
  }
`;

export const SkinsTabCardTitle = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
`;

export const StyledBotCardMarker = styled(CardMarker)`
  position: absolute;
  top: -10px;
  right: -6px;
  transform: rotate(-26deg);
`;
