import { Box, Button, Typography, styled } from '@mui/material';

import { THEME } from '../../constants';

export const MyBotCreatePageContainer = styled(Box)`
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

export const CreateButton = styled(Button)`
  margin-inline: auto;
  max-width: 193px;
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.black.main};
  margin-top: 32px;
`;

export const CreateButtonText = styled(Typography)`
  font-size: 16px;
  margin-left: 20px;
`;

export const CreateButtonIconWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: ${THEME.BORDER_RADIUS.PILL};
  background-color: ${({ theme }) => theme.palette.black.main};
`;
