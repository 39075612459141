export const FORM_ERRORS = {
  REQUIRED: (fieldName: string) => `${fieldName} required`,
  MIN_AMOUNT: (fieldName: string, amount: number) =>
    `${fieldName} should be greater than zero ${amount - 1}`,
  MAX_AMOUNT: (fieldName: string, amount: number) =>
    `${fieldName} should be less ${amount + 1}`,
  MIN_PERCENT: (percent: number) => `Cannot be less than ${percent}.`,
  MAX_PERCENT: (percent: number) => `Must not exceed ${percent}%`,
  SHOULD_BE_UNIQUE: (fieldName: string) =>
    `${fieldName} should be unique among others`,
};
