import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import animationData from '../../../../assets/image/parachute.json';
import { useDispatch } from 'react-redux';
import { serviceActions } from '../../../../redux/reducers/serviceSlice';

const slideDown = keyframes`
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100vh);
  }
`;

const AnimatedDiv = styled('div')(() => ({
  position: 'absolute',
  animation: `${slideDown} 7s ease-in-out forwards`,
  animationDelay: '1s',
  zIndex: 50,
  opacity: 1,
  transition: 'opacity 1.75s ease-in-out',
}));

interface LottieAnimationProps {
  isAirdrop: boolean;
  setShowAnimation: Dispatch<SetStateAction<boolean>>;
}

const LottieAnimation = ({
  isAirdrop,
  setShowAnimation,
}: LottieAnimationProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const animationInstance = useRef<any>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (containerRef.current && !animationInstance.current) {
      animationInstance.current = lottie.loadAnimation({
        container: containerRef.current,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: animationData,
      });
    }

    return () => {
      if (animationInstance.current) {
        animationInstance.current.destroy();
        animationInstance.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (isAirdrop && animationInstance.current) {
      animationInstance.current.stop();
      animationInstance.current.goToAndPlay(0, true);
      dispatch(serviceActions.setIsFirstLottieRender(false));

      setTimeout(() => {
        if (containerRef.current) {
          containerRef.current.style.opacity = '0';
        }
      }, 4800);
    }
  }, [isAirdrop]);

  animationInstance?.current?.addEventListener('complete', () => {
    setShowAnimation(false);
  });

  return <AnimatedDiv ref={containerRef} />;
};

export default LottieAnimation;
