import { Colors } from '../../../data-models';
import { THEME } from '../../../constants';

type SettingIconType = {
  color?: Colors;
};

export const SettingIcon = ({
  color = THEME.COLORS.WHITE,
}: SettingIconType = {}) => {
  return (
    <svg
      width='19'
      height='16'
      viewBox='0 0 19 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.5 0.184204C1.98122 0.184204 0.75 1.41542 0.75 2.9342C0.75 4.45299 1.98122 5.6842 3.5 5.6842C5.01878 5.6842 6.25 4.45299 6.25 2.9342C6.25 1.41542 5.01878 0.184204 3.5 0.184204ZM2.25 2.9342C2.25 2.24385 2.80964 1.6842 3.5 1.6842C4.19036 1.6842 4.75 2.24385 4.75 2.9342C4.75 3.62456 4.19036 4.1842 3.5 4.1842C2.80964 4.1842 2.25 3.62456 2.25 2.9342Z'
        fill={color}
      />
      <path
        d='M9.5 2.1842C9.08579 2.1842 8.75 2.51999 8.75 2.9342C8.75 3.34842 9.08579 3.6842 9.5 3.6842L17.5 3.6842C17.9142 3.6842 18.25 3.34842 18.25 2.9342C18.25 2.51999 17.9142 2.1842 17.5 2.1842L9.5 2.1842Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.5 10.1842C13.9812 10.1842 12.75 11.4154 12.75 12.9342C12.75 14.453 13.9812 15.6842 15.5 15.6842C17.0188 15.6842 18.25 14.453 18.25 12.9342C18.25 11.4154 17.0188 10.1842 15.5 10.1842ZM14.25 12.9342C14.25 12.2438 14.8096 11.6842 15.5 11.6842C16.1904 11.6842 16.75 12.2438 16.75 12.9342C16.75 13.6246 16.1904 14.1842 15.5 14.1842C14.8096 14.1842 14.25 13.6246 14.25 12.9342Z'
        fill={color}
      />
      <path
        d='M1.5 12.1842C1.08579 12.1842 0.75 12.52 0.75 12.9342C0.75 13.3484 1.08579 13.6842 1.5 13.6842L9.5 13.6842C9.91421 13.6842 10.25 13.3484 10.25 12.9342C10.25 12.52 9.91421 12.1842 9.5 12.1842L1.5 12.1842Z'
        fill={color}
      />
    </svg>
  );
};
