import {
  AnyAction,
  combineReducers,
  configureStore,
  Dispatch,
  isRejectedWithValue,
  ThunkMiddleware,
} from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import authReducer from './reducers/authSlice';
import serviceReducer from './reducers/serviceSlice';

import { authAPI, leaderBoardAPI, battleAPI, marketplaceAPI } from './api';

import { initFacebookThunk } from './asyncThunks/facebookThunk';

const isDevelopment =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

const APIS = [authAPI, battleAPI, leaderBoardAPI, marketplaceAPI];

const apiReducers = APIS.reduce((acc, { reducerPath, reducer }) => {
  return {
    ...acc,
    [reducerPath]: reducer,
  };
}, {});

const reducers = combineReducers({
  ...apiReducers,
  authReducer,
  serviceReducer,
});

const rtkQueryErrorLogger =
  () => (next: Dispatch<AnyAction>) => (action: any) => {
    if (
      isRejectedWithValue(action) &&
      navigator.onLine &&
      process.env.NODE_ENV === 'development'
    ) {
      // eslint-disable-next-line no-console
      console.log(
        action.payload?.data?.message || 'Error in RTK Query occured',
      );
    }

    return next(action);
  };

const middlewares = APIS.map(({ middleware }) => middleware);
export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(rtkQueryErrorLogger)
      .concat(middlewares as ThunkMiddleware[]),
  devTools: isDevelopment,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

store.dispatch(initFacebookThunk());
