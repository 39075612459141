import { useCallback, useMemo } from 'react';
import { scaleLinear } from 'd3-scale';

import { GAME_BUBBLE_SIZE, SMALL_SCREEN, MEDIUM_SCREEN } from '../../constants';
import { numberWithDots } from '../../utils';
import { useBffLobbyQuery, useBffProfileQuery } from '../../redux/api';
import {
  BattleParticipantData,
  GameBubbleNodeType,
} from '../../data-models/game';
import { useMediaQuery } from '@mui/material';

export const useGameBubbleData = (width?: number, height?: number) => {
  const { data: profile } = useBffProfileQuery(undefined, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });
  const { data: lobbyData, status } = useBffLobbyQuery(undefined, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });
  const isSmallScren = useMediaQuery(SMALL_SCREEN);
  const isMediumScreen = useMediaQuery(MEDIUM_SCREEN);

  const parseLobbyResponse = () => {
    const battleBots: BattleParticipantData[] = [];
    const battleData = lobbyData?.currentBattle?.battle;
    battleData?.participants.forEach((battleBot: BattleParticipantData) => {
      battleBots.push({
        ...battleBot,
        score: battleData?.totalScores[battleBot?.botId],
      });
    });
    return battleBots;
  };

  const scale = useCallback(
    (() => {
      const minMax = parseLobbyResponse()?.reduce<{ min: number; max: number }>(
        (acc, curr) => {
          acc.max = curr.score > acc.max ? curr.score : acc.max;

          acc.min = curr.score < acc.min ? curr.score : acc.min;
          return acc;
        },
        { min: 0, max: 1 },
      );

      return scaleLinear(
        [minMax?.min || 0, minMax?.max || 1],
        [GAME_BUBBLE_SIZE.MIN, GAME_BUBBLE_SIZE.MAX],
      );
    })(),
    [parseLobbyResponse()],
  );

  const getSizeMultiplier = useCallback(() => {
    if (isSmallScren) {
      return 3;
    }

    if (isMediumScreen) {
      return 2.5;
    }

    return 2;
  }, [isMediumScreen, isSmallScren]);

  const data: GameBubbleNodeType[] | undefined = useMemo(() => {
    if (
      !parseLobbyResponse() ||
      !parseLobbyResponse().length ||
      status === 'pending'
    )
      return;

    const result = [...parseLobbyResponse()]
      .sort((a, b) => (a?.score > b?.score ? -1 : 1))
      .map((item, index) => {
        const sizeMultiplier = getSizeMultiplier();
        const r = scale(item.score) / sizeMultiplier;

        return {
          r,
          position: index + 1,
          isOwner: profile?.userId === item.userId,
          name: item.botName,
          score: numberWithDots(item.score),
        };
      });

    return result;
  }, [parseLobbyResponse(), width, height, profile]);

  return { data };
};
