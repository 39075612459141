export const PlusIcon = () => {
  return (
    <svg
      width='13'
      height='12'
      viewBox='0 0 13 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Add'>
        <path
          id='Vector'
          d='M6.99996 0.666687C6.99996 0.390545 6.7761 0.166687 6.49996 0.166687C6.22382 0.166687 5.99996 0.390545 5.99996 0.666687L5.99996 5.50002H1.16663C0.890484 5.50002 0.666626 5.72388 0.666626 6.00002C0.666626 6.27616 0.890484 6.50002 1.16663 6.50002H5.99996V11.3334C5.99996 11.6095 6.22382 11.8334 6.49996 11.8334C6.7761 11.8334 6.99996 11.6095 6.99996 11.3334V6.50002H11.8333C12.1094 6.50002 12.3333 6.27616 12.3333 6.00002C12.3333 5.72388 12.1094 5.50002 11.8333 5.50002H6.99996L6.99996 0.666687Z'
          fill='white'
        />
      </g>
    </svg>
  );
};
