import { useState, useEffect } from 'react';
import { useLazyBffLobbyQuery } from '../redux/api';

export const useProgress = ({
  startTime,
  endTime,
  isActiveBattle,
}: {
  startTime?: string;
  endTime?: string;
  isActiveBattle?: boolean;
}) => {
  const [progress, setProgress] = useState(0);
  const [shouldUpdateFrequently, setShouldUpdateFrequently] =
    useState<boolean>(false);
  const [triggerLobby] = useLazyBffLobbyQuery({
    pollingInterval: shouldUpdateFrequently ? 500 : 5000,
  });

  useEffect(() => {
    if (!startTime || !endTime) return;

    const startTimeMs = new Date(startTime).getTime();
    const endTimeMs = new Date(endTime).getTime();
    const totalDuration = endTimeMs - startTimeMs;

    const calculateProgress = () => {
      const currentTimeMs = new Date().getTime();
      const elapsedTime = currentTimeMs - startTimeMs;
      let newProgress = isActiveBattle
        ? (elapsedTime / totalDuration) * 100
        : 100 - (elapsedTime / totalDuration) * 100;
      newProgress = Math.max(0, Math.min(100, newProgress));
      setProgress(newProgress);
    };

    calculateProgress();
    const interval = setInterval(calculateProgress, 100);

    return () => clearInterval(interval);
  }, [startTime, endTime, isActiveBattle]);

  useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        triggerLobby();
      }, 500);
    }

    if (isActiveBattle) {
      if (progress > 90) {
        setShouldUpdateFrequently(true);
      } else {
        setShouldUpdateFrequently(false);
      }
    } else {
      if (progress < 10) {
        setShouldUpdateFrequently(true);
      } else {
        setShouldUpdateFrequently(false);
      }
    }
  }, [progress]);

  return progress;
};
