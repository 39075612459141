import { BalanceAssetType } from './enums';

export type InstructionData = {
  firstBlock: boolean;
  opponentPumps: boolean;
  opponentDumps: boolean;
  forgiveRate: number;
  friendPumps: boolean;
  friendDumps: boolean;
};

export type BotData = {
  id: number;
  userId: number;
  name: string;
  instructions: InstructionData;
  totalScore?: number;
  totalRounds?: number;
};

export type BotDataUI = {
  id: number;
  userId: number;
  name: string;
  instructions: InstructionData;
  totalRounds?: number;
  totalScore?: number;
  isActive?: boolean;
};

export type LeaderboardItemData = {
  strategyId: number;
  strategyName: string;
  score: number;
  isOwner: boolean;
};

export type BattleParticipantData = {
  id: number;
  userId: number;
  botId: number;
  botName: string;
  score: number;
};

export type BattleRenderData = {
  battle: BattleData;
};

export type BattleDataTotalScores = {
  [key: string]: number;
};

export type BattleData = {
  currentStep: number;
  duration: number;
  id: number;
  participants: BattleParticipantData[];
  startBlock: number;
  status: string;
  totalScores: BattleDataTotalScores;
};

export type BalanceData = {
  id: number;
  assetType: BalanceAssetType;
  assetSubtype: string;
  amount: number;
};

export type AirdropData = {
  secondsTillNextAirdrop: number;
  nextRewardAssetType: string;
  nextRewardAmount: number;
};

export type BlockchainData = {
  name: string;
  latestBlockNumber: number;
};

export enum PhasesEnum {
  ACTIVE_BATTLE = 'ACTIVE_BATTLE',
  COOLDOWN = 'COOLDOWN',
}

export type BattlePhaseData = {
  startTime: string;
  endTime: string;
  phase: PhasesEnum;
  totalDurationInSeconds: number;
};

export type BotResultData = {
  botId: number;
  botName: string;
  reward: number;
  score: number;
  userId: number;
  isOwner: boolean;
};

export type BattleResultData = {
  botResults: BotResultData[];
};

export type LobbyData = {
  airdrop: AirdropData;
  balances: BalanceData[];
  battlePhase: BattlePhaseData;
  participants: BattleParticipantData[];
  battleResult: BattleResultData;
  blockchain: BlockchainData;
  currentBattle: {
    battle: BattleData;
  };
  currentServerTime: string;
  lobbyParticipants: BattleParticipantData[];
  roundsTillAirdrop: number;
};

export type GameBubbleNodeType = {
  r: number;
  position: number;
  isOwner: boolean;
  score: number | string;
  name: string;
  index?: number;
  x?: number;
  y?: number;
  vx?: number;
  vy?: number;
  fx?: number | null;
  fy?: number | null;
};

export type MissionData = {
  createdAt: string;
  updatedAt: string;
  id: number;
  userId: number;
  missionEntity: {
    createdAt: string;
    updatedAt: string;
    id: number;
    code: string;
    name: string;
    description: string;
    recurringType: string;
    active: boolean;
    maxProgress: number;
    reward: {
      type: string;
      subType: string | null;
      amount: number;
    };
  };
  status: string;
  currentProgress: number;
  completeDate: string | null;
};

export type MissionsResultData = MissionData[];
