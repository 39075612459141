import { useState, useEffect } from 'react';
import {
  GameValuesContainer,
  InfoWrapper,
} from './GameUserBalancesAndAirdrop.styled';
import { PumpCurrencyCoinIcon } from '../../../../components/icons';
import {
  useLazyBalancesQuery,
  useLazyBffLobbyQuery,
} from '../../../../redux/api';
import { BalanceAssetType } from '../../../../data-models/enums';
import { GameInfoBlock } from '../../../../components/GameDataValue/GameInfoBlock';
import AirdropIcon from '../../../../components/icons/AirdropIcon';
import LottieAnimation from '../LottieAnimation';
import { useDispatch, useSelector } from 'react-redux';
import { getIsFirstLottieRender } from '../../../../redux/selectors/serviceSelector';
import { serviceActions } from '../../../../redux/reducers/serviceSlice';

export const GameUserBalancesAndAirdrop = () => {
  const isFirstLottieRender = useSelector(getIsFirstLottieRender);
  const [triggerLobby, { data: lobby }] = useLazyBffLobbyQuery({
    pollingInterval: 5000,
  });
  const [balancesTrigger, { data: balances }] = useLazyBalancesQuery({
    pollingInterval: 5000,
  });
  const dispatch = useDispatch();

  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    triggerLobby();
    balancesTrigger();
  }, []);

  const gasValue = balances?.find(
    (balance) => balance.assetType === BalanceAssetType.gas,
  );

  useEffect(() => {
    if (
      lobby?.roundsTillAirdrop === 0 &&
      lobby?.battlePhase?.phase === 'ACTIVE_BATTLE' &&
      isFirstLottieRender
    ) {
      setShowAnimation(true);
    }
  }, [lobby]);

  const handleCountRounds = () => {
    if (lobby) {
      if (lobby?.roundsTillAirdrop === 0) {
        return 'NOW!';
      } else if (lobby?.roundsTillAirdrop > 1) {
        dispatch(serviceActions.setIsFirstLottieRender(true));
        return `${lobby?.roundsTillAirdrop} ROUNDS`;
      } else {
        return `NEXT ROUND`;
      }
    } else {
      return '...';
    }
  };

  return (
    <GameValuesContainer>
      {showAnimation ? (
        <LottieAnimation
          isAirdrop={showAnimation}
          setShowAnimation={setShowAnimation}
        />
      ) : null}
      <InfoWrapper>
        <GameInfoBlock
          icon={<AirdropIcon />}
          infoValue={handleCountRounds()}
          isTimer
          isTimerCloseToOver={lobby?.roundsTillAirdrop === 0}
        />
        <GameInfoBlock
          icon={<PumpCurrencyCoinIcon />}
          infoValue={gasValue?.amount || '...'}
          isGasBalance
        />
      </InfoWrapper>
    </GameValuesContainer>
  );
};
