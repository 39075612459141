import { Box, Typography, styled } from '@mui/material';

import { transientOptions } from '../../utils';
import { THEME } from '../../constants';

export type StyledIconWrapperType = {
  $bgColor?: string;
  $size?: number;
  $iconSize: number;
  $border?: string;
};

export const StyledIconWrapper = styled(
  Box,
  transientOptions,
)<StyledIconWrapperType>`
  height: ${({ $size }) => `${$size}px`};
  min-width: ${({ $size }) => `${$size}px`};
  width: ${({ $size }) => `${$size}px`};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ $bgColor }) => $bgColor};
  border-radius: ${THEME.BORDER_RADIUS.PILL};
  border: ${({ $border }) => ($border ? $border : 'none')};

  & svg {
    width: ${({ $iconSize }) => `${$iconSize}px`};
  }
`;

export type StyledTextWrapperType = {
  $bgColor: string;
  $color: string;
  $width: number;
  $height: number;
};

export const StyledTextWrapper = styled(
  Typography,
  transientOptions,
)<StyledTextWrapperType>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${({ $height }) => `${$height}px`};
  min-width: ${({ $width }) => `${$width}px`};
  background-color: ${({ $bgColor }) => $bgColor};
  color: ${({ $color }) => $color};
  border-radius: ${THEME.BORDER_RADIUS.PILL};
  padding: 8px 8px 8px 26px;
  gap: 16px;
  font-size: 16px;
  font-weight: 500;
`;
